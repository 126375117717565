import ChatStatus from "components/ChatStatus/ChatStatus.jsx";
import React from "react";

export default function index() {
  return (
    <>
      <ChatStatus />
    </>
  );
}

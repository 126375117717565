import { useRecoilState, useRecoilValue } from "recoil";
import {
  dataState,
  highlightedRowState,
} from "../../store/appointment/atoms/appointmentAtomState";
import { FaPencilAlt } from "react-icons/fa";
import { parseISO, format } from "date-fns";

const AppointnmentMobileView = ({ handleEditClick }) => {
  const [data, setData] = useRecoilState(dataState);
  const highlightedRow = useRecoilValue(highlightedRowState);

  return (
    <div className="mx-auto sm:hidden">
      {/* List items */}
      {data.length > 0 ? (
        data.map((row, rowIndex) => (
          <div key={rowIndex} className="p-4 mb-4 bg-white rounded-md shadow">
            <div className="flex justify-between mb-2">
              <div>
                <p className="text-lg font-semibold">{row.doctor_name}</p>
                <p>{row.doctor}</p>
              </div>
              <div>
                <p className="text-lg font-semibold">{row.appointment_type}</p>
              </div>
            </div>
            <div className="flex justify-between">
              <div>
                <p className="text-base font-semibold">Appointment Date</p>
                <p>
                  {row.scheduled_at
                    ? format(parseISO(row.scheduled_at), "dd-MM-yyyy HH:mm:ss")
                    : null}
                </p>
              </div>
            </div>
            <div className="flex justify-between">
              <div>
                <p className="text-base font-semibold">Remark</p>
                {/* <p>{row.remark.source}</p> */}
              </div>
            </div>

            <div className="flex justify-between">
              <div>
                <p className="text-base font-semibold">Status</p>
                <p>{row.appointment_status}</p>
              </div>
              <div>
                <p className="text-base font-semibold">Patient Number</p>
                <p>{row.phone_number}</p>
              </div>
            </div>
            <div className="flex justify-end mt-2">
              <button>
                <FaPencilAlt
                  className="mr-2 text-blue-500 cursor-pointer"
                  onClick={() => handleEditClick(rowIndex)}
                  aria-label="Edit"
                />
              </button>
            </div>
          </div>
        ))
      ) : (
        <p>No data available</p>
      )}
    </div>
  );
};

export default AppointnmentMobileView;

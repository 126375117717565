import React, { useEffect, useRef, useState } from "react";
import { BiSelectMultiple } from "react-icons/bi";
import { FaMinus, FaPlus } from "react-icons/fa";
import { MdArrowDropDown, MdClose } from "react-icons/md";

const MultiSelect = ({
  filterOptions,
  onChange,
  filterName,
  selectAll = false,
  preSelectedFilters = [],
}) => {
  const [selectedOptions, setSelectedOptions] = useState(() => {
    if (selectAll) {
      return filterOptions.map((option) => option.value);
    } else if (preSelectedFilters && preSelectedFilters.length > 0) {
      return preSelectedFilters;
    } else {
      return [];
    }
  });
  const [allSelected, setAllSelected] = useState(() => {
    if (selectAll) {
      return true;
    } else if (preSelectedFilters && preSelectedFilters.length > 0) {
      return preSelectedFilters.length === filterOptions.length;
    } else {
      return false;
    }
  });
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleSelectOption = (value) => {
    setSelectedOptions((prevSelected) => {
      const newSelectedOptions = prevSelected.includes(value)
        ? prevSelected.filter((item) => item !== value)
        : [...prevSelected, value];
      if (newSelectedOptions.length === filterOptions.length) {
        setAllSelected(true);
        onChange(["all"]);
      } else {
        setAllSelected(false);
        onChange(newSelectedOptions);
      }
      return newSelectedOptions;
    });
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // useEffect(() => {
  //   if (selectAll) {
  //     setSelectedOptions(filterOptions.map((option) => option.value));
  //     onChange(["all"]);
  //     setAllSelected(true);
  //   }
  // }, []);

  // useEffect(()=>{
  //   if (preSelectedFilters && preSelectedFilters.length > 0) {
  //       setSelectedOptions(preSelectedFilters.map((option) => option));
  //       onChange(preSelectedFilters.map((option) => option));
  //       setAllSelected(preSelectedFilters.length === filterOptions.length);
  //     }
  // },[preSelectedFilters]);

  const toggleAllSelection = (e) => {
    e.stopPropagation();
    if (allSelected) {
      setSelectedOptions([]);
      onChange([]);
    } else {
      setSelectedOptions(filterOptions.map((option) => option.value));
      onChange(["all"]);
    }
    setAllSelected(!allSelected);
  };

  return (
    <div
      onClick={() => setIsOpen(!isOpen)}
      ref={dropdownRef}
      className={`relative flex h-[44px] w-56 cursor-pointer items-center rounded border-2 bg-white px-3 py-1 transition-all`}
    >
      <div className="flex w-full items-center justify-between gap-x-6">
        <h3 className="text-base leading-[1.1rem]">
          {selectedOptions.length > 0
            ? `${selectedOptions.length} Selected`
            : `Filter By ${filterName}`}{" "}
        </h3>
        <div className="flex items-center justify-center gap-x-2">
          <span
            onClick={toggleAllSelection}
            className="cursor-pointer text-sm hover:underline"
          >
            {allSelected ? (
              <MdClose className="h-5 w-5" />
            ) : (
              <BiSelectMultiple className="h-5 w-5" />
            )}
          </span>
          {!isOpen ? (
            <MdArrowDropDown className="h-6 w-6" />
          ) : (
            <MdArrowDropDown className="h-6 w-6 rotate-180" />
          )}
        </div>
      </div>

      <div>
        {isOpen && (
          <div className="absolute top-8 left-0 z-10 mt-3 max-h-[60vh] w-full space-y-2 overflow-y-auto rounded-b-md border-t bg-white shadow-md transition-all">
            {filterOptions.map((option) => (
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  handleSelectOption(option.value);
                }}
                key={option.value}
                className="flex items-center justify-between px-4 py-2 hover:bg-gray-100"
              >
                <span className="text-sm">{option.name}</span>
                <div className="flex items-center space-x-2">
                  <input type="checkbox" checked={selectedOptions.includes(option.value)} />
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default MultiSelect;

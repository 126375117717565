import React, { useState, useEffect } from "react";
import axios from "axios";
import { getToken } from "utils/getToken";
import { formatToDateAndTime } from "utils/formatTime";
import { MdMessage } from "react-icons/md";
import { FaLongArrowAltRight } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { HiExternalLink } from "react-icons/hi";
import IframeModal from "components/ChatStatus/IframeModal";
import { FiSearch } from "react-icons/fi";

const UserMessages = ({ mobileNo, userId }) => {
  const [currPage, setCurrPage] = useState(1);
  const [currData, setCurrData] = useState({});
  const [resultsArray, setResultsArray] = useState([]);
  const [view, setView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [filterPage, setFilterPage] = useState(1);
  const [error, setError] = useState(null); // New error state

  const fetchData = async (mobileNo, userId) => {
    try {
      setLoading(true);
      setError(null); // Reset error state when fetching data
      const dataForCurrPage = await axios.post(
        `${process.env.REACT_APP_API_URL}users/paginated-whatsapp-message/?page=${currPage}`,
        mobileNo
          ? JSON.stringify({
              mobile: `${mobileNo}`,
            })
          : userId
          ? JSON.stringify({
              user_id: userId,
            })
          : null,
        {
          headers: {
            Authorization: `Token ${getToken()}`,
            "Content-Type": "application/json",
          },
        }
      );
      setCurrData(dataForCurrPage.data);
      setResultsArray(dataForCurrPage.data.results);
    } catch (error) {
      console.log("Error fetching data:", error);
      toast.error("Some error occurred!");
      setError("Failed to load messages. Please try again");
    } finally {
      setLoading(false); // Stop loader in both success and failure
    }
  };

  const totalPages = Math.ceil(currData.count / 50);

  useEffect(() => {
    if (view) fetchData(mobileNo, userId);
  }, [currPage, mobileNo, userId, view]);

  const handleNextPage = () => {
    setCurrPage((prevPage) => prevPage + 1);
    window.scrollTo(0, 0);
  };

  const handlePrevPage = () => {
    setCurrPage((prevPage) => prevPage - 1);
    window.scrollTo(0, 0);
  };

  function createLinkAndOpenChat(row, rowIndex, mobileNum) {
    const chatStartTime = new Date(row.user_message.time);
    const startTimeOffset = chatStartTime.getTimezoneOffset();

    const lastMessageTime = new Date(row.user_message.time);
    const lastMessageTimeOffset = lastMessageTime.getTimezoneOffset();
    const startTime = new Date(
      chatStartTime.getTime() - 10 * 60 * 1000 - startTimeOffset * 60 * 1000
    );
    const endTime = new Date(
      lastMessageTime.getTime() +
        10 * 60 * 1000 -
        lastMessageTimeOffset * 60 * 1000
    );

    const startTimeISO = startTime.toISOString().slice(0, 19);
    const endTimeISO = endTime.toISOString().slice(0, 19);

    const mobileNumTransformed = mobileNum.split("+")[1];

    const url = `/admin/whatsapp-chat?group_id=${row.user_message.group_id}&start_time=${startTimeISO}&end_time=${endTimeISO}&mobile_number=${mobileNumTransformed}`;
    setModalContent({ url });
  }

  const closeModal = () => {
    setModalContent(null);
  };

  const handlePageSearch = () => {
    const pageNo = parseInt(filterPage, 10);
    if (!isNaN(pageNo) && pageNo <= totalPages) {
      setCurrPage(pageNo);
    } else {
      toast.error("Please enter a valid page number!");
    }
  };

  const handleTryAgain = () => {
    fetchData(mobileNo, userId);
  };

  return (
    <>
      <p
        onClick={() => setView(true)}
        className="flex justify-items-stretch text-center text-gray-800"
      >
        {!view && (
          <button className="w-full rounded-md bg-gray-700 p-2 text-white">
            View Whatsapp Chat
          </button>
        )}
      </p>

      {modalContent && (
        <IframeModal onClose={closeModal} url={modalContent.url} />
      )}

      {view && (
        <div
          className={`h-auto overflow-y-auto rounded-[10px] bg-gray-100 p-3`}
        >
          {!loading ? (
            error ? (
              <div className="text-center text-red-500">
                {error}
                <button
                  className="mt-4 rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600"
                  onClick={handleTryAgain}
                >
                  Try Again
                </button>
              </div>
            ) : (
              <div
                className={`overflow-y-scroll ${
                  resultsArray.length > 0 ? "h-80" : ""
                }`}
              >
                {/* messages top bar */}
                <div className="sticky -top-2 z-40 bg-gray-100 p-1 ">
                  <div className="flex items-center gap-1 ">
                    <MdMessage className="text-lg" />
                    <p>
                      <b>Total Messages</b> :{" "}
                      {currData.count ? currData.count : null}
                    </p>
                  </div>

                  <div className="flex w-full gap-10 p-0">
                    <h4 className="w-1/2">Messages</h4>
                    <h4 className="w-1/2">Replies</h4>
                  </div>
                </div>
                {/* border */}
                <p className="border-b-2"></p>
                {/* --------------- Message Container ---------- */}
                <div className="">
                  {resultsArray.length > 0 ? (
                    resultsArray.map((message, index) => (
                      <div
                        key={index}
                        className="my-2 flex max-h-60 w-full items-center gap-10 rounded-md "
                      >
                        {/* left portion  (user message container) */}
                        <div className="h-full w-1/2 rounded-md bg-white">
                          <div
                            className={`- relative my-0 mx-0 rounded-md p-2  ${
                              message.reply.length > 0
                                ? "bg-blue-200"
                                : "bg-white"
                            }`}
                          >
                            {/* user message msg */}
                            <p>{message.user_message.message}</p>
                            {/* time */}
                            <div className="text-right text-xs text-gray-700 ">
                              {" "}
                              <div className="flex items-center gap-4">
                                <p>
                                  {formatToDateAndTime(
                                    message.user_message.time
                                  )}
                                </p>

                                <button
                                  className="flex cursor-pointer items-center gap-1 text-blue-500 hover:underline"
                                  onClick={() => {
                                    createLinkAndOpenChat(
                                      message,
                                      index,
                                      mobileNo
                                    );
                                  }}
                                >
                                  Open chat
                                  <HiExternalLink />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {message.reply.length > 0 && (
                          <>
                            <FaLongArrowAltRight />
                            <div className="h-full w-1/2 rounded-md">
                              {message.reply.map((reply, replyIndex) => (
                                <div
                                  key={replyIndex}
                                  className="relative mx-2 my-2 rounded-md bg-white p-2"
                                >
                                  {/* msg */}
                                  <p>{reply.message}</p>
                                  {/* time */}
                                  <p className="text-right text-xs text-gray-700 ">
                                    {formatToDateAndTime(reply.time)}
                                  </p>
                                </div>
                              ))}
                            </div>
                          </>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="h-25 bg-white text-center text-lg">
                      No message found
                    </div>
                  )}
                </div>
              </div>
            )
          ) : (
            <>
              <ClipLoader
                color={"white"}
                size={100}
                className="relative left-2/4 top-1/2 h-10 w-full text-white"
              />
            </>
          )}
          <div className="mt-4 flex flex-col justify-center md:mt-6 md:flex-row">
            <div className="flex justify-center md:ml-auto">
              <button
                onClick={handlePrevPage}
                disabled={currData.previous === null || currPage === 1}
                className="rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="inline-flex h-5 w-5"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 4.293a1 1 0 010 1.414L6.414 9H16a1 1 0 110 2H6.414l3.293 3.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
                Previous Page
              </button>
              <h3 className="flex items-center px-2 text-lg text-gray-800 ">
                Page {currPage} of {totalPages}
              </h3>

              <button
                onClick={handleNextPage}
                disabled={currData.next == null || currPage === totalPages}
                className="rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
              >
                Next Page
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="inline-flex h-5 w-5 -rotate-180 transform"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M9.707 4.293a1 1 0 010 1.414L6.414 9H16a1 1 0 110 2H6.414l3.293 3.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <div className="mt-5 flex items-center justify-end md:ml-auto md:mt-0">
              <input
                type="text"
                placeholder={`Go to page (1 - ${totalPages})`}
                value={filterPage}
                onChange={(e) => setFilterPage(e.target.value)}
                className="w-full rounded-md border border-gray-400 bg-white py-2 pl-8 pr-2 text-sm font-medium text-gray-800 outline-none"
              />
              <button
                className="mx-2 cursor-pointer rounded-lg bg-blue-500 px-4 py-3 text-white hover:bg-blue-300"
                onClick={handlePageSearch}
              >
                <FiSearch className="h-4 w-4" />
              </button>
            </div>
          </div>
          <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      )}
    </>
  );
};

export default UserMessages;

import React, { useState, useRef, useEffect } from "react";
import { MdOutlineDriveFolderUpload, MdSearch } from "react-icons/md";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { ToastContainer, toast } from "react-toastify";
import { getToken } from "utils/getToken";

function BulkWhatsappMessage(props) {
  // State to track selected groups
  const [fileName, setFileName] = useState("");
  const [groupSearchQuery, setGroupSearchQuery] = useState("");
  const [contentType, setContentType] = useState("text_only");
  const [mediaFile, setMediaFile] = useState(null);
  const [mediaUrl, setMediaUrl] = useState("");
  const [content, setContent] = useState("");
  const [jobName, setJobName] = useState("");
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [isJobCreating, setIsJobCreating] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [groupsData, setGroupsData] = useState([]);
  const fileInputRef = useRef(null);
  const { setIsJobStatusVisible } = props;
  const groupDataFetcher = async () => {
    try {
      let url = process.env.REACT_APP_API_URL + "whatsapp/get-group-details/";

      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${getToken()}`,
          "Content-Type": "application/json",
        },
      });

      if (
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        setGroupsData([...response.data.data]);
        // console.log(response.data.data);
      }
    } catch (error) {
      toast.error("Something went wrong!");
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    groupDataFetcher();
  }, []);
  // Function to handle individual group selection
  const handleGroupSelection = (groupId) => {
    const isSelected = selectedGroups.includes(groupId);

    if (isSelected) {
      // Remove group from selectedGroups if already selected
      setSelectedGroups(selectedGroups.filter((id) => id !== groupId));
    } else {
      // Add group to selectedGroups if not selected
      setSelectedGroups([...selectedGroups, groupId]);
    }
  };

  // Function to handle "Select All" button click
  const handleSelectAll = () => {
    // If all groups are selected, clear the selection; otherwise, select all
    if (groupSearchQuery !== "") {
      if (selectedGroups.length === filteredGroups.length) {
        setSelectedGroups([]);
        setSelectAllChecked(false);
      } else {
        const allGroupIds = filteredGroups.map((group) => group.group_id);
        setSelectedGroups(allGroupIds);
        setSelectAllChecked(true);
      }
    } else {
      if (selectedGroups.length === groupsData.length) {
        setSelectedGroups([]);
        setSelectAllChecked(false);
      } else {
        const allGroupIds = groupsData.map((group) => group.group_id);
        setSelectedGroups(allGroupIds);
        setSelectAllChecked(true);
      }
    }
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const newFileName = e.target.files[0]?.name;
    const file = e.target.files[0];
    if (file) {
      setMediaFile(file);
    }
    setFileName(newFileName);
    // console.log(newFileName);
    // console.log(file);
  };
  const filteredGroups = groupsData.filter((group) =>
    group.group_name.toLowerCase().includes(groupSearchQuery.toLowerCase())
  );

  const handleContentTypeChange = (e) => {
    setContentType(e.target.value);
    if (e.target.value === "text_only") {
      setMediaFile(null);
      setMediaUrl("");
      setFileName("");
    } else if (e.target.value === "media") {
      setMediaUrl("");
    } else if (e.target.value === "media_url") {
      setMediaFile(null);
      setFileName("");
    }
  };

  const handleSendBulkMessage = async () => {
    // Create a FormData object to send the file
    if (contentType === "null") {
      console.error("No Content Type was selected");
      return;
    }

    if (selectedGroups.length === 0) {
      console.error("No Group was selected");
      return;
    }

    const formData = new FormData();

    if (mediaFile) {
      formData.append("media", mediaFile);
    } else {
      formData.append("media", null); // Send null for media_file if no file is selected
    }

    const requestData = {
      job_name: jobName,
      "group_ids[]": selectedGroups,
      content_type: contentType,
      content: content ? content : "",
      media_url: mediaUrl ? mediaUrl : "",
    };

    try {
      setIsJobCreating(true);
      // Append additional fields to the FormData
      Object.entries(requestData).forEach(([key, value]) => {
        if (key === "group_ids[]") {
          for (let i = 0; i < selectedGroups.length; i++) {
            formData.append(key, selectedGroups[i]);
          }
        } else {
          formData.append(key, value);
        }
      });
      console.log(formData.toString());
      // Make the API call using Axios
      const url = process.env.REACT_APP_API_URL + "whatsapp/save-job/";
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${getToken()}`,
        },
      });

      // Handle the response as needed
      console.log("API Response:", response);
      setContent("");
      setJobName("");
      setContentType("text_only");
      setMediaFile(null);
      setMediaUrl("");
      setFileName("");
      setSelectedGroups([]);
      setSelectAllChecked(false);
      setIsJobCreating(false);
      setIsJobStatusVisible(true);
    } catch (error) {
      setIsJobCreating(false);
      console.error("API Error:", error);
    }
  };
  return (
    <div className="flex w-full justify-center">
      <div className="flex w-full flex-col  gap-5 md:flex-row md:justify-between md:gap-2 ">
        <div className="h-[530px] rounded bg-white px-3  pt-2 shadow-md md:w-[49%]">
          <div className="p-2">
            <div className="relative w-[90%]">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MdSearch className="text-xl text-gray-500" />
              </div>
              <input
                className="w-full rounded border px-3 py-3 pl-10 outline-none"
                type="text"
                placeholder="Search groups"
                onChange={(e) => setGroupSearchQuery(e.target.value)}
              />
            </div>

            <div className="h-[450px] overflow-y-scroll">
              <div className="flex items-center justify-between ">
                <div className="mb-2 flex items-center py-3">
                  <input
                    type="checkbox"
                    id={`group-all`}
                    checked={selectAllChecked}
                    onChange={handleSelectAll}
                    className="mr-2 h-4 w-4 cursor-pointer border text-blue-500 focus:outline-none "
                  />
                  <label htmlFor={`group-all`}>Select All</label>
                </div>

                <p className="mb-2 mr-4 text-lg font-bold text-gray-700">
                  {selectedGroups.length > 0
                    ? `Selected: ${selectedGroups.length}`
                    : ""}
                </p>
              </div>
              <hr />
              {filteredGroups.map((group, index) => (
                <div key={index}>
                  <div className="mb-2 flex items-center py-3">
                    <input
                      type="checkbox"
                      id={`group-${group.group_id}`}
                      checked={selectedGroups.includes(group.group_id)}
                      onChange={() => handleGroupSelection(group.group_id)}
                      className="mr-2 h-4 w-4 cursor-pointer text-blue-500 focus:outline-none "
                    />
                    <label htmlFor={`group-${group.group_id}`}>
                      {group.group_name}
                    </label>
                  </div>
                  <hr />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex h-[530px] flex-col gap-3 rounded bg-white p-4 shadow-md md:w-[49%]">
          <div>
            <p className="mb-1 text-sm font-bold text-gray-700">
              CONTENT TYPE <b className="text-red-500">*</b>
            </p>
            <select
              className=" w-[100%] cursor-pointer rounded-[10px] border bg-white px-3 py-2 outline-none"
              value={contentType}
              onChange={handleContentTypeChange}
            >
              <option value="text_only">Text Only</option>
              <option value="media">Media </option>
              <option value="media_url">Media Url</option>
            </select>
          </div>
          <div
            className={`${
              contentType === "text_only" ? "" : "flex  justify-between"
            }`}
          >
            <div>
              <p className="mb-1 text-sm font-bold text-gray-700">
                JOB NAME: <b className="text-red-500">*</b>
              </p>
              <input
                className="w-full rounded-[10px] border px-3 py-2 outline-none"
                type="text"
                placeholder="Enter Job Name"
                value={jobName}
                onChange={(e) => setJobName(e.target.value)}
              />
            </div>
            {contentType === "media_url" && (
              <div>
                <p className="mb-1 text-sm font-bold text-gray-700">
                  MEDIA URL<b className="text-red-500">*</b>
                </p>
                <input
                  className="w-full rounded-[10px] border px-3 py-2 outline-none"
                  type="text"
                  placeholder="Paste Media Url"
                  value={mediaUrl}
                  onChange={(e) => setMediaUrl(e.target.value)}
                />
              </div>
            )}
            {contentType === "media" && (
              <div className=" md:mr-5">
                <p className="text-sm font-bold text-gray-700">
                  MEDIA FILE <b className="text-red-500">*</b>
                </p>{" "}
                <div className="flex  flex-col items-center">
                  <input
                    onChange={handleFileChange}
                    ref={fileInputRef}
                    type="file"
                    className="hidden"
                  />
                  <button
                    onClick={handleButtonClick}
                    className={`flex w-full items-center gap-2 rounded-[10px] border bg-blue-500 px-3 py-2 text-white`}
                  >
                    <MdOutlineDriveFolderUpload />
                    <span>Upload File</span>
                  </button>
                  <span className="text-sm text-gray-900">
                    {fileName.length > 18
                      ? fileName.substring(0, 18) + "..."
                      : fileName}
                  </span>
                </div>
              </div>
            )}
          </div>

          <div>
            <p className="mb-1 text-sm font-bold text-gray-700">
              MESSAGE{" "}
              {contentType === "text_only" && <b className="text-red-500">*</b>}
            </p>
            <textarea
              className="w-[100%] resize-none rounded-[10px] border px-3 py-2 outline-none"
              rows="8" // Set the number of visible text lines
              cols="50"
              placeholder="Type Your Message"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
            <div className="flex justify-end">
              {isJobCreating ? (
                <>
                  <button
                    disabled
                    className="mb-4 rounded-md bg-blue-300 px-4 py-1 text-white"
                  >
                    <ClipLoader
                      color={"white"}
                      size={20}
                      className="relative top-1 right-1"
                    />
                    Creating Job
                  </button>
                </>
              ) : (
                <button
                  onClick={handleSendBulkMessage}
                  className="mb-4 rounded-md bg-blue-500 px-4 py-1 text-white"
                >
                  Send
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default BulkWhatsappMessage;

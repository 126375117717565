import axios from "axios";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getToken } from "utils/getToken";

const PaymentLink = (props) => {
  const navigate = useNavigate();
  const [linkFound, setLinkFound] = useState(false);
  const [link, setLink] = useState("");
  const [mobile, setMobile] = useState("");
  const [payReason, setPayReason] = useState("");
  const [couponId, setCouponId] = useState(0);
  const [couponData, setCouponData] = useState(0);
  const [amount, setAmount] = useState(0);
  const [copyMsg, setCopyMsg] = useState("Copy");
  const [clicked, setClicked] = useState(false);

  const createLink = (e) => {
    e.preventDefault();
    if (payReason === "" || payReason === "Select Reason for Payment") {
      alert("Reason of Payment is Mandatory");
    } else {
      setClicked(true);
      axios
        .post(
          process.env.REACT_APP_API_URL + "payments/payment-link/",
          {
            mobile: mobile,
            amount: parseInt(amount) * 100,
            payment_reason: payReason,
            payment_source: "Support Portal",
            coupon_id: couponId,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Token " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          setClicked(false);
          console.log(res.data);
          setLink(res.data.url);
          setLinkFound(true);
        })
        .catch((err) => {
          setClicked(false);
          if (err.response.status === 401) {
            localStorage.clear();
            navigate("/");
          }
          toast.error("Unable to create link! ");
        });
    }
  };

  const couponDataFetcher = async () => {
    try {
      const url = process.env.REACT_APP_API_URL + "subscription/coupons/";

      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${getToken()}`,
          "Content-Type": "application/json",
        },
      });
      //  console.log(response);
      if (response.data && response.data.length > 0) {
        setCouponData([...response.data]);
      }
    } catch (error) {
      toast.error("Something went wrong!");
      console.log("Error fetching data:", error);
    }
  };

  const copyText = () => {
    navigator.clipboard.writeText(link);
    setCopyMsg("Copied");
    setTimeout(() => {
      setCopyMsg("Copy");
    }, 1000);
  };

  const linkComponent = () => {
    if (linkFound) {
      return (
        <div className="flex justify-center">
          <div className="w-screen p-8 text-center bg-blue-500 rounded-xl">
            <p className="px-4 py-4 mb-6 text-white bg-pink-500 link">{link}</p>
            <button
              onClick={copyText}
              className="px-4 py-2 text-black bg-white"
            >
              {copyMsg}
            </button>
          </div>
        </div>
      );
    }
  };
  useEffect(() => {
    couponDataFetcher();
  }, []);

  console.log("Pay Reason", payReason);
  return (
    <>
      <div className="flex flex-col items-center px-4 overflow-hidden md:px-12">
        <div className="flex flex-col items-center w-full mt-4 sm:mt-6">
          {/* Form start */}
          <form className="flex w-[100%] flex-col space-y-4">
            <div className="w-full">
              <h2 className="items-center justify-center my-6 text-4xl font-bold text-center capitalize text-navy-700 dark:text-white md:my-8">
                Payment Link
              </h2>
              {linkComponent()}
              {linkFound ? null : (
                <div>
                  <div className="mt-4">
                    <label>
                      Mobile No. <b className="text-red-500">*</b>
                    </label>
                    <input
                      className="text-black  w-full border-2 border-solid border-[#BFDBFE] bg-white py-2 px-4 focus:outline-none focus:ring-blue-500 dark:focus:ring-blue-600"
                      type="text"
                      placeholder="Mobile"
                      value={mobile}
                      onChange={(e) => {
                        setMobile(e.target.value);
                      }}
                    />
                  </div>

                  <div className="mt-4">
                    <label>
                      Payment Reason <b className="text-red-500">*</b>
                    </label>
                    <select
                      className="text-black  w-full border-2 border-solid  border-[#BFDBFE] bg-white py-2 px-4 pb-4 focus:outline-none focus:ring-blue-500 dark:focus:ring-blue-600"
                      onChange={(e) => setPayReason(e.target.value)}
                    >
                      <option>Select Reason for Payment</option>
                      <option>Consultation - Pediatrics</option>
                      <option>Consultation - Gynecologist</option>
                      <option>Consultation - Dermatalogy</option>
                      <option>Consultation - Lactation</option>
                      <option>Consultation - Nutritionist</option>
                      <option>Consultation - PPD</option>
                      <option>Subscription - OneWeek_Trial (7 Days)</option>
                      <option>Subscription - Essential (1)</option>
                      <option>Subscription - Essential (3)</option>
                      <option>Subscription - BTP (1)</option>
                      <option>Subscription - Holistic_New (1)</option>
                      <option>Subscription - Holistic_New (3)</option>
                      <option>Subscription - Holistic_Old (1)</option>
                      <option>Subscription - Holistic_Old (3)</option>
                      <option>Subscription - WTP (1)</option>
                      <option>Subscription - WTP (3)</option>
                      <option>Subscription - STP (1)</option>
                      <option>Subscription - Toilet Training Program TTP (1)</option>
                      <option>Subscription - Basic (1)</option>
                      <option>Subscription - Basic (3)</option>
                      <option>Subscription - Prime (1)</option>
                      <option>Subscription - Prime (3)</option>
                      <option>Subscription - Basic 499</option>
                      <option>Others</option>
                    </select>
                  </div>

                  <h1 className="text-black mt-4 w-full border-2  border-solid border-[#BFDBFE] bg-white py-2 px-4 focus:outline-none focus:ring-blue-500 dark:focus:ring-blue-600">
                    INR
                  </h1>
                  <div className="mt-4">
                    <label>
                      Amount <b className="text-red-500">*</b>
                    </label>
                    <input
                      className="text-black   w-full border-2 border-solid border-[#BFDBFE] bg-white py-2 px-4 focus:outline-none focus:ring-blue-500 dark:focus:ring-blue-600"
                      type="number"
                      placeholder="Amount"
                      step={0.01}
                      value={amount}
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                    />
                  </div>
                  <div className="mt-4 mb-4">
                    <label>Coupon</label>
                    <select
                      className="text-black  w-full border-2  border-solid border-[#BFDBFE] bg-white py-2 px-4 focus:outline-none focus:ring-blue-500 dark:focus:ring-blue-600"
                      onChange={(e) => setCouponId(e.target.value)}
                    >
                      <option value={0}>Select a Coupon</option>
                      {couponData.length > 0 &&
                        couponData.map((coupon, index) => {
                          return (
                            <option key={index} value={coupon.id}>
                              {coupon.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>

                  {clicked ? (
                    <button
                      className={`w-full rounded bg-blue-300 px-4 py-2 text-center text-white md:col-span-2`}
                    >
                      Loading...
                    </button>
                  ) : (
                    <button
                      className={`w-full rounded bg-blue-500 px-4 py-2 text-white md:col-span-2`}
                      onClick={(e) => createLink(e)}
                    >
                      Create
                    </button>
                  )}
                </div>
              )}
            </div>
          </form>
        </div>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </>
  );
};

export default PaymentLink;

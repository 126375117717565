import { atom } from "recoil";

export const supportTaskModalState = atom({
  key: "supportTaskModalState",
  default: {
    open: false,
    taskDetails: null,
    taskId: null,
    type: null,
  },
});

export const isTasksUpdatedState = atom({
  key: "isTasksUpdatedState",
  default: false,
});
import React, { useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { getToken } from "utils/getToken";
import axios from "axios";
import { useRecoilValue } from "recoil";
import {
  appointmentIdState,
  userMobileNumberState,
} from "../../store/appointment/atoms/appointmentAtomState";
import { formatTimestamp } from "../../utils/formatTimeStamp";
import SyncLoader from "components/Loaders/SyncLoader";
import { FaRegCopy } from "react-icons/fa";
import { toast } from "react-toastify";

const AppointmentHistoryModal = ({ isOpen, closeModal }) => {
  const [patientHistoryData, setPatientHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const appointmentId = useRecoilValue(appointmentIdState);
  const [hasFetched, setHasFetched] = useState(false);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const userMobileNumber = useRecoilValue(userMobileNumberState);

  const fetchPatientData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}patients/patient-case-history/`,
        { appointment_id: appointmentId },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${getToken()}`,
          },
        }
      );
      if (response.data.length === 0) {
        setError("No History data found");
      } else {
        setPatientHistoryData(response.data);
        setHasFetched(true);
        console.log(patientHistoryData);
      }
    } catch (err) {
      setError("Failed to fetch patient data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen && !hasFetched) {
      fetchPatientData();
    }
  }, [isOpen, hasFetched]);

  const handleClose = () => {
    setHasFetched(false);
    closeModal();
  };

  const toggleExpanded = (index) => {
    setExpandedIndex(index === expandedIndex ? null : index);
  };

  const renderPatientDetails = (details) => {
    const entries = Object.entries(details);
    return entries.map(([key, value]) => {
      if (Array.isArray(value)) {
        return (
          <p key={key} className="mt-2">
            <strong className="capitalize">{key.replace(/_/g, " ")}:</strong>{" "}
            {value.join(", ")}
          </p>
        );
      }
      return (
        <p key={key} className="mt-2">
          <strong className="capitalize">{key.replace(/_/g, " ")}:</strong>{" "}
          {value}
        </p>
      );
    });
  };

  const showFullTermDetails = (details) => {
    if (details.baby_full_term === "No") {
      return `No, ${details.weeks_born_earlier} weeks`;
    } else {
      return "Yes";
    }
  };

  const formatPatientHistoryBlock = (data) => {
    return `
Patient Mobile number: ${userMobileNumber || "N/A"}
Baby Name: ${data.details.baby_name || "N/A"}
Baby Gender: ${data.details.baby_gender || "N/A"}
Baby Date of Birth: ${data.details.baby_dob || "N/A"}
Was baby full term born, if no how early born: ${showFullTermDetails(
      data.details
    )}
Baby birth weight: ${data.details.birth_weight || "N/A"} kg
Any history of hospitalization: ${data.details.baby_hospitalization || "N/A"}
Baby last weight and date of weight: ${
      data.details.baby_current_weight || "N/A"
    } kg on ${data.details.current_weight_date || "N/A"}
What is the problem your baby is facing right now? : ${
      data.details.problem_faced_by_baby || "N/A"
    }`;
  };

  const copyBlockToClipboard = (data) => {
    const formattedData = formatPatientHistoryBlock(data);
    navigator.clipboard.writeText(formattedData).then(() => {
      toast.success("Patient history block copied to clipboard!");
    });
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-900 opacity-75"></div>
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-full p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-3xl p-6 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  Patient Case History
                </Dialog.Title>
                <div className="mt-4 space-y-4 overflow-y-auto h-96">
                  {loading && <SyncLoader size="5px" color="#525CEB" />}
                  {error && <p>{error}</p>}
                  {patientHistoryData.map((data, index) => (
                    <div
                      key={data.id}
                      className="p-4 border rounded-lg bg-gray-50"
                    >
                      <div className="flex justify-between">
                        <div>
                          <p>
                            <strong>Mobile Number:</strong> {userMobileNumber}{" "}
                          </p>
                          <p>
                            <strong>Time:</strong>{" "}
                            {formatTimestamp(data.updated_at)}
                          </p>
                        </div>
                        {data.category === "pediatric" && (
                          <button
                            type="button"
                            className="p-2 ml-2 text-white bg-blue-400 rounded-md"
                            onClick={() => copyBlockToClipboard(data)}
                          >
                            <FaRegCopy size="15" />
                          </button>
                        )}
                      </div>

                      <div className="mt-2">
                        {renderPatientDetails(data.details)}
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex justify-end mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={handleClose}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AppointmentHistoryModal;

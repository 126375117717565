import React from "react";
import { useLocation } from "react-router-dom";
import { FiAlignJustify } from "react-icons/fi";
const Navbar = (props) => {
  const { onOpenSidenav } = props;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  return (
    // <nav
    //   className={`position-inherit sticky top-0 z-40 flex w-full flex-row bg-white/10 backdrop-blur-xl ${
    //     queryParams.size > 0 ? "top-auto hidden" : "xl:top-auto xl:hidden"
    //   }`}
    // >
    //   <div className="flex  w-full flex-grow items-center justify-between shadow-2xl shadow-shadow-500 ">
    //     <span
    //       className="flex cursor-pointer text-xl text-gray-600 dark:text-white xl:hidden"
    //       onClick={onOpenSidenav}
    //     >
    //       <FiAlignJustify className="h-8 w-8" />
    //     </span>
    //     <div>
    //       <div className="h-6 pt-1">
    //         <a
    //           className="text-lg font-bold text-navy-700 hover:underline dark:text-white dark:hover:text-white"
    //           href=" "
    //         >
    //           Babynama
    //           <span className="mx-1 text-sm text-navy-700 hover:text-navy-700 dark:text-white">
    //             {" "}
    //           </span>
    //         </a>
    //       </div>
    //     </div>
    //   </div>
    // </nav>
    <></>
  );
};

export default Navbar;

import React, { useState, useEffect, useRef, useMemo } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { getToken } from "utils/getToken";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ChatContainer } from "./ChatContainer";
import { FiltersDropdown } from "./FiltersDropdown";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { BsFillArrowLeftCircleFill } from "react-icons/bs";
import { ChatContainerPersonal } from "./ChatContainerPersonal";
import { MdCancel } from "react-icons/md";

import "./WhatsappChat.css";
import "assets/css/filter.css";

const ChatMain = () => {
  const [isPreviousMessageLoading, setIsPreviouMessageLoading] =
    useState(false);
  const [isNextMessageLoading, setIsNextMessageLoading] = useState(false);
  const [isNewMessageLoading, setIsNewMessageLoading] = useState(false);
  const [chatData, setChatData] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [latestGroupNames, setLatestGroupNames] = useState([]);
  const [latestGroupDataLoaded, setLatestGroupDataLoaded] = useState(false);
  const [allGroupDataLoaded, setAllGroupDataLoaded] = useState(false);
  const [latestGroupData, setLatestGroupData] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [normalGroupNames, setNormalGroupNames] = useState([]);
  const [normalGroupDataLoaded, setNormalGroupDataLoaded] = useState(false);
  const [normalGroupData, setNormalGroupData] = useState([]);
  const [isPreviousMessageLoaded, setIsPreviousMessageLoaded] = useState(false);
  const [newMessageLength, setNewMessageLength] = useState(0);
  // Parse data from url
  const location = useLocation();
  const queryParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location.search]);
  const groupIdFromQuery = queryParams.get("group_id");
  const startTimeFromQuery = queryParams.get("start_time");
  const endTimeFromQuery = queryParams.get("end_time");
  const mobileNumberFromQuery = queryParams.get("mobile_number");

  // For getting latest group details
  const latestGroupDataFetcher = async () => {
    try {
      let url = process.env.REACT_APP_API_URL + "whatsapp/get-top-groups-data/";

      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${getToken()}`,
          "Content-Type": "application/json",
        },
      });
      // console.log("response", response);

      if (response.data && response.data.length > 0) {
        setLatestGroupDataLoaded(true);
        const groupNames = response.data.map((group) => group.group_name);
        const LatestgroupId = response.data.map((group) => group.group_id);
        setLatestGroupNames(groupNames);
        setLatestGroupData(response.data);
        // console.log(response.data);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
      setIsDisabled(false);
    }
  };

  // For getting all groups deatils
  const normalGroupDataFetcher = async () => {
    try {
      let url = process.env.REACT_APP_API_URL + "whatsapp/get-group-details/";

      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${getToken()}`,
          "Content-Type": "application/json",
        },
      });

      if (
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        setNormalGroupDataLoaded(true);
        const groupNames = response.data.data.map((group) => group.group_name);
        setNormalGroupNames(groupNames);
        setNormalGroupData(response.data.data);
        // console.log(response.data.data);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
      // setIsDisabled(false);
    }
  };

  const findGroup = (groupName) => {
    const latestGroup = latestGroupData.find(
      (group) => group.group_name.toLowerCase() === groupName.toLowerCase()
    );

    if (latestGroup) {
      return latestGroup;
    }

    const normalGroup = normalGroupData.find(
      (group) => group.group_name.toLowerCase() === groupName.toLowerCase()
    );

    return normalGroup;
  };

  const handleGroupSelection = (selectedGroupName) => {
    const matchingGroup = findGroup(selectedGroupName);
    // console.log(matchingGroup);
    if (matchingGroup) {
      setStartTime(null);
      setEndTime(null);
      setSelectedMobileNumber(null);
      setSelectedGroup(matchingGroup.group_name);
      setSelectedGroupId(matchingGroup.group_id);
      // console.log(selectedGroupId);
    } else {
      toast.error("Group not found with name:", selectedGroupName);
      console.log("Group not found with name:", selectedGroupName);
    }
  };

  const findGroupById = (groupId) => {
    const latestGroup = latestGroupData.find(
      (group) => group.group_id === groupId
    );

    if (latestGroup) {
      return latestGroup;
    }

    const normalGroup = normalGroupData.find(
      (group) => group.group_id === groupId
    );

    return normalGroup;
  };

  // Find normal groupData by id
  const handleGroupFinderAndSetterById = (groupId) => {
    const matchingGroup = findGroupById(groupId);

    if (matchingGroup) {
      setStartTime(null);
      setEndTime(null);
      setSelectedMobileNumber(null);
      setSelectedGroup(matchingGroup.group_name);
      setSelectedGroupId(matchingGroup.group_id);
      // console.log(selectedGroupId);
    } else {
      toast.error("Group name doesn't exist in this view:", groupId);
      // console.log("Group name doesn't exist in this view:", groupId);
    }
  };

  // For fetching data
  const fetchWhatsappChatData = async (
    selectedGroupId,
    filter_time,
    more_records,
    start_time,
    end_time,
    sortingMethod
  ) => {
    try {
      // console.log("api called");
      const url =
        process.env.REACT_APP_API_URL + "whatsapp/get-group-messages/";
      if (sortingMethod !== "asc") {
        sortingMethod = "desc";
      }
      if (
        startTimeFromQuery &&
        endTimeFromQuery &&
        groupIdFromQuery &&
        more_records === "next"
      ) {
        sortingMethod = "asc";
      }
      if (
        startTimeFromQuery &&
        endTimeFromQuery &&
        groupIdFromQuery &&
        more_records === "previous"
      ) {
        sortingMethod = "desc";
      }
      const data = {
        whatsappgroup_id: selectedGroupId,
        filter_time,
        more_records, //Pass 'Next' or 'Previous'
        start_time,
        end_time,
        sort_by: sortingMethod,
      };

      const response = await axios.post(url, data, {
        headers: {
          Authorization: `Token ${getToken()}`,
          "Content-Type": "application/json",
        },
      });
      if (response.data.length === 0) {
        toast.error("No messages found");
        setChatData([]);
        setIsDisabled(false);
        setIsPreviouMessageLoading(false);
        setIsNextMessageLoading(false);
        setIsNewMessageLoading(false);
        return;
      }
      const newChatData = response.data;
      // console.log("newChatData", newChatData);
      // Remove duplicates from messages content
      // newChatData = removeDuplicatesFromMainData(newChatData);
      // Revert the order of messages

      if (sortingMethod === "desc") {
        newChatData.reverse();
        // console.log("Reversed");
      }
      if (more_records === "next") {
        // Append new messages to the end
        if (newChatData.length === 1) {
          toast.error("No more messages to show");
          setIsNextMessageLoading(false);
        } else {
          const uniqueNewMessages = removeDuplicates(
            newChatData,
            chatData,
            true
          );
          // console.log(uniqueNewMessages);
          setChatData([...chatData, ...uniqueNewMessages]);
          toast.success("Next Messages loaded successfully");
          setIsDisabled(false);
          setIsNextMessageLoading(false);
          if (mobileNumberFromQuery) {
            setMobileNumberSearchQuery(mobileNumberFromQuery);
          }
        }
      } else if (more_records === "previous") {
        // Insert new messages at the beginning
        const uniqueNewMessages = removeDuplicates(
          newChatData,
          chatData,
          false
        );
        // console.log(uniqueNewMessages);
        setChatData([...uniqueNewMessages, ...chatData]);
        setNewMessageLength(uniqueNewMessages.length);
        toast.success("Previous Messages fetched successfully");
        setIsPreviousMessageLoaded(true);
        setIsDisabled(false);
        setIsPreviouMessageLoading(false);
        if (mobileNumberFromQuery) {
          setMobileNumberSearchQuery(mobileNumberFromQuery);
        }
      } else if (start_time && end_time) {
        setChatData(newChatData);
        setIsDisabled(false);
        setIsNewMessageLoading(false);
        return newChatData;
        // BUG:2 api cals are being made
        // toast.success("Messages fetched successfully");
      } else {
        setChatData(newChatData);
        setIsDisabled(false);
        setIsNewMessageLoading(false);
        return newChatData;
      }
    } catch (error) {
      console.log(error);
      console.log("Error fetching data:", error);
      setIsDisabled(false);
      setIsNewMessageLoading(false);
      setIsPreviouMessageLoading(false);
      setIsNextMessageLoading(false);
      toast.error("Error fetching data");
      setChatData([]);
    }
  };

  // For combining two sets of API response

  const fetchAndAppendMessages = async (
    selectedGroupId,
    filter_time,
    more_records,
    start_time,
    end_time,
    sortingMethod
  ) => {
    try {
      // Decrease 20 minutes from start time
      const newStartTime = new Date(start_time);
      const startTimeOffset = newStartTime.getTimezoneOffset();
      newStartTime.setMinutes(newStartTime.getMinutes() - 20 - startTimeOffset);
      const newIsoStartTime = newStartTime.toISOString().slice(0, 19);

      // Add 20 minutes to end time
      const newEndTime = new Date(end_time);
      const endTimeOffset = newEndTime.getTimezoneOffset();
      newEndTime.setMinutes(newEndTime.getMinutes() + 20 - endTimeOffset);
      const newIsoEndTime = newEndTime.toISOString().slice(0, 19);

      const firstApiResponse = await fetchWhatsappChatData(
        selectedGroupId,
        filter_time,
        more_records,
        start_time,
        end_time,
        sortingMethod
      );
      // console.log("firstApiResponse", firstApiResponse);
      // Make API call with updated start time and end time
      const newApiResponse = await fetchWhatsappChatData(
        selectedGroupId,
        filter_time,
        more_records,
        newIsoStartTime,
        newIsoEndTime,
        sortingMethod
      );
      // console.log("newApiResponse", newApiResponse);

      const indexOfFirstMessage = newApiResponse.findIndex(
        (message) =>
          message.message.message_id === firstApiResponse[0].message.message_id
      );

      const indexOfLastMessage = newApiResponse.findIndex(
        (message) =>
          message.message.message_id ===
          firstApiResponse[firstApiResponse.length - 1].message.message_id
      );
      // console.log("indexOfFirstMessage", indexOfFirstMessage);
      // console.log("indexOfLastMessage", indexOfLastMessage);

      if (indexOfFirstMessage !== -1 && indexOfLastMessage !== -1) {
        const startIndex = Math.max(indexOfFirstMessage - 5, 0);
        const endIndex = Math.min(
          indexOfLastMessage + 6,
          newApiResponse.length - 1
        );

        const combinedMessages = newApiResponse.slice(startIndex, endIndex + 1);
        // console.log("Combined Messages", combinedMessages);
        setChatData(combinedMessages);
      }
    } catch (error) {
      console.log("Error fetching and appending data:", error);
    }
  };

  // Fetch data on page load

  useEffect(() => {
    latestGroupDataFetcher();
    normalGroupDataFetcher();
  }, []);

  useEffect(() => {
    if (normalGroupDataLoaded && latestGroupDataLoaded) {
      setAllGroupDataLoaded(true);
    }
  }, [normalGroupDataLoaded, latestGroupDataLoaded]);

  // close grpup sidebar when params are present
  const [isGroupSidebarOpen, setIsGroupSidebarOpen] = useState(true);
  // const toggleGroupSidebar = () => {
  //   setIsGroupSidebarOpen(!isGroupSidebarOpen);
  // };

  useEffect(() => {
    if (queryParams.size > 0) {
      setIsGroupSidebarOpen(false);
    }
  }, [queryParams]);
  // Load initial data only when no query params are present

  useEffect(() => {
    if (
      !groupIdFromQuery &&
      !startTimeFromQuery &&
      !endTimeFromQuery &&
      allGroupDataLoaded
    )
      if (selectedGroupId) {
        setIsNewMessageLoading(true);
        fetchWhatsappChatData(selectedGroupId);
      }
  }, [
    endTimeFromQuery,
    groupIdFromQuery,
    selectedGroupId,
    startTimeFromQuery,
    allGroupDataLoaded,
  ]);

  // For fetching next and previous messages
  const handleShowPreviousMessages = () => {
    setIsPreviouMessageLoading(true);
    fetchPreviousMessages();
  };

  const handleNextMessages = () => {
    setIsNextMessageLoading(true);
    const filter_time = chatData[chatData.length - 1].message.time;
    fetchWhatsappChatData(selectedGroupId, filter_time, "next");
  };

  const fetchPreviousMessages = () => {
    const filter_time = chatData[0].message.time;
    fetchWhatsappChatData(selectedGroupId, filter_time, "previous");
  };

  // Search groups based on all groups
  const [searchQuery, setSearchQuery] = useState("");
  const [searchClicked, setSearchClicked] = useState(false);
  const containerRef = useRef(null);

  const filteredGroups =
    searchClicked && searchQuery.length > 0
      ? normalGroupData.filter((group) =>
          group.group_name.toLowerCase().includes(searchQuery.toLowerCase())
        )
      : latestGroupData;

  useEffect(() => {
    if (searchQuery.length === 0) {
      setSearchClicked(false);
    }
  }, [searchClicked, searchQuery]);

  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    setIsDropdownPhoneOpen(false);
  };
  const [isDropdownPhoneOpen, setIsDropdownPhoneOpen] = useState(false);
  const phoneToggleDropdown = () => {
    setIsDropdownPhoneOpen(!isDropdownPhoneOpen);
    setIsDropdownOpen(false);
  };
  const closePhoneDropdowns = () => {
    setIsDropdownPhoneOpen(false);
  };

  const [mobileNumberSearchQuery, setMobileNumberSearchQuery] = useState("");
  const handleMobileNumberSearch = () => {
    if (mobileNumberSearchQuery.length > 7) {
      setMobileNumberSearchQuery(mobileNumberSearchQuery);
      // console.log("mobileNumberSearchQuery", mobileNumberSearchQuery);
    }
  };

  // For start and end time
  const [isDisabled, setIsDisabled] = useState(false);
  const handleInputFocus = (event) => {
    event.target.style.borderColor = "#3498db";
  };

  const handleDateTimeSearch = async () => {
    // console.log("startTime", startTime);
    // console.log("endTime", endTime);
    if (startTime && endTime) {
      if (startTime > endTime) {
        toast.error("Start time cannot be greater than end time");
      } else {
        setIsDisabled(true);

        // Convert selected times to ISO format with date and time in the same time zone
        const startTimeDate = new Date(startTime);
        const endTimeDate = new Date(endTime);

        // Get the offset in minutes and apply it to the dates
        const startTimeOffset = startTimeDate.getTimezoneOffset();
        const endTimeOffset = endTimeDate.getTimezoneOffset();

        startTimeDate.setMinutes(startTimeDate.getMinutes() - startTimeOffset);
        endTimeDate.setMinutes(endTimeDate.getMinutes() - endTimeOffset);

        // Convert to ISO format
        const isoStartTime = startTimeDate.toISOString().slice(0, 19);
        const isoEndTime = endTimeDate.toISOString().slice(0, 19);
        setIsNewMessageLoading(true);
        await fetchWhatsappChatData(
          selectedGroupId,
          "",
          "",
          isoStartTime,
          isoEndTime,
          "desc"
        );
      }
    } else {
      toast.error("Please select start and end time");
    }
  };

  // Used only when group id is present
  // Fetches data using only group id

  useEffect(() => {
    if (
      groupIdFromQuery &&
      !endTimeFromQuery &&
      !startTimeFromQuery &&
      !mobileNumberFromQuery &&
      allGroupDataLoaded
    ) {
      if (groupIdFromQuery) {
        // console.log("Valid group id:", groupIdFromQuery);
        handleGroupFinderAndSetterById(groupIdFromQuery);
        setIsNewMessageLoading(true);
        fetchWhatsappChatData(groupIdFromQuery, "", "", "", "", "asc");
      }
    }
  }, [
    endTimeFromQuery,
    groupIdFromQuery,
    latestGroupNames,
    startTimeFromQuery,
    allGroupDataLoaded,
  ]);

  // Fetches data using group id, start time and end time
  useEffect(() => {
    if (
      startTimeFromQuery &&
      endTimeFromQuery &&
      groupIdFromQuery &&
      allGroupDataLoaded &&
      !mobileNumberFromQuery
    ) {
      const isValidStartTime = !isNaN(Date.parse(startTimeFromQuery));
      const isValidEndTime = !isNaN(Date.parse(endTimeFromQuery));

      if (isValidStartTime && isValidEndTime) {
        // console.log(
        //   "Valid start and end time:",
        //   startTimeFromQuery,
        //   endTimeFromQuery
        // );
        if (groupIdFromQuery) {
          handleGroupFinderAndSetterById(groupIdFromQuery);
          setIsNewMessageLoading(true);
          fetchWhatsappChatData(
            groupIdFromQuery,
            "",
            "",
            startTimeFromQuery,
            endTimeFromQuery,
            "asc"
          );
        }
      } else {
        setIsNewMessageLoading(false);
        // console.log(
        //   "Invalid start and end time:",
        //   startTimeFromQuery,
        //   endTimeFromQuery
        // );
        toast.error("Invalid start and end time");
      }
    }
  }, [
    endTimeFromQuery,
    allGroupDataLoaded,
    groupIdFromQuery,
    latestGroupNames,
    startTimeFromQuery,
  ]);

  // Used only when group id, start time and end time all are present in url

  useEffect(() => {
    if (
      mobileNumberFromQuery &&
      groupIdFromQuery &&
      startTimeFromQuery &&
      endTimeFromQuery &&
      allGroupDataLoaded
    ) {
      if (mobileNumberFromQuery.length < 7) {
        toast.error("Invalid mobile number");
        return;
      }
      const isValidStartTime = !isNaN(Date.parse(startTimeFromQuery));
      const isValidEndTime = !isNaN(Date.parse(endTimeFromQuery));

      if (groupIdFromQuery && isValidStartTime && isValidEndTime) {
        handleGroupFinderAndSetterById(groupIdFromQuery);
        setIsNewMessageLoading(true);
        // console.log("Valid group id:", groupIdFromQuery);
        fetchAndAppendMessages(
          groupIdFromQuery,
          "",
          "",
          startTimeFromQuery,
          endTimeFromQuery,
          "asc"
        );
        setMobileNumberSearchQuery(mobileNumberFromQuery);
        // console.log("mobileNumberSearchQuery", mobileNumberSearchQuery);
      }
    }
  }, [
    endTimeFromQuery,
    groupIdFromQuery,
    latestGroupNames,
    mobileNumberFromQuery,
    allGroupDataLoaded,
    startTimeFromQuery,
  ]);

  // handleDateTimeSearch when startTime and endTime are updated
  useEffect(() => {
    if (startTime && endTime && allGroupDataLoaded && selectedGroup) {
      setIsNewMessageLoading(true);
      handleDateTimeSearch();
    }
  }, [startTime, endTime, allGroupDataLoaded, selectedGroup]);

  //  Find and remove duplicates by comparing the last 2 message id of passed array with existing array
  const removeDuplicates = (newMessages, oldMessages, isNext) => {
    let messageIdSet = new Set();

    if (isNext) {
      const start = Math.max(0, oldMessages.length - 5);
      for (let i = start; i < oldMessages.length; i++) {
        // console.log(
        //   "duplicate found with id:",
        //   oldMessages[i].message.message_id
        // );
        messageIdSet.add(oldMessages[i].message.message_id);
      }
    } else {
      const end = Math.min(4, oldMessages.length);
      for (let i = 0; i < end; i++) {
        // console.log(
        //   "duplicate found with id:",
        //   oldMessages[i].message.message_id
        // );
        // console.log("oldMessages[i]", oldMessages[i]);
        messageIdSet.add(oldMessages[i].message.message_id);
      }
    }
    const deduplicatedMessages = newMessages.filter(
      (message) => !messageIdSet.has(message.message.message_id)
    );
    return deduplicatedMessages;
  };

  // Function to remove duplicate messages based on content
  // const removeDuplicatesFromMainData = (messages) => {
  //   const uniqueMessages = [];
  //   const messageSet = new Set(); // To keep track of unique content

  //   messages.forEach((message) => {
  //     const content = message.message.content;
  //     if (!messageSet.has(content)) {
  //       messageSet.add(content);
  //       uniqueMessages.push(message);
  //     }
  //   });

  //   return uniqueMessages;
  // };

  const seedValues = [
    "Garfield",
    "Chester",
    "Mia",
    "Ginger",
    "Patches",
    "Mimi",
    "Rocky",
    "Salem",
    "Sadie",
    "Snowball",
    "Sassy",
    "BuddyMidnight",
    "Lucky",
    "Annie",
    "Princess",
    "Oscar",
    "Nala",
    "Baby",
    "Mittens",
  ];

  // For personal chats
  // To get  user mobile number sorted by last message time
  const [userMobileNumbers, setUserMobileNumbers] = useState([
    "918864865605",
    "+918800582831",
    "919602071012",
    "919610724803",
    "919971130452",
  ]);
  const [selectedMobileNumber, setSelectedMobileNumber] = useState("");
  const [selectedTeamType, setSelectedTeamType] = useState("");
  const [userChatData, setUserChatData] = useState([]);
  const handleTeamTypeChange = (newTeamType) => {
    setSelectedTeamType(newTeamType);
  };

  const fetchUserMobileNumbers = async () => {
    try {
      const config = {
        url:
          process.env.REACT_APP_API_URL + "/whatsapp/get-recent-users-chats/",
        method: "get",
        headers: {
          Authorization: `Token ${getToken()}`,
          "Content-Type": "application/json",
        },
      };

      const response = await axios(config);
      const userMobileNumbers = response.data;
      // console.log("userMobileNumbers", userMobileNumbers);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    // fetchUserMobileNumbers();
  }, []);

  const handleMobileNumberSelection = (mobileNumber) => {
    setSelectedMobileNumber(mobileNumber);
    setSelectedGroupId("");
    setSelectedGroup("");
    setUserChatData([]);
    setSelectedTeamType("");
  };
  useEffect(() => {
    if (selectedMobileNumber !== "" && selectedTeamType !== "") {
      setIsNewMessageLoading(true);
      fetchUserData(selectedMobileNumber, selectedTeamType, "", "", "", "");
    }
  }, [selectedMobileNumber, selectedTeamType]);

  //  To get personal chat data

  const fetchUserData = async (
    selectedMobileNumber,
    chat_with,
    filter_time,
    more_records,
    start_time,
    end_time
  ) => {
    try {
      const data = {
        user_mobile: selectedMobileNumber,
        chat_with,
        filter_time,
        more_records,
        start_time,
        end_time,
      };
      const config = {
        method: "post",
        url: process.env.REACT_APP_API_URL + "whatsapp/get-user-messages/",
        headers: {
          Authorization: `Token ${getToken()}`,
          "Content-Type": "application/json",
        },
        data: JSON.stringify(data),
      };
      // console.log(config);
      // console.log("api called");

      const response = await axios(config);
      // console.log("response", response);
      if (response.data.length === 0) {
        toast.error("No messages found");
        setUserChatData([]);
        setIsDisabled(false);
        setIsPreviouMessageLoading(false);
        setIsNextMessageLoading(false);
        setIsNewMessageLoading(false);
        return;
      }
      const newChatData = response.data;
      // console.log("newChatData", newChatData);
      // Remove duplicates from messages content
      // newChatData = removeDuplicatesFromMainData(newChatData);
      // Sort the new messages by time
      newChatData.sort((a, b) => a.message.message_id - b.message.message_id);
      if (chat_with === "sales") {
        setUserChatData(newChatData);
        toast.success("Messages loaded successfully");
        setIsDisabled(false);
        setIsNewMessageLoading(false);
      } else if (chat_with === "support") {
        setUserChatData(newChatData);
        toast.success("Messages loaded successfully");
        setIsDisabled(false);
        setIsNewMessageLoading(false);
      } else if (more_records === "next") {
        // Append new messages to the end
        if (newChatData.length === 1) {
          toast.error("No more messages to show");
          setIsNextMessageLoading(false);
        } else {
          const uniqueNewMessages = removeDuplicates(
            newChatData,
            chatData,
            true
          );
          setUserChatData([...chatData, ...uniqueNewMessages]);
          toast.success("Next Messages loaded successfully");
          setIsDisabled(false);
          setIsNextMessageLoading(false);
        }
      } else if (more_records === "previous") {
        // Insert new messages at the beginning
        const uniqueNewMessages = removeDuplicates(
          newChatData,
          chatData,
          false
        );
        setUserChatData([...uniqueNewMessages, ...chatData]);
        toast.success("Previous Messages fetched successfully");
        setIsDisabled(false);
        setIsPreviouMessageLoading(false);
      } else if (start_time && end_time) {
        setUserChatData(newChatData);
        setIsDisabled(false);
        setIsNewMessageLoading(false);
        // toast.success("Messages fetched successfully");
      } else {
        setUserChatData(newChatData);
        setIsDisabled(false);
        setIsNewMessageLoading(false);
      }
    } catch (error) {
      // console.log(error.response.data);
      console.log("Error fetching data:", error);
      setIsDisabled(false);
      setIsNewMessageLoading(false);
      setIsPreviouMessageLoading(false);
      setIsNextMessageLoading(false);
      toast.error("Error fetching data");
    }
  };

  return (
    <>
      <div className="hidden md:block">
        <div className="flex w-full ">
          {/* Group sidebar */}
          {isGroupSidebarOpen && (
            <div className="flex h-screen w-4/12 flex-col ">
              <div className="relative bg-gray-200 px-2 py-2">
                <input
                  type="text"
                  className="w-full bg-white px-2 py-2 text-sm"
                  placeholder="Search groups"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    setSearchClicked(true);
                  }}
                />
                {searchQuery && (
                  <span
                    className="absolute top-4 right-2 cursor-pointer text-red-500"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent the button's click event from firing
                      setSearchQuery("");
                      setSearchClicked(false);
                    }}
                  >
                    <MdCancel />
                  </span>
                )}
              </div>
              <div className="overflow-y-auto bg-white" ref={containerRef}>
                {filteredGroups.map((group, index) => {
                  const wrappedIndex = index % seedValues.length;
                  return (
                    <div
                      className={`flex cursor-pointer items-center border-b border-gray-200  px-3 ${
                        group.group_name === selectedGroup
                          ? "bg-gray-200"
                          : "bg-white"
                      }`}
                      key={index + group.group_name}
                      onClick={() => handleGroupSelection(group.group_name)}
                    >
                      <div className="flex h-12 w-12 items-center justify-center rounded-full bg-gray-300">
                        <img
                          src={`https://api.dicebear.com/7.x/micah/svg?seed=${seedValues[wrappedIndex]}`}
                          alt={group.group_name}
                          width={48}
                          height={48}
                        />
                      </div>
                      <div className="ml-4 flex-1 py-4 ">
                        <div className="items-bottom flex flex-col justify-between">
                          <p className="text-black">{group.group_name}</p>
                          <p className="text-sm text-gray-700">
                            {group.message
                              ? `${group.message.substring(0, 20).trim()}${
                                  group.message.length > 20 ? "..." : ""
                                }`
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}

                {!searchClicked ? (
                  <>
                    <hr className="border-2 border-blue-500"></hr>
                    <p className="text-center font-bold text-blue-500">
                      ALL GROUPS
                    </p>
                    <hr className="border-2 border-blue-500"></hr>
                    {normalGroupData.map((group, index) => {
                      const wrappedIndex = index % seedValues.length;
                      return (
                        <div
                          className={`flex cursor-pointer items-center border-b border-gray-200  px-3 ${
                            group.group_name === selectedGroup
                              ? "bg-gray-200"
                              : "bg-white"
                          }`}
                          key={index + group.group_name}
                          onClick={() => handleGroupSelection(group.group_name)}
                        >
                          <div className="flex h-12 w-12 items-center justify-center rounded-full bg-gray-300">
                            <img
                              src={`https://api.dicebear.com/7.x/micah/svg?seed=${seedValues[wrappedIndex]}`}
                              alt={group.group_name}
                              width={48}
                              height={48}
                            />
                          </div>
                          <div className="ml-4 flex-1 py-4">
                            <div className="items-bottom flex justify-between">
                              <p className="text-gray-700">
                                {group.group_name}
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  ""
                )}

                {/* <button
                  onClick={handleViewMoreClick}
                  className="px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600 md:px-8"
                >
                  {showLatestGroups ? "View All Groups" : "View Latest Groups"}
                </button> */}

                {/* All personal chat */}
                {/* {userMobileNumbers.slice(0, 20).map((userMobile, index) => {
                const wrappedIndex = index % seedValues.length;
                return (
                  <div
                    className={`flex cursor-pointer items-center border-b border-gray-200  px-3 ${
                      userMobile === selectedMobileNumber
                        ? "bg-gray-200"
                        : "bg-white"
                    }`}
                    key={index + userMobile}
                    onClick={() => handleMobileNumberSelection(userMobile)}
                  >
                    <div className="flex items-center justify-center w-12 h-12 bg-gray-300 rounded-full">
                      <img
                        src={`https://api.dicebear.com/7.x/micah/svg?seed=${seedValues[wrappedIndex]}`}
                        alt={userMobile}
                        width={48}
                        height={48}
                      />
                    </div>
                    <div className="flex-1 py-4 ml-4 ">
                      <div className="flex justify-between items-bottom">
                        <p className="text-gray-700">{userMobile}</p>
                      </div>
                    </div>
                  </div>
                );
              })} */}
              </div>
            </div>
          )}
          {/* add a hamburger icon to close group sidebar */}

          {/* End */}
          <div className="flex h-screen w-full flex-col ">
            <div className="bg-gray-300 ">
              <div className="flex flex-row items-center justify-between bg-gray-200 px-3 py-2">
                <div>
                  <p className="font-bold text-gray-700">
                    {selectedGroup || selectedMobileNumber}
                  </p>
                </div>
                <div>
                  <FiltersDropdown
                    isDropdownOpen={isDropdownOpen}
                    toggleDropdown={toggleDropdown}
                    mobileNumberSearchQuery={mobileNumberSearchQuery}
                    setMobileNumberSearchQuery={setMobileNumberSearchQuery}
                    handleMobileNumberSearch={handleMobileNumberSearch}
                    startTime={startTime}
                    endTime={endTime}
                    setStartTime={setStartTime}
                    setEndTime={setEndTime}
                    handleDateTimeSearch={handleDateTimeSearch}
                    isDisabled={isDisabled}
                    handleInputFocus={handleInputFocus}
                    filterIcon={<BiDotsVerticalRounded color="gray" />}
                    dropdownRef={dropdownRef}
                    isDropdownPhoneOpen={isDropdownPhoneOpen}
                    phoneToggleDropdown={phoneToggleDropdown}
                    closePhoneDropdowns={closePhoneDropdowns}
                  />
                </div>
              </div>
              {selectedMobileNumber ? (
                <ChatContainerPersonal
                  chatData={userChatData}
                  mobileNumberSearchQuery={mobileNumberSearchQuery}
                  handleShowPreviousMessages={handleShowPreviousMessages}
                  handleNextMessages={handleNextMessages}
                  isPreviousMessageLoading={isPreviousMessageLoading}
                  isNextMessageLoading={isNextMessageLoading}
                  isNewMessageLoading={isNewMessageLoading}
                  selectedTeamType={selectedTeamType}
                  onTeamTypeChange={handleTeamTypeChange}
                />
              ) : (
                <ChatContainer
                  chatData={chatData}
                  setMobileNumberSearchQuery={setMobileNumberSearchQuery}
                  mobileNumberSearchQuery={mobileNumberSearchQuery}
                  handleShowPreviousMessages={handleShowPreviousMessages}
                  handleNextMessages={handleNextMessages}
                  isPreviousMessageLoading={isPreviousMessageLoading}
                  isNextMessageLoading={isNextMessageLoading}
                  isNewMessageLoading={isNewMessageLoading}
                  isPreviousMessageLoaded={isPreviousMessageLoaded}
                  newMessageLength={newMessageLength}
                  selectedGroupId={selectedGroupId}
                />
              )}
            </div>
          </div>
          <ToastContainer
            position="bottom-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop
            closeOnClick={false}
            rtl={false}
            pauseOnFocusLoss={false}
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      </div>

      <div className="md:hidden">
        {selectedGroup ? (
          <div className="flex">
            <div className="flex h-screen w-full">
              <div className="overflow-y-auto bg-gray-300">
                <div className="flex flex-row items-center justify-between bg-gray-200 px-3 py-2">
                  <div className="flex">
                    <span
                      className="cursor-pointer items-center pt-1 pr-1"
                      onClick={() => setSelectedGroup(null)}
                    >
                      <BsFillArrowLeftCircleFill color="black" />
                    </span>
                    <p className="text-gray-700">{selectedGroup}</p>
                  </div>
                  <div>
                    <FiltersDropdown
                      isDropdownOpen={isDropdownOpen}
                      toggleDropdown={toggleDropdown}
                      mobileNumberSearchQuery={mobileNumberSearchQuery}
                      setMobileNumberSearchQuery={setMobileNumberSearchQuery}
                      handleMobileNumberSearch={handleMobileNumberSearch}
                      startTime={startTime}
                      endTime={endTime}
                      setStartTime={setStartTime}
                      setEndTime={setEndTime}
                      handleDateTimeSearch={handleDateTimeSearch}
                      isDisabled={isDisabled}
                      handleInputFocus={handleInputFocus}
                      filterIcon={<BiDotsVerticalRounded color="gray" />}
                      dropdownRef={dropdownRef}
                      isDropdownPhoneOpen={isDropdownPhoneOpen}
                      phoneToggleDropdown={phoneToggleDropdown}
                      closePhoneDropdowns={closePhoneDropdowns}
                    />
                  </div>
                  <div></div>
                </div>
                <ChatContainer
                  chatData={chatData}
                  mobileNumberSearchQuery={mobileNumberSearchQuery}
                  handleShowPreviousMessages={handleShowPreviousMessages}
                  handleNextMessages={handleNextMessages}
                  isPreviousMessageLoading={isPreviousMessageLoading}
                  isNextMessageLoading={isNextMessageLoading}
                  isNewMessageLoading={isNewMessageLoading}
                  selectedGroupId={selectedGroupId}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="flex h-screen">
            <div className="flex w-full">
              <div className="flex w-full flex-col">
                <div className="bg-gray-100 px-2 py-2">
                  <input
                    type="text"
                    className="w-full bg-white px-2 py-2 text-sm"
                    placeholder="Search new chat"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
                <div className="h-screen overflow-y-auto bg-white">
                  {filteredGroups.map((group, groupId) => {
                    const wrappedIndex = groupId % seedValues.length;
                    return (
                      <div
                        className={`flex cursor-pointer items-center  px-3 ${
                          group.group_name === selectedGroup
                            ? "bg-gray-200"
                            : "bg-white"
                        }`}
                        key={groupId}
                        onClick={() => handleGroupSelection(group.group_name)}
                      >
                        <div className="flex h-12 w-12 items-center justify-center rounded-full bg-gray-300">
                          {" "}
                          <img
                            src={`https://api.dicebear.com/7.x/micah/svg?seed=${seedValues[wrappedIndex]}`}
                            alt={group.group_name}
                            width={48}
                            height={48}
                          />
                        </div>
                        <div className="ml-4 flex-1 border-b border-gray-200 py-4">
                          <div className="items-bottom flex flex-col gap-0">
                            <p className="text-black">{group.group_name}</p>
                            <p className="text-sm text-gray-700">
                              {group.message
                                ? `${group.message.substring(0, 34).trim()}${
                                    group.message.length > 34 ? "..." : ""
                                  }`
                                : ""}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
        <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </>
  );
};

export default ChatMain;

import axios from "axios";
import UserDataPopup from "components/UserData/UserDataPopup";
import { useEffect, useRef, useState } from "react";
import { BsChatSquareTextFill } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";
import { useInView } from "react-intersection-observer";
import { useSearchParams } from "react-router-dom";
import Select from "react-select";
import { ClipLoader } from "react-spinners";
import { MdClose, MdOpenInNew } from "react-icons/md";
import { TiTickOutline } from "react-icons/ti";
import { toast, ToastContainer } from "react-toastify";
import { getToken } from "utils/getToken";
import "../../assets/css/scrollBar.css";
import woman from "../../assets/img/avatars/woman.png";
import ChatBubble from "./ChatBubble";
import MultiSelect from "./MultiSelect";

function QNAs() {
  const [chatList, setChatList] = useState([]);
  const [convo, setConvo] = useState([]);
  const [listLoading, setlistLoading] = useState(false);
  const [convoLoading, setConvoLoading] = useState(false);
  const [activeChat, setActiveChat] = useState(null);
  const [activeUser, setActiveUser] = useState("");
  const [isPDataModalOpen, setIsPDataModalOpen] = useState(false);
  const [selectedChatType, setSelectedChatType] = useState("");
  const [filter, setFilter] = useState({
    sort: "",
    type: "",
    doctor_id: "",
    end_date: "",
    start_date: "",
  });
  const [selectedDate, setSelectedDate] = useState({
    start_date: "",
    end_date: "",
  });
  const [doctorsData, setDoctorsData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [totalMessages, setTotalMessages] = useState(0);
  const [doctorCareCount, setDoctorCareCount] = useState(0);
  const [followupCount, setFollowupCount] = useState(0);
  const [botCount, setBotCount] = useState(0);
  const [activeConvoId, setActiveConvoId] = useState(null);
  const [mobile, setMobile] = useState("");
  const [statusParam] = useSearchParams();
  const param = statusParam.get("status");
  const [initialArray, setInitialArray] = useState(()=>{return param === 'actionable' ? [param] : []});
  const [statusArray, setStatusArray] = useState(()=>{return param === 'actionable' ? [param] : []});
  const [statusSubsArray, setStatusSubsArray] = useState([]);
  const [userMobile, setUserMobile] = useState("");

  const { ref, inView } = useInView({ threshold: 0.1 });

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Token ${getToken()}`,
  };

  const selectClassName =
    "focus:ring-primary rounded-md border border-gray-300 bg-white px-2 py-2 text-base ring-opacity-5 hover:bg-gray-50 focus:outline-none focus:ring-2 cursor-pointer ring-offset-1";

  const statusFilterOptions = [
    { name: "Close", value: "close" },
    { name: "Doctor Pending", value: "doctor_pending" },
    { name: "Actionable", value: "actionable" },
  ];

  const statusSubsFilterOptions = [
    { name: "BTP", value: "btp" },
    { name: "Holistic", value: "holistic" },
    { name: "Essential", value: "essential" },
  ];

  const chatTypeData = [
    { name: "All", value: "" },
    { name: "Doctor Care", value: "doctor_care" },
    { name: "Follow-up", value: "followup" },
    { name: "Bot", value: "qna" },
  ];

  if (filter.type === "" || filter.type === "qna") {
    statusFilterOptions.splice(1, 0, { name: "Open", value: "open" });
  }

  const getChatList = async (formattedMobile, resetOffset) => {
    setlistLoading(true);
    try {
      const statusQuery = statusArray.join(",");
      const statusSubsQuery = statusSubsArray.join(",");
      const response = await axios.get(
        process.env.REACT_APP_API_URL +
          `bot/support-portal/conversation/chat-list/?status=${statusQuery}&subs=${
            statusSubsQuery === "all" ? "" : statusSubsQuery
          }&sort=${filter.sort}&offset=${
            resetOffset === 0 ? 0 : offset
          }&mobile=${formattedMobile || ""}&type=${filter.type}&end_date=${
            filter.end_date
          }&start_date=${filter.start_date}&doctor_id=${filter.doctor_id}`,
        {
          headers,
        }
      );

      const responseCount = await axios.get(
        process.env.REACT_APP_API_URL +
          `bot/support-portal/conversation/count/pending/`,
        {
          headers,
        }
      );

      if (responseCount.status === 200) {
        setDoctorCareCount(responseCount.data.doctor_care.actionable);
        setFollowupCount(responseCount.data.followup.actionable);
        setBotCount(responseCount.data.qna.actionable);
      }

      if (response.status === 200) {
        setChatList((prev) => [...prev, ...response?.data?.messages]);
        setTotalMessages(response?.data?.totalMessages);
        setOffset(response?.data?.next_offset);
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch chat list");
    } finally {
      setlistLoading(false);
    }
  };

  useEffect(() => {
    if (inView && offset !== null && !listLoading) {
      getChatList(mobile.replace(/[^\d+]/g, ""), offset);
    }
  }, [inView]);

  useEffect(() => {
    setOffset(0);
    setConvo([]);
    setActiveUser("");
    setActiveChat(null);
    setChatList([]);
    getChatList();
  }, [filter, statusArray, statusSubsArray]);

  const getConvo = async (id, chatid, user_name, user_mobile) => {
    setConvoLoading(true);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_URL +
          `bot/support-portal/conversation/messages/`,
        { conversation_id: id },
        {
          headers,
        }
      );
      if (response.status === 200) {
        setConvo(response?.data?.results);
        setActiveConvoId(id);
        setActiveChat(chatid);
        setActiveUser(user_name);
        setUserMobile(user_mobile);
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to fetch conversation");
    } finally {
      setConvoLoading(false);
    }
  };

  const handleFilterChange = ({ target: { name, value } }) => {
    setOffset(0);
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChatTypeSelection = (value) => {
    setOffset(0);
    setFilter((prev) => ({
      ...prev,
      type: value,
    }));
    setSelectedChatType(value);
  };

  const handleDateChange = (e) => {
    setOffset(0);
    const { name, value } = e.target;
    setSelectedDate((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    setOffset(0);
    if (selectedDate.start_date && selectedDate.end_date) {
      setFilter((prev) => ({
        ...prev,
        start_date: selectedDate.start_date,
        end_date: selectedDate.end_date,
      }));
    }
  }, [selectedDate]);

  const handleStatusChange = (newSelectedStatuses) => {
    setOffset(0);
    setStatusArray(newSelectedStatuses);
  };
  const handleStatusSubsChange = (newSelectedStatuses) => {
    setOffset(0);
    setStatusSubsArray(newSelectedStatuses);
  };

  const fetchDoctors = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}doctors/doctors/all/`,
        {
          headers,
        }
      );
      setDoctorsData(data);
    } catch (error) {
      console.error("Error fetching doctors:", error);
      toast.error("Failed to fetch doctors");
    }
  };

  useEffect(() => {
    setOffset(0);
    fetchDoctors();
  }, []);

  const getFormattedDate = (dateISO) => {
    const date = new Date(dateISO);
    const formattedDate = date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
    });
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return `${formattedDate} at ${formattedTime}`;
  };

  const handlePDataModal = () => {
    setIsPDataModalOpen(true);
  };
  const handlePDataModalClose = () => {
    setIsPDataModalOpen(false);
  };

  const handlePhSearch = () => {
    setChatList([]);
    const formattedMobile = `+${mobile}`;
    getChatList(formattedMobile, 0);
  };

  useEffect(() => {
    if (mobile !== "" && mobile.length > 7) {
      handlePhSearch();
    }
  }, [mobile]);

  const handlePhNoClear = () => {
    setChatList([]);
    setMobile("");
    getChatList(null, 0);
  };

  const scollRef = useRef(null);
  useEffect(() => {
    if (scollRef.current) {
      scollRef.current.scrollTop = scollRef.current.scrollHeight;
    }
  }, [activeUser, activeChat, activeConvoId, offset]);

  const handleCloseChat = async (id) => {
    const data = {
      conversation_id: id,
      status: "close",
    };
    const dataFinal = JSON.stringify(data);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}bot/user/conversation/status/update/`,
        dataFinal,
        { headers }
      );
      if (response.status === 200) {
        toast.success("Chat closed successfully.");
        setChatList([]);
        setMobile("");
        setOffset(0);
        getChatList(null, 0);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getIsClosedStatus = (id) => {
    const chat = chatList.find((chat) => chat.conversation_id === id);
    return chat?.status === "close";
  };

  return (
    <div className="relative h-[100vh] bg-white pt-2">
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
      {isPDataModalOpen && (
        <UserDataPopup
          selectedUser={userMobile}
          noSearch={true}
          handlePDataModalClose={handlePDataModalClose}
        />
      )}
      <div className="pl-1 pr-5">
        <div className="mb-4 flex items-center justify-between px-2">
          <h1 className="text-3xl font-semibold uppercase">
            Questions and Answers
          </h1>
          <button
            className="rounded-lg bg-blue-500 px-4 py-2 text-white"
            onClick={() => {
              setOffset(0);
              setMobile("");
              setChatList([]);
              getChatList(null, 0);
              activeConvoId !== null &&
                getConvo(activeConvoId, activeChat, activeUser);
            }}
          >
            Refresh
          </button>
        </div>

        <div className="flex flex-wrap items-center gap-4 px-2 pb-3">
          <MultiSelect
            filterOptions={statusFilterOptions}
            onChange={handleStatusChange}
            filterName="Status"
            preSelectedFilters={initialArray}
          />
          <MultiSelect
            filterOptions={statusSubsFilterOptions}
            onChange={handleStatusSubsChange}
            filterName="Subscription"
          />

          <label className="text-md mt-1 flex">
            <select
              className={selectClassName}
              name="sort"
              onChange={handleFilterChange}
              value={filter.sort}
            >
              <option value="">Sort</option>
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </select>
          </label>
          <label className="text-md mt-1 flex">
            <Select
              className="focus:ring-primary min-w-[200px] cursor-pointer rounded-md border-none bg-white text-base hover:bg-gray-50 focus:outline-none focus:ring-2"
              name="doctor_id"
              onChange={(selectedOption) =>
                handleFilterChange({
                  target: { name: "doctor_id", value: selectedOption?.value },
                })
              }
              value={
                doctorsData
                  .map((doctor) => ({ value: doctor.id, label: doctor.name }))
                  .find((option) => option.value === filter.doctor_id) || {
                  value: "",
                  label: "Find a doctor",
                }
              }
              options={[
                { value: "", label: "Select a doctor" },
                ...doctorsData.map((doctor) => ({
                  value: doctor.id,
                  label: doctor.name,
                })),
              ]}
              placeholder="Select a doctor"
              styles={{
                control: (base, state) => ({
                  ...base,
                  borderColor: "#cbd5e0",
                  boxShadow: state.isFocused ? "0 0 0 1px #3b82f6" : "none",
                  "&:hover": {
                    borderColor: "#cbd5e0",
                  },
                }),
              }}
            />
          </label>

          <label className="text-md -mt-4 flex flex-col">
            <span className="font-semibold">Start Date:</span>
            <input
              type="date"
              className={selectClassName}
              name="start_date"
              onChange={handleDateChange}
              value={selectedDate.start_date}
            />
          </label>
          <label className="text-md -mt-4 flex flex-col">
            <span className="font-semibold">End Date:</span>
            <input
              type="date"
              className={selectClassName}
              name="end_date"
              onChange={handleDateChange}
              value={selectedDate.end_date}
            />
          </label>
        </div>
      </div>

      <div className="grid h-auto w-full grid-cols-12">
        <div className="col-span-4 flex h-[82vh] flex-col items-center border-t">
          <div className="flex w-full items-center justify-center shadow-sm">
            {chatTypeData.map((chatType, i) => (
              <span
                key={i}
                onClick={() => handleChatTypeSelection(chatType.value)}
                className={`relative w-1/4 cursor-pointer px-2 py-4 text-center hover:text-gray-700 ${
                  selectedChatType === chatType.value
                    ? "border-b-4 border-blue-400"
                    : ""
                }`}
              >
                {chatType.name}
                {chatType.value === "doctor_care" && (
                  <span className="absolute top-2 -right-1 flex h-4 w-4 items-center justify-center rounded-full bg-yellow-300 text-xs font-semibold text-yellow-700">
                    {doctorCareCount}
                  </span>
                )}
                {chatType.value === "followup" && (
                  <span className="absolute top-2 right-1 flex h-4 w-4 items-center justify-center rounded-full bg-yellow-300 text-xs font-semibold text-yellow-700">
                    {followupCount}
                  </span>
                )}
                {chatType.value === "qna" && (
                  <span className="absolute top-2 right-5 flex h-4 w-4 items-center justify-center rounded-full bg-yellow-300 text-xs font-semibold text-yellow-700">
                    {botCount}
                  </span>
                )}
              </span>
            ))}
          </div>
          <div className="flex w-full items-center justify-center py-2 px-1 shadow-sm">
            <div className="relative w-[85%]">
              <input
                type="text"
                placeholder="Search by phone number"
                value={mobile}
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
                className="w-full rounded-lg border border-gray-400 px-5 py-2 pr-10"
              />
              <MdClose
                onClick={handlePhNoClear}
                className="absolute right-3 top-1/2 h-6 w-6 -translate-y-1/2 transform cursor-pointer text-gray-400"
              />
            </div>
            <button
              className="flex w-[15%] items-center justify-center"
              onClick={handlePhSearch}
            >
              <FaSearch className="h-6 w-6" />
            </button>
          </div>

          <div className="custom-scrollbar h-full w-full overflow-auto bg-white">
            {chatList.length > 0 &&
              chatList.map((msg, i) => {
                return (
                  <div
                    onClick={() =>
                      getConvo(
                        msg.conversation_id,
                        msg?.id,
                        msg?.user_name,
                        msg?.user_mobile
                      )
                    }
                    key={i}
                    style={
                      activeChat === msg?.id
                        ? { backgroundColor: "#edf2f7" }
                        : {}
                    }
                    className={`w-full cursor-pointer gap-x-4 border-b px-3 py-3 hover:bg-gray-50 ${
                      msg?.is_actionable
                        ? "border-l-2 border-b border-orange-300 bg-orange-100"
                        : "bg-white hover:bg-gray-50"
                    }`}
                  >
                    <div className="flex items-center gap-2 font-semibold text-gray-800">
                      <div className="flex w-[15%] items-center justify-center">
                        <img src={woman} className="h-12 w-12" alt="avatar" />
                      </div>
                      <div className="flex w-[85%] flex-col gap-y-[1px]">
                        <div className="flex items-center justify-between">
                          <p>{msg?.user_name}</p>
                          <p className="text-sm font-normal text-blue-500">
                            {getFormattedDate(msg?.updated_at)}
                          </p>
                        </div>
                        <div className="flex items-center justify-between">
                          <div className="flex items-center justify-center gap-x-2">
                            {msg?.last_msg?.content && (
                              <p className="text-sm font-light text-gray-700">
                                {msg?.last_msg?.content?.length >= 16
                                  ? msg?.last_msg?.content?.slice(0, 16) + "..."
                                  : msg?.last_msg?.content}
                              </p>
                            )}
                            <p className="text-sm font-semibold text-gray-700">
                              {msg?.sender_type ? ` - ${msg?.sender_type}` : ``}
                            </p>
                          </div>
                          <p className="text-sm font-semibold text-blue-500">
                            {msg?.doctor_name || ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

            <div
              ref={ref}
              className="mt-3 flex h-10 w-full items-center justify-center"
            >
              {listLoading && <ClipLoader color={"blue"} size={30} />}
            </div>
          </div>
        </div>
        {convoLoading ? (
          <div className="col-span-8 flex h-full items-center justify-center">
            <ClipLoader color={"gray"} size={60} />
          </div>
        ) : (
          <div className="relative col-span-8 h-[82vh] pb-2 overflow-hidden border-l border-t">
            {activeUser ? (
              <div
                onClick={handlePDataModal}
                className="flex h-[12%] w-full cursor-pointer items-center justify-between border-b py-2 px-6 pl-6 shadow-sm"
              >
                <div className="flex items-center gap-x-4">
                  <div className="p-1">
                    <img src={woman} className="h-10 w-10" alt="avatar" />
                  </div>
                  <div className="flex items-center justify-center gap-x-3 text-blue-700 underline hover:text-blue-900">
                    <span>{activeUser}</span>
                    <MdOpenInNew />
                  </div>
                </div>
                <div className="flex items-center justify-center gap-x-6">
                  <div
                    className="flex items-center gap-x-1 hover:text-blue-500 hover:underline"
                    onClick={(e) => {
                      e.stopPropagation();
                      setMobile(userMobile);
                    }}
                  >
                    <BsChatSquareTextFill />
                    <p>See all chats</p>
                  </div>
                  {!getIsClosedStatus(activeConvoId) && !listLoading ? (
                    <div
                      className="flex items-center gap-x-1 rounded-md bg-green-600 px-3 py-2 text-white hover:bg-green-500"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCloseChat(activeConvoId);
                      }}
                    >
                      <TiTickOutline />
                      <p>Close this chat</p>
                    </div>
                  ) : null}
                </div>
              </div>
            ) : (
              <div className="flex h-full items-center justify-center bg-white">
                <span className="flex items-center justify-center gap-x-3">
                  No User Selected
                </span>
              </div>
            )}
            <div
              ref={scollRef}
              className="custom-scrollbar h-[calc(100%-64px)] w-full overflow-auto bg-white px-4"
            >
              {convo.map((item, i) => (
                <ChatBubble msg={item} key={i} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default QNAs;

import { atom } from "recoil";

export const whatsappSessionModalState = atom({
  key: "whatsappSessionModalState",
  default: {
    open: false,
    sessionDetails: null,
    sessionId: null,
    type: null,
  },
});

export const startSessionModalState = atom({
  key: "startSessionModalState",
  default: {
    open: false,
    sessionId: null,
    sessionName: null,
    sessionStatus: null,
  },
});

export const isSessionDetailsUpdatedState = atom({
  key: "isSessionDetailsUpdated",
  default: false,
});

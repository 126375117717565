import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import Navbar from "components/navbar";
import Sidebar from "components/sidebar";
import Footer from "components/footer/Footer";
import routes from "routes.js";

export default function Admin() {
  const location = useLocation();
  const [open, setOpen] = useState(true);

  // useEffect(() => {
  //   window.addEventListener("resize", () => {
  //     window.innerWidth < 1200 ? setOpen(false) : setOpen(true);
  //   });
  //   window.innerWidth < 901 ? setOpen(false) : setOpen(true);
  //   return () => {
  //     window.removeEventListener("resize", () => {});
  //   };
  // }, []);

  const getActiveNavbar = (routes) => {
    const activeRoute = routes.find(
      (route) => location.pathname.indexOf(route.layout + route.path) !== -1
    );

    return activeRoute ? activeRoute.secondary : false;
  };

  const getAdminRoutes = () => {
    return routes
      .filter((prop) => prop.layout === "/admin")
      .map((prop, key) => (
        <Route path={`/${prop.path}`} element={prop.component} key={key} />
      ));
  };

  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      {/* {open ? (
      ) : (
        <span className="hidden py-2 text-xl text-gray-700 dark:text-white xl:inline">
          <GiHamburgerMenu
            onClick={() => setOpen(true)}
            className="h-8 w-8 cursor-pointer"
          />
        </span>
      )} */}
      <div className="h-full w-full bg-lightPrimary dark:!bg-navy-900">
        <main className="h-full flex-none">
          <div className="h-full">
            <Navbar
              onOpenSidenav={() => setOpen(true)}
              secondary={getActiveNavbar(routes)}
            />
            <div className="mx-auto h-auto">
              <Routes>
                {getAdminRoutes()}
                <Route
                  path="/"
                  element={<Navigate to="/auth/sign-in" replace />}
                />
              </Routes>
            </div>
            <div className="hidden p-2">
              <Footer />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

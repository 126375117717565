import React, { useState, useEffect } from "react";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getToken } from "utils/getToken";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { toast, ToastContainer } from "react-toastify";
import { useRecoilState } from "recoil";
import {
  openAddUpdatePatientModalOthersState,
  patientRefetchState,
} from "store/patientModalState";
import SyncLoader from "components/Loaders/SyncLoader";
import PatientModal from "components/PatientModal";
import UserDataPopup from "../UserData/UserDataPopup";
import { useNavigate } from "react-router-dom";

const MySwal = withReactContent(Swal);

const headers = {
  "Content-Type": "application/json",
  Authorization: `Token ${getToken()}`,
};

const BookConsultation = () => {
const navigate = useNavigate();

  const [mobile, setMobile] = useState("");
  const [token, setToken] = useState(getToken());
  const [loading, setLoading] = useState(false);
  const [consultationBooked, setConsultationBooked] = useState(false);
  const [doctors, setDoctors] = useState([]);
  const [doctorId, setDoctorId] = useState(0);
  const [slots, setSlots] = useState([]);
  const consultationTypeOption = [
    "Choose an option",
    "lactation",
    "nutrition",
    "gyne",
    "derma",
    "pediatric",
    "growth",
    "others",
  ];
  const [selectedConsultation, setSelectedConsultation] = useState(
    consultationTypeOption[0]
  );
  const [selectedDatetime, setSelectedDatetime] = useState(dayjs());
  const [selectedSlot, setSelectedSlot] = useState(0);
  const [selectedPatientId, setSelectedPatientId] = useState("");
  const formattedDatetime = selectedDatetime.format("YYYY-MM-DDTHH:mm:ss");
  const [isDisabled, setIsDisabled] = useState(false);

  const fetchDoctors = async (category) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}doctors/doctors/all?category=${category}&list_type=all`,
        {
          headers,
        }
      );
      const doctorsData = response.data.map((doctor) => ({
        value: doctor.id,
        label: doctor.name,
      }));
      setDoctors(doctorsData);
      setDoctorId(doctorsData[0]?.value || 0);
      fetchDoctorSlots(doctorsData[0]?.value || 0);
    } catch (error) {
      console.error("Error fetching doctors:", error);
      toast.error("Failed to fetch doctors");
    }
  };

  const fetchDoctorSlots = async (doctorId) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}doctors/get-doctor-appointment-slot/`,
        {
          doctor_id: doctorId,
          source: "support portal",
          slot_type: selectedConsultation,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      const slotData = data.appointments.map((slot) => ({
        value: slot.id,
        label: `${slot.date} (${dayjs(slot.time_from, "HH:mm").format(
          "hh:mm A"
        )} - ${dayjs(slot.time_to, "HH:mm").format("hh:mm A")})`,
      }));
      setSlots(slotData);
      setSelectedSlot(slotData[0]?.value || 0);
    } catch (error) {
      console.error("Error fetching doctor slots:", error);
      toast.error("Failed to fetch doctor slots");
    }
  };

  useEffect(() => {
    if (
      selectedConsultation === "growth" ||
      selectedConsultation === "lactation"
    ) {
      setDoctors([]);
      setSlots([]);
      setDoctorId(0);
      setSelectedSlot(0);
      fetchDoctors(selectedConsultation);
    }
  }, [selectedConsultation]);

  const handleConsultationButton = () => {
    if (selectedConsultation === consultationTypeOption[0]) {
      toast.error("Please select a consultation type.");
      return;
    }

    if (mobile.length < 7) {
      toast.error("Please enter a valid mobile number.");
      return;
    }

    if (!selectedPatientId) {
      toast.error("Please select a patient.");
      return;
    }

    if (!selectedDatetime.isValid()) {
      toast.error("Please select a valid date and time for the appointment.");
      return;
    }
    if (
      selectedConsultation === "lactation" ||
      selectedConsultation === "growth"
    ) {
      if (!selectedSlot) {
        toast.error("Please select a slot for the appointment.");
        return;
      }
      if (!doctorId) {
        toast.error("Please select a doctor for the appointment.");
        return;
      }
    }

    setLoading(true);

    const data = JSON.stringify({
      mobile: `+${mobile}`,
      consultation_type: selectedConsultation,
      scheduled_at: formattedDatetime,
      patient_id: selectedPatientId,
      doctor_id: doctorId,
      slot_id: selectedSlot,
      // Dont change the below source
      source: "manual consultation from support portal",
    });

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}subscription/book-manual-consultation/`,
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        if (response.status === 200) {
          MySwal.fire({
            title: "Congratulations!",
            icon: "success",
            text: "Your consultation has been booked. Thank you!",
            showConfirmButton: true,
          });
          resetForm();
        }
      })
      .catch((error) => {
        if (
          error.response.data.message.includes(
            "No active subscriptions found for the user"
          )
        ) {
          MySwal.fire({
            title: "Error",
            icon: "error",
            html:
              "It seems like you do not have access to book this consultation. Kindly take a plan or upgrade to a plan from the " +
              '<a style="color: primary; text-decoration: underline" href="https://babynama.com/care-plans">Care Plans</a> page.',
            showConfirmButton: true,
          });
        } else {
          MySwal.fire({
            title: "Error",
            icon: "error",
            text: error.response.data.message,
            showConfirmButton: true,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (mobile.length < 7) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [mobile]);

  const resetForm = () => {
    setSelectedConsultation(consultationTypeOption[0]);
    setMobile("+91");
    setConsultationBooked(false);
    setIsDataFetched(false);
    setDoctors([]);
    setDoctorId(0);
    setSlots([]);
    setSelectedPatientId("");
    setSelectedDatetime(dayjs());
    setSelectedSlot(0);
  };

  const [patients, setPatients] = useState(null);
  const [fetchingPatients, setFetchingPatients] = useState(false);
  const [openAddUpdatePatientModal, setOpenAddUpdatePatientModal] =
    useRecoilState(openAddUpdatePatientModalOthersState);
  const [shouldRefetch, setShouldRefetch] = useRecoilState(patientRefetchState);
  const [isDataFetched, setIsDataFetched] = useState(false);

  const getPatients = async () => {
    try {
      setFetchingPatients(true);

      const checkOnboardingResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}consultation/check-onboarding-status/`,
        {
          phone_number: `+${mobile}`,
          consultation_type: selectedConsultation.toLowerCase(),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${getToken()}`,
          },
        }
      );

      fetchPatientsData();

    } catch (error) {
      if (error.response && error.response.status === 400) {
        MySwal.fire({
          title: "Booking Not Allowed",
          text: "Onboarding has not been done for this user. Please complete the onboarding.",
          icon: "error",
          confirmButtonColor: "#d33",
          confirmButtonText: "Complete Onboarding",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(`/admin/userdata?mobile_number=+${mobile}`);
          }
          // resetForm();
        });
      }
       else {
        console.error("Error checking onboarding status:", error);
        toast.error("Failed to check onboarding status");
      }
    } finally {
      setFetchingPatients(false);
    }
  };

  const fetchPatientsData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}patients/get-patients/`,
        {
          mobile: `+${mobile}`,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${getToken()}`,
          },
        }
      );

      if (response.status === 200) {
        setIsDataFetched(true);
        setPatients(response.data.length > 0 ? response.data : []);
      }
    } catch (error) {
      console.error("No patient found");
    }
  };


  useEffect(() => {
    if (shouldRefetch) {
      getPatients();
      setShouldRefetch(false);
    }
  }, [shouldRefetch]);

  const handlePatientChange = (e) => {
    if (e.target.value) {
      if (e.target.value === "addPatient") {
        setOpenAddUpdatePatientModal({
          userMobile: `+${mobile}`,
          open: true,
          type: "add",
          appointmentIndex: null,
        });
      } else {
        setSelectedPatientId(e.target.value);
        const selectedPatient = patients.find(
          (p) => p.id.toString() === e.target.value
        );
      }
    } else {
      setSelectedPatientId("");
    }
  };
  const handleNoNameClick = () => {
    if (!patients || patients.length === 0 || !selectedPatientId) {
      toast.warn("Please provide a name for the patient.");
      setOpenAddUpdatePatientModal({
        open: true,
        type: "update",
        patientDetails: patients.find(
          (patient) => patient.id === parseInt(selectedPatientId)
        ),
        userMobile: `+${mobile}`,
        appointmentIndex: selectedPatientId,
      });
    }
  };
  const [selectedUser, setSelectedUser] = useState(null);
  const [isPDataModalOpen, setIsPDataModalOpen] = useState(false);
  const handlePDataModalOpen = (mobile) => {
    setSelectedUser(mobile);
    setIsPDataModalOpen(true);
  };

  const handlePDataModalClose = () => {
    setIsPDataModalOpen(false);
    setSelectedUser(null);
  };

  return (
    <>
      <div className="items-center justify-center px-4 mx-auto md:mx-0 md:w-full md:p-4">
        <h1 className="my-2 text-lg font-bold text-center text-gray-900 capitalize dark:text-white md:pt-5 md:text-4xl">
          Book Complimentary Consultation
        </h1>
        <p className="text-base text-center text-gray-700 md:text-lg">
          Book a Pediatric consultation only in case of emergencies. The doctor
          will call within 15 minutes after booking.
        </p>

        <div className="flex flex-col gap-4 pt-7 md:gap-6">
          <div className="flex flex-col gap-2">
            <label
              htmlFor="consultationType"
              className="mb-1 text-base font-normal text-gray-700 "
            >
              Select Consultation Type:
            </label>
            <select
              id="consultationType"
              value={selectedConsultation}
              onChange={(e) => setSelectedConsultation(e.target.value)}
              className="text-black w-full rounded-md border-2 border-solid border-[#BFDBFE] bg-white py-2 px-4 focus:outline-none focus:ring-blue-500 dark:focus:ring-blue-600"
            >
              {consultationTypeOption.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>

          {(selectedConsultation === "growth" ||
            selectedConsultation === "lactation") && (
            <div className="flex flex-col gap-2">
              <label
                htmlFor="doctorSelect"
                className="mb-1 text-base font-normal text-gray-700"
              >
                Select A Doctor
              </label>
              <select
                id="doctorSelect"
                value={doctorId}
                onChange={(e) => {
                  setDoctorId(e.target.value);
                  fetchDoctorSlots(e.target.value);
                }}
                className="px-4 py-2 bg-white border border-gray-400 rounded-md"
              >
                <option value={0} disabled>
                  Select a Doctor
                </option>
                {doctors.map((doctor) => (
                  <option key={doctor.value} value={doctor.value}>
                    {doctor.label}
                  </option>
                ))}
              </select>

              <label
                htmlFor="slotSelect"
                className="mb-1 text-base font-normal text-gray-700"
              >
                Select Appointment Slot
              </label>
              <select
                id="slotSelect"
                value={selectedSlot}
                onChange={(e) => setSelectedSlot(Number(e.target.value))}
                className="px-4 py-2 bg-white border border-gray-400 rounded-md"
              >
                {slots.length > 0 ? (
                  slots.map((slot) => (
                    <option key={slot.value} value={slot.value}>
                      {slot.label}
                    </option>
                  ))
                ) : (
                  <option value={0} disabled>
                    No slots available
                  </option>
                )}
              </select>
            </div>
          )}

          {selectedConsultation !== "growth" &&
            selectedConsultation !== "lactation" && (
              <div className="flex flex-col gap-2">
                <label
                  htmlFor="appointmentDatetime"
                  className="mb-1 text-base font-normal text-gray-700"
                >
                  Select Appointment Date and Time
                </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    value={selectedDatetime}
                    onChange={(newValue) => setSelectedDatetime(newValue)}
                    renderInput={(params) => (
                      <input
                        {...params}
                        className="w-full px-4 py-2 bg-white border border-gray-400 rounded-md"
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
            )}

          <div className="flex flex-col gap-2">
            <label
              htmlFor="mobileNumber"
              className="mb-1 text-base font-normal text-gray-700"
            >
              Enter Mobile Number:
            </label>
            <div className="flex gap-2">
              <PhoneInput
                isValid={(value) => {
                  if (value.match(/12345/)) {
                    return "Invalid value: " + value;
                  } else if (value.match(/1234/)) {
                    return false;
                  } else {
                    return true;
                  }
                }}
                enableSearch={true}
                dropdownStyle={{
                  top: 30,
                  border: "2px solid #3b82f6",
                  borderRadius: "10px",
                }}
                searchStyle={{
                  padding: "10px",
                  width: "90%",
                  borderRadius: "8px",
                }}
                inputStyle={{
                  width: "100%",
                  paddingTop: "20px",
                  paddingBottom: "20px",
                  border: "2px solid #BFDBFE",
                }}
                country={"in"}
                value={mobile}
                onChange={(phone) => setMobile(phone)}
                countryCodeEditable={true}
              />

              <button
                onClick={getPatients}
                className={`w-full rounded bg-blue-500 px-4 py-2 text-center text-lg text-white hover:bg-blue-700 md:col-span-2 ${
                  loading
                    ? "cursor-not-allowed opacity-70 hover:bg-blue-500"
                    : ""
                } ${
                  isDisabled
                    ? "cursor-not-allowed bg-gray-300 hover:bg-gray-500"
                    : "bg-blue-500 "
                }`}
                disabled={isDisabled}
              >
                Fetch details
              </button>
            </div>
            {fetchingPatients && (
              <div className="p-3">
                <SyncLoader size="5px" color="#525CEB" />
              </div>
            )}
          </div>

          {isDataFetched && (
            <div className="w-full">
              <div className="flex justify-center">
                <select
                  value={selectedPatientId}
                  onChange={handlePatientChange}
                  className="px-4 py-2 bg-white border border-gray-400 rounded-md"
                >
                  {patients.length > 0 ? (
                    <>
                      <option value={""}>Select Patient</option>
                      <option value={"addPatient"}>Add Patient</option>
                      {patients.map((patient, index) => (
                        <option key={index} value={patient.id}>
                          {patient.name || (
                            <span className="text-red-500">NO NAME</span>
                          )}
                        </option>
                      ))}
                    </>
                  ) : (
                    <>
                      <option value={""}>Select Patient</option>
                      <option value={"addPatient"}>Add Patient</option>
                    </>
                  )}
                </select>
              </div>

              <div className="flex justify-center gap-3 mt-2 text-white">
                {patients.length > 0 && selectedPatientId ? (
                  <button
                    className="rounded-md bg-blue-400 p-1.5 px-3"
                    onClick={() => {
                      if (selectedPatientId && mobile) {
                        setOpenAddUpdatePatientModal({
                          open: true,
                          type: "update",
                          patientDetails: patients.find(
                            (patient) =>
                              patient.id === parseInt(selectedPatientId)
                          ),
                          userMobile: `+${mobile}`,
                          appointmentIndex: null,
                        });
                      } else {
                        toast.error("Please select a patient!");
                      }
                    }}
                  >
                    Edit Details
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setOpenAddUpdatePatientModal({
                        userMobile: `+${mobile}`,
                        open: true,
                        type: "add",
                        appointmentIndex: null,
                      });
                    }}
                    className="rounded-md bg-red-400 p-1.5 px-3 text-sm text-white"
                  >
                    Add Patient
                  </button>
                )}
              </div>
            </div>
          )}

          <button
            onClick={handleConsultationButton}
            className={`w-full rounded bg-blue-500 px-4 py-2 text-center text-lg text-white hover:bg-blue-700 md:col-span-2 ${
              loading ? "cursor-not-allowed opacity-70 hover:bg-blue-500" : ""
            }`}
          >
            Submit
          </button>
          {consultationBooked && (
            <div className="text-green-500">
              Consultation booked successfully
            </div>
          )}
        </div>
      </div>
      {isPDataModalOpen && (
          <UserDataPopup
            noSearch={true}
            handlePDataModalClose={handlePDataModalClose}
          />
        )}
      <PatientModal />
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default BookConsultation;

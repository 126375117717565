import React, { useEffect, useState } from "react";
import axios from "axios";
import { useRecoilState } from "recoil";
import {
  openAddUpdatePatientModalState,
  addUpdateAppointmentState,
  isAppointmentDetailsUpdatedState,
} from "store/appointment/atoms/appointmentAtomState";
import SyncLoader from "components/Loaders/SyncLoader";
import { toast } from "react-toastify";
import { getToken } from "utils/getToken";

const AppointmentPatientDetail = ({
  userMobile,
  patientId,
  appointmentIndex,
  appointmentId,
}) => {
  const [patients, setPatients] = useState(null);
  const [selectedPatientId, setSelectedPatientId] = useState("");
  const [openAddUpdatePatientModal, setOpenAddUpdatePatientModal] =
    useRecoilState(openAddUpdatePatientModalState);
  const [fetchingPatients, setFetchingPatients] = useState(false);
  const [addUpdateAppointment, setAddUpdateAppointmentState] = useRecoilState(
    addUpdateAppointmentState
  );
  const [isAppointmentDetailsUpdated, setIsAppointmentDetailsUpdated] =
    useRecoilState(isAppointmentDetailsUpdatedState);
  const [savingAppointment, SetSavingAppointment] = useState(false);

  useEffect(() => {
    if (userMobile) {
      const getPatients = async () => {
        try {
          setFetchingPatients(true);
          const response = await axios.post(
            process.env.REACT_APP_API_URL + "patients/get-patients/",
            {
              mobile: userMobile,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${getToken()}`,
              },
            }
          );

          if (response?.data?.length > 0) {
            setPatients(response.data);
            console.log("Patients:", patients);
          } else {
            setPatients([]);
          }
          // const data = response.data;
          // return data;
        } catch (error) {
          console.error("No patient found");
        } finally {
          setFetchingPatients(false);
        }
      };

      getPatients();
    }
  }, [userMobile]);

  useEffect(() => {
    if (
      addUpdateAppointment?.appointmentIndex === appointmentIndex &&
      addUpdateAppointment?.needUpdate &&
      userMobile
    ) {
      const getPatients = async () => {
        try {
          setFetchingPatients(true);
          const response = await axios.post(
            process.env.REACT_APP_API_URL + "patients/get-patients/",
            {
              mobile: userMobile,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Token ${getToken()}`,
              },
            }
          );

          if (response?.data?.length > 0) {
            setPatients(response.data);
          } else {
            setPatients([]);
          }
          // const data = response.data;
          // return data;
        } catch (error) {
          console.error("No patient found");
        } finally {
          setFetchingPatients(false);
        }
      };

      getPatients();
      setAddUpdateAppointmentState({
        appointmentIndex: null,
        needUpdate: false,
      });
    }
  }, [addUpdateAppointment?.appointmentIndex]);

  // useEffect(() => {
  //   if (patientId) {
  //     setSelectedPatientId(patientId);
  //   }
  // }, [patientId]);

  useEffect(() => {
    if (patientId) {
      setSelectedPatientId(patientId);
    } else {
      setSelectedPatientId("");
    }
  }, [appointmentId]);

  const handlePatientChange = (e) => {
    const patientId = e.target.value;
    // Immediately return if no selection or 'Add Patient' is selected.
    if (!patientId || patientId === "addPatient") {
      console.log("called");
      setSelectedPatientId("");
      if (patientId === "addPatient") {
        setOpenAddUpdatePatientModal({
          userMobile: userMobile,
          open: true,
          type: "add",
          appointmentIndex: appointmentIndex,
        });
      }
      return;
    }
    // Find the selected patient
    const selectedPatient = patients.find((p) => p.id.toString() === patientId);

    // Check if the patient has a name.
    if (!selectedPatient?.name) {
      setOpenAddUpdatePatientModal({
        userMobile: userMobile,
        open: true,
        type: "update",
        patientDetails: selectedPatient,
        appointmentIndex: appointmentIndex,
      });
      toast.warn("Please provide a name for the patient.");
      return;
    }
    setSelectedPatientId(patientId);
    handleSavePatientInAppointment(selectedPatient);
  };

  const handleSavePatientInAppointment = async (selectedPatient) => {
    if (!selectedPatient.id || !appointmentId || !selectedPatient?.name) {
      // console.log("selectedPatientId", selectedPatient.id);
      // console.log("appointmentId", appointmentId);
      // console.log("selectedPatient", selectedPatient);
      toast.error("Please select a patient with a name to save!");
      return;
    }

    try {
      SetSavingAppointment(true);

      const updatedAppointmentData = {
        appointment_id: appointmentId,
        patient_id: Number(selectedPatient.id),
      };

      const config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}consultation/appointment/update/`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${getToken()}`,
        },
        data: updatedAppointmentData,
      };

      // Update the appointment with the new patient details
      const response = await axios(config);

      if (response.status === 200) {
        toast.success("Successfully saved patient in the appointment!");
        setIsAppointmentDetailsUpdated(true);
      }
    } catch (error) {
      console.error("UPDATE_APPOINTMENT_ERROR", error);
      toast.error("Something went wrong while saving the appointment!");
    } finally {
      SetSavingAppointment(false);
    }
  };

  if (!patients) {
    return null;
  }

  if (patients && patients.length === 0) {
    return (
      <div>
        <div className="flex justify-center">
          <button
            onClick={() => {
              setOpenAddUpdatePatientModal({
                userMobile: userMobile,
                open: true,
                type: "add",
                appointmentIndex: appointmentIndex,
              });
            }}
            className="rounded-md bg-red-400 p-1.5 px-3 text-sm text-white"
          >
            Add Patient
          </button>
        </div>
      </div>
    );
  }

  if (fetchingPatients || savingAppointment) {
    return (
      <div className="p-3">
        <SyncLoader size="5px" color="#525CEB" />
      </div>
    );
  }

  const handleNoNameClick = () => {
    if (!patients || patients.length === 0 || !selectedPatientId) {
      toast.warn("Please provide a name for the patient.");
      setOpenAddUpdatePatientModal({
        open: true,
        type: "update",
        patientDetails: patients.find(
          (patient) => patient.id === parseInt(selectedPatientId)
        ),
        userMobile: userMobile,
        appointmentIndex: appointmentIndex,
      });
    }
  };

  return (
    <>
      <div className="flex justify-center">
        <select
          value={selectedPatientId}
          onChange={handlePatientChange}
          className="w-full p-2 bg-gray-100 rounded-md min-w-max"
        >
          <option value={""}>Select Patient</option>
          <option value={"addPatient"}>Add Patient</option>
          {patients.map((patient, index) => (
            <option key={index} value={patient.id}>
              {patient.name || (
                <span className="text-red-500" onClick={handleNoNameClick}>
                  NO-NAME
                </span>
              )}
            </option>
          ))}
        </select>
      </div>

      <div className="flex justify-center gap-3 mt-2 text-white">
        {selectedPatientId ? (
          <button
            className="w-full rounded-md bg-blue-400 p-1.5 px-3"
            onClick={() => {
              if (selectedPatientId && userMobile) {
                setOpenAddUpdatePatientModal({
                  open: true,
                  type: "update",
                  patientDetails: patients.find(
                    (patient) => patient.id === parseInt(selectedPatientId)
                  ),
                  userMobile: userMobile,
                  appointmentIndex: appointmentIndex,
                });
                // setCurrentAppointmentId(appointmentId);
              } else {
                toast.error("Please select a patient!");
              }
            }}
          >
            Edit
          </button>
        ) : (
          <button
            onClick={() => {
              setOpenAddUpdatePatientModal({
                userMobile: userMobile,
                open: true,
                type: "add",
                appointmentIndex: appointmentIndex,
              });
              // setCurrentAppointmentId(appointmentId);
            }}
            className="w-full rounded-md bg-red-400 p-1.5 px-3 text-sm text-white"
          >
            Add
          </button>
        )}
      </div>
    </>
  );
};

export default AppointmentPatientDetail;

import { atom } from "recoil";

export const currentPageState = atom({
  key: "currentPage",
  default: 1,
});

export const selectedUserState = atom({
  key: "selectedUser",
  default: null,
});

export const showModalState = atom({
  key: "showModal",
  default: false,
});

export const doctorsDataState = atom({
  key: "doctorsData",
  default: [],
});

export const dataState = atom({
  key: "data",
  default: [],
});

export const highlightedRowState = atom({
  key: "highlightedRow",
  default: null,
});

export const filterPageState = atom({
  key: "filterPage",
  default: "",
});

export const mobileNumberState = atom({
  key: "mobileNumber",
  default: "",
});

export const totalPagesState = atom({
  key: "totalPages",
  default: 0,
});

export const doctorIdState = atom({
  key: "doctorId",
  default: 0,
});

export const appointmentDateState = atom({
  key: "appointmentDate",
  default: null,
});

export const appointmentTypeState = atom({
  key: "appointmentType",
  default: "",
});

export const appointmentStatusState = atom({
  key: "appointmentStatus",
  default: "null",
});

export const appointmentIdState = atom({
  key: "appointmentId",
  default: 0,
});

export const userMobileNumberState = atom({
  key: "userMobileNumberState",
  default: 0,
});

export const isAppointmentHistoryModalOpenState = atom({
  key: "isAppointmentHistoryModalOpenState",
  default: false,
});

export const remarkUpdateState = atom({
  key: "remarkUpdate",
  default: "",
});

export const feedbackState = atom({
  key: "feedback",
  default: "",
});

export const ratingState = atom({
  key: "rating",
  default: "null",
});

//For Filters

export const filterSelectedForUserState = atom({
  key: "filterSelectedForUser",
  default: true,
});
export const filterSelectedForStatusState = atom({
  key: "filterSelectedForStatus",
  default: true,
});

export const filterSelectedForConsultationState = atom({
  key: "filterSelectedForConsultation",
  default: false,
});

export const filterChangeForUserState = atom({
  key: "filterChangeForUser",
  default: "All",
});
export const statusFilterState = atom({
  key: "statusFilter",
  default: "All",
});

export const consultationTypeFilterState = atom({
  key: "consulationTypeFilter",
  default: "",
});

export const mainLoaderState = atom({
  key: "mainLoaderState",
  default: false,
});
export const statusModalOpenState = atom({
  key: "statusModalOpenState",
  default: false,
});
export const isSaveStatusButtonDisabledState = atom({
  key: "isSaveStatusButtonDisabledState",
  default: false,
});

// State for Appointment ADD/UPDATE Patient History

export const openAddUpdatePatientModalState = atom({
  key: "openAddUpdatePatientModal",
  default: {
    open: false,
    userMobile: "",
    type: "",
    patientDetails: null,
    appointmentIndex: null,
  },
});

export const addUpdateAppointmentState = atom({
  key: "addUpdateAppointment",
  default: {
    appointmentIndex: null,
    needUpdate: false,
  },
});

// export const selectedPatientIdState = atom({
//   key: 'selectedPatientId',
//   default: null,
// });

// export const currentAppointmentIdState = atom({
//   key: 'currentAppointmentId',
//   default: null,
// });

// export const patientsState = atom({
//   key: 'patientsState',
//   default: [],
// });
// Later - To fetch new appointments data after saving

export const isAppointmentSaved = atom({
  key: "isAppointmentSaved",
  default: false,
});

// State for Appointment ADD/UPDATE History

export const openAddUpdateAppointmentDetailsModalState = atom({
  key: "openAddUpdateAppointmentDetailsModal",
  default: {
    open: false,
    type: "",
    appointmentDetails: null,
    appointmentId: null,
  },
});

export const isAppointmentDetailsUpdatedState = atom({
  key: "isAppointmentDetailsUpdated",
  default: false,
});

//State for Appointment Video Call Feature

export const isVideoConsultationAllowedState = atom({
  key: "isVideoConsultationAllowed",
  default: false,
});

export const openVideoCallModalState = atom({
  key: "openVideoCallModalState",
  default: {
    open: false,
    type: "",
    appointmentDetails: null,
    appointmentId: null,
  },
});

import React, { useState, useEffect } from "react";
import axios from "axios";
import { getToken } from "utils/getToken";
import { ToastContainer } from "react-toastify";
import EditSlotModal from "./EditSlotModal";
import SyncLoader from "components/Loaders/SyncLoader";
import {
  convertTimeTo12HourReadbleFormat,
  formatToDate,
} from "utils/formatTime";

const headers = {
  "Content-Type": "application/json",
  Authorization: `Token ${getToken()}`,
};

const DoctorsAppointments = () => {
  const [doctors, setDoctors] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const [availableSlots, setAvailableSlots] = useState([]);
  const [inactiveSlots, setInactiveSlots] = useState([]);
  const [scheduleSlots, setScheduleSlots] = useState([]);
  const [openEditSlotModal, setOpenEditSlotModal] = useState({
    open: false,
  });
  const [activeTab, setActiveTab] = useState("weeklySlots");
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const consultationTypeOption = [
    "Choose type of Consultation",
    "lactation",
    "growth",
  ];
  const [selectedConsultation, setSelectedConsultation] = useState(
    consultationTypeOption[0]
  );
  const [showErrorMessage, setShowErrorMessage] = useState(true);

  useEffect(() => {
    if (selectedConsultation === consultationTypeOption[0]) {
      setShowErrorMessage(true);
    } else {
      setShowErrorMessage(false);
      getAvailableDoctors();
      setSelectedDoctor(null); // Reset doctor selection when consultation type changes
      setAvailableSlots([]);
      setInactiveSlots([]);
      setScheduleSlots([]);
    }
  }, [selectedConsultation]);

  useEffect(() => {
    if (selectedDoctor && selectedDoctor.id) {
      getSlotsByDocId(selectedDoctor.id, "active");
      getSlotsByDocId(selectedDoctor.id, "inactive");
      getScheduleSlotsByDocId(selectedDoctor.id);
    } else {
      setAvailableSlots([]);
      setInactiveSlots([]);
      setScheduleSlots([]);
    }
  }, [selectedDoctor]);

  useEffect(() => {
    if (isDataUpdated) {
      if (selectedDoctor && selectedDoctor.id) {
        getSlotsByDocId(selectedDoctor.id, "active");
        getSlotsByDocId(selectedDoctor.id, "inactive");
        getScheduleSlotsByDocId(selectedDoctor.id);
      }
      setIsDataUpdated(false);
    }
  }, [isDataUpdated]);

  const handleDoctorSelect = (doctor) => {
    setSelectedDoctor(doctor);
  };

  const handleRefresh = () => {
    if (selectedDoctor && selectedDoctor.id) {
      getSlotsByDocId(selectedDoctor.id);
      getScheduleSlotsByDocId(selectedDoctor.id);
    }
  };

  const getAvailableDoctors = async () => {
    try {
      setIsLoading(true);
      const doctorsResponse = await axios.get(
        process.env.REACT_APP_API_URL +
          `doctors/doctors/all/?category=${selectedConsultation}&list_type=all`,
        {
          headers,
        }
      );

      if (doctorsResponse.status === 200) {
        if (doctorsResponse.data?.length > 0) {
          setDoctors(doctorsResponse.data);
        } else {
          setDoctors([]);
        }
      }
    } catch (err) {
      console.log("GET_DOCTORS_ERROR", err);
      setDoctors([]);
    } finally {
      setIsLoading(false);
    }
  };

  const getSlotsByDocId = async (doctorId, slotType) => {
    try {
      setIsLoading(true);
      const doctorsResponse = await axios.post(
        process.env.REACT_APP_API_URL + "doctors/weekly-availability/",
        {
          doctor_ids: [doctorId],
        },
        {
          headers,
        }
      );

      if (doctorsResponse.status === 200) {
        const slots = doctorsResponse.data;
        if (slotType === "active") {
          setAvailableSlots(slots.filter((slot) => slot.status === "active"));
        } else if (slotType === "inactive") {
          setInactiveSlots(slots.filter((slot) => slot.status === "inactive"));
        }
      }
    } catch (err) {
      console.log("GET_DOCTORS_Available_Slot_ERROR", err);
      if (slotType === "active") {
        setAvailableSlots([]);
      } else if (slotType === "inactive") {
        setInactiveSlots([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getScheduleSlotsByDocId = async (doctorId) => {
    try {
      setIsLoading(true);
      const doctorsResponse = await axios.post(
        process.env.REACT_APP_API_URL + "doctors/doctor-appointment-slots/",
        {
          doctor_ids: [doctorId],
          status: ["free", "booked"],
          slot_type: selectedConsultation
        },
        {
          headers,
        }
      );

      if (doctorsResponse.status === 200) {
        setScheduleSlots(doctorsResponse.data);
      }
    } catch (err) {
      console.log("GET_DOCTORS_Schedule_Slot_ERROR", err);
      setScheduleSlots([]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="mx-auto sm:w-full">
      <EditSlotModal
        openEditSlotModal={openEditSlotModal}
        setOpenEditSlotModal={setOpenEditSlotModal}
        setIsDataUpdated={setIsDataUpdated}
      />

      <div className="py-6 mb-5">
        {/* Header */}
        <div className="flex items-center justify-between px-2 mr-1 md:px-0">
          <div className="flex gap-2">
            <select
              id="consultationType"
              value={selectedConsultation}
              onChange={(e) => setSelectedConsultation(e.target.value)}
              className="text-black w-full rounded-md border-2 border-solid border-[#BFDBFE] bg-white py-2 px-4 focus:outline-none focus:ring-blue-500 dark:focus:ring-blue-600"
            >
              {consultationTypeOption.map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </select>

            {selectedConsultation !== consultationTypeOption[0] && (
              <select
                className="p-2 border border-gray-300 rounded-lg"
                onChange={(e) =>
                  handleDoctorSelect(
                    doctors.find(
                      (doctor) => doctor.id === parseInt(e.target.value)
                    )
                  )
                }
              >
                <option value="">Select a doctor</option>
                {doctors.map((doctor) => (
                  <option key={doctor.id} value={doctor.id}>
                    {doctor.name}
                  </option>
                ))}
              </select>
            )}
          </div>

          <div className="">
            <h1 className="text-lg font-bold text-center text-blue-500 capitalize dark:text-white md:text-2xl">
              Manage Doctor's Schedule
            </h1>
          </div>

          <div>
            <button
              disabled={isLoading && !selectedDoctor}
              className="px-3 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600 md:px-6"
              onClick={handleRefresh}
            >
              Refresh
            </button>
          </div>
        </div>
      </div>

      <div className="bg-gray-100 rounded-md">
        <ul className="flex flex-wrap justify-center gap-4 -mb-px text-sm font-medium text-center text-gray-500">
          <li className="me-2">
            <button
              onClick={() => setActiveTab("weeklySlots")}
              className={`inline-flex ${
                activeTab === "weeklySlots"
                  ? "border-b-2 border-blue-600 text-blue-500"
                  : "text-gray-900"
              } group items-center justify-center  rounded-t-lg p-4 hover:border-gray-300`}
            >
              Available Weekly Slots
            </button>
          </li>

          <li className="me-2">
            <button
              onClick={() => setActiveTab("scheduleSlots")}
              className={`inline-flex ${
                activeTab === "scheduleSlots"
                  ? "border-b-2 border-blue-600 text-blue-500"
                  : "text-black"
              } group items-center justify-center rounded-t-lg p-4 hover:border-gray-300`}
            >
              Scheduled Slots
            </button>
          </li>
        </ul>
      </div>

      {showErrorMessage ? (
        <div className="flex h-[70vh] items-center justify-center text-red-500">
          Please choose a type of consultation.
        </div>
      ) : !selectedDoctor ? (
        <div className="flex h-[70vh] items-center justify-center text-red-500">
          Please select a doctor.
        </div>
      ) : isLoading ? (
        <div className="flex h-[70vh] items-center justify-center">
          <SyncLoader color={"#525CEB"} size={10} />
        </div>
      ) : activeTab === "weeklySlots" ? (
        <>
          <div className="mt-5">
            <div className="flex items-center justify-between px-4">
              <h2 className="text-lg font-bold text-center text-blue-400 md:text-2xl">
                {selectedDoctor?.name} Active Slots
              </h2>

              {selectedDoctor && (
                <button
                  onClick={() => {
                    setOpenEditSlotModal({
                      open: true,
                      slotId: null,
                      type: "weeklySlot",
                      feature: "add",
                      slotDetails: null,
                      doctorId: selectedDoctor.id,
                      slotType: selectedConsultation,
                    });
                  }}
                  className="px-3 py-2 text-white bg-red-500 rounded-md"
                >
                  Add Weekly Slot
                </button>
              )}
            </div>

            {availableSlots.length > 0 ? (
              <div className="flow-root px-4 mt-5">
                <ul className="divide-y divide-gray-200">
                  {availableSlots.map((slot) => (
                    <li className="py-3 sm:py-4" key={slot.id}>
                      <div className="flex items-center">
                        <div className="w-1 h-16 bg-blue-400 rounded-md"></div>
                        <div className="flex-1 min-w-0 ms-4">
                          <p className="text-base font-medium text-gray-900 truncate dark:text-white">
                            Day - {slot.day}
                          </p>
                          <p className="text-sm text-gray-800 truncate dark:text-gray-400">
                            Available -{" "}
                            {convertTimeTo12HourReadbleFormat(slot?.time_from)}{" "}
                            - {convertTimeTo12HourReadbleFormat(slot?.time_to)}
                          </p>
                          <p className="text-sm text-gray-800 truncate dark:text-gray-400">
                            Status -{" "}
                            <span
                              className={`${
                                slot?.status === "active"
                                  ? `text-green-500`
                                  : `text-red-500`
                              }`}
                            >
                              {slot.status}
                            </span>
                          </p>
                        </div>

                        <div className="">
                          <button
                            onClick={() => {
                              setOpenEditSlotModal({
                                open: true,
                                slotId: slot.id,
                                type: "weeklySlot",
                                feature: "edit",
                                slotDetails: slot,
                                doctorId: null,
                                slotType: selectedConsultation,
                              });
                            }}
                            className="px-3 py-2 text-white bg-blue-400 rounded-md"
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div className="flex items-center justify-center h-20 my-4">
                No available slots found!
              </div>
            )}
          </div>

          {inactiveSlots.length > 0 && (
            <div className="mt-5">
              <div className="flex items-center justify-between px-4">
                <h2 className="text-lg font-bold text-center text-red-500 md:text-2xl">
                  {selectedDoctor?.name} Inactive Slots
                </h2>
              </div>

              <div className="flow-root px-4 mt-5">
                <ul className="divide-y divide-gray-200">
                  {inactiveSlots.map((slot) => (
                    <li className="py-3 sm:py-4" key={slot.id}>
                      <div className="flex items-center">
                        <div className="w-1 h-16 bg-blue-400 rounded-md"></div>
                        <div className="flex-1 min-w-0 ms-4">
                          <p className="text-base font-medium text-gray-900 truncate dark:text-white">
                            Day - {slot.day}
                          </p>
                          <p className="text-sm text-gray-800 truncate dark:text-gray-400">
                            Available -{" "}
                            {convertTimeTo12HourReadbleFormat(slot?.time_from)}{" "}
                            - {convertTimeTo12HourReadbleFormat(slot?.time_to)}
                          </p>
                          <p className="text-sm text-gray-800 truncate dark:text-gray-400">
                            Status -{" "}
                            <span
                              className={`${
                                slot?.status === "active"
                                  ? `text-green-500`
                                  : `text-red-500`
                              }`}
                            >
                              {slot.status}
                            </span>
                          </p>
                        </div>

                        <div className="">
                          <button
                            onClick={() => {
                              setOpenEditSlotModal({
                                open: true,
                                slotId: slot.id,
                                type: "weeklySlot",
                                feature: "edit",
                                slotDetails: slot,
                                doctorId: null,
                                slotType: selectedConsultation,
                              });
                            }}
                            className="px-3 py-2 text-white bg-blue-400 rounded-md"
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="px-4 mt-5">
          <div className="flex items-center justify-between px-4">
            <h2 className="text-lg font-bold text-center text-green-400 md:text-2xl">
              {selectedDoctor?.name} Schedule Slots
            </h2>

            {selectedDoctor && (
              <button
                onClick={() => {
                  setOpenEditSlotModal({
                    open: true,
                    slotId: null,
                    type: "scheduleSlot",
                    feature: "add",
                    slotDetails: null,
                    doctorId: selectedDoctor.id,
                    slotType: selectedConsultation,
                  });
                }}
                className="px-3 py-2 text-white bg-red-500 rounded-md"
              >
                Add Slot
              </button>
            )}
          </div>

          <div className="mt-5 min-h-[60vh] max-w-full overflow-y-auto">
            <table className="w-full bg-white shadow-md">
              <thead>
                <tr className="bg-blue-200">
                  <th className="px-4 py-2 border">Date</th>
                  <th className="px-4 py-2 border">User</th>
                  <th className="px-4 py-2 border">Timing</th>
                  <th className="px-4 py-2 border">Status</th>
                  <th className="px-4 py-2 border">Edit</th>
                </tr>
              </thead>
              <tbody>
                {scheduleSlots.map((slot, rowIndex) => (
                  <tr key={rowIndex}>
                    <td className="min-w-[150px] border px-2 py-2">
                      {formatToDate(slot.date)}
                    </td>
                    <td className="min-w-[150px] border px-2 py-2">
                      <div className="flex flex-col items-center gap-2">
                        {slot?.user_name && (
                          <p>User Name - {slot?.user_name}</p>
                        )}
                        {slot?.phone_number && (
                          <p>Phone Number - {slot?.phone_number}</p>
                        )}
                      </div>
                    </td>
                    <td className="min-w-[200px] border px-4 py-2">
                      {convertTimeTo12HourReadbleFormat(slot?.time_from)} -{" "}
                      {convertTimeTo12HourReadbleFormat(slot?.time_to)}
                    </td>
                    <td className="min-w-[200px] border px-4 py-2">
                      <span
                        className={`${
                          slot?.status === "free"
                            ? `text-green-500`
                            : `text-red-500`
                        }`}
                      >
                        {slot.status}
                      </span>
                    </td>
                    <td className="flex min-w-[150px] items-center justify-center border px-4 py-2">
                      <button
                        disabled={slot.status === "booked"}
                        onClick={() => {
                          setOpenEditSlotModal({
                            open: true,
                            slotId: slot.id,
                            type: "scheduleSlot",
                            feature: "edit",
                            slotDetails: {
                              ...slot,
                              doctorName: selectedDoctor?.name,
                            },
                            doctorId: null,
                            slotType: selectedConsultation,
                          });
                        }}
                        className="rounded-md bg-red-500 py-1.5 px-3 font-light text-white disabled:cursor-not-allowed"
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default DoctorsAppointments;

import { useState, useEffect } from "react";
import { FaPencilAlt } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { parseISO, format } from "date-fns";
import { ToastContainer } from "react-toastify";
import axios from "axios";
import { useMediaQuery } from "@mui/material";
import { MdOutlineClose } from "react-icons/md";
import { getToken } from "utils/getToken";
import { Listbox } from "@headlessui/react";
import { HiOutlineSelector } from "react-icons/hi";
import SyncLoader from "components/Loaders/SyncLoader";
import CreateSubscriptionModal from "components/CreateSubscription/CreateSubscriptionForm";
import { HiExternalLink } from "react-icons/hi";
import UserDataPopup from "components/UserData/UserDataPopup";
import IframeModal from "../ChatStatus/IframeModal";
import { MdCancel } from "react-icons/md";

const startsortingOptions = [
  { value: "s_start_time", name: "Start Date Ascending" },
  { value: "s_start_time desc", name: "Start Date Descending" },
];

const endsortingOptions = [
  { value: "s_end_time", name: "End Date Ascending" },
  { value: "s_end_time desc", name: "End Date Descending" },
];

const Growth = () => {
  const [token, setToken] = useState(getToken());
  const [data, setData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [filterPage, setFilterPage] = useState("");
  const [comment, setComment] = useState("");
  const [editableMaxCalls, setEditableMaxCalls] = useState("");
  const [isCreateSubscriptionModalOpen, setIsCreateSubscriptionModalOpen] =
    useState(false);
  const [isSearchButtonClicked, setIsSearchButtonClicked] = useState(false);
  const [reasonData, setReasonData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [commentError, setCommentError] = useState(false); // New state variable for comment error message

  const [startSortingMethod, setstartSortingMethod] = useState();
  const [endSortingMethod, setendSortingMethod] = useState();

  const [fetchingSubscriptionData, setFetchingSubscriptionData] =
    useState(true);
  const [isPDataModalOpen, setIsPDataModalOpen] = useState(false);
  const [isFilterSelectedForConsultation, setIsFilterSelectedForConsultation] =
    useState(false);
  const [consultationTypeFilter, setConsultationTypeFilter] = useState("");
  const [selectedFilterDate, setSelectedFilterDate] = useState(null);
  const [selectedStausFilters, setSelectedStatusFilters] = useState("all");

  const handlePDataModalClose = () => {
    setIsPDataModalOpen(false);
    setSelectedUser(null);
  };

  const handlePDataModal = (row) => {
    setSelectedUser(row.phone_number);
    // console.log(row.Chat_started_by);
    setIsPDataModalOpen(true);
  };
  const handleFilterChangeForStatus = (value) => {
    setSelectedStatusFilters(value);
    setCurrentPage(1);
  };

  const fetchData = async (sortby) => {
    setFetchingSubscriptionData(true);
    try {
      let response;
      // Prepare the request data
      const requestData = {};
      if (filterText !== "") {
        requestData.number = filterText;
      }
      if (sortby !== "") {
        requestData.sort_by = sortby;
      }
      if (selectedFilterDate !== null) {
        requestData.date = selectedFilterDate;
      }
      if (selectedStausFilters !== "all") {
        requestData.status = selectedStausFilters;
      }

      // Make the API request
      response = await axios({
        method: "POST",
        url:
          process.env.REACT_APP_API_URL +
          "subscription/growth-consultation-dashboard/",
        headers: {
          Authorization: `Token ${token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      });

      console.log(response.data);
      setData(response.data);
      setFetchingSubscriptionData(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setFetchingSubscriptionData(false);
    }
  };

  useEffect(() => {
    if (!isSearchButtonClicked) {
      fetchData();
      console.log("data:", data);
    }
  }, [
    currentPage,
    isSearchButtonClicked,
    consultationTypeFilter,
    selectedFilterDate,
    selectedStausFilters,
  ]);

  const handleSearchButtonClick = async () => {
    setLoading(true); // Set loading to true before starting the search

    try {
      console.log("searching text");
      await fetchData(); // Call the API with the updated filterText
      setIsSearchButtonClicked(true); // Set the flag to true indicating search button is clicked
    } catch (error) {
      console.log("No data Available");
      // Handle the error if needed
    }

    setLoading(false); // Set loading to false after the search button click is complete
  };

  const handleDateChange = (date) => {
    setSelectedFilterDate(date);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };
  const handlePageSearch = () => {
    const pageNumber = parseInt(filterPage);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setFilterText(""); // Clear the filterText after navigation
      setIsSearchButtonClicked(false); // Reset the search button click state

      setCurrentPage(pageNumber);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleFilterChange = (e) => {
    const { value } = e.target;
    setFilterText(value);
    setIsSearchButtonClicked(false); // Reset the search button click state when filter text changes
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const handleCommentChange = (e) => {
    const { value } = e.target;
    setComment(value);
  };

  const handleMaxCallsChange = (e) => {
    const { value } = e.target;
    setEditableMaxCalls(value);
  };

  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const numberOfButtons = isSmallScreen ? 4 : 10;

  // Function to toggle the modal's visibility
  const toggleModal = () => {
    setIsCreateSubscriptionModalOpen(!isCreateSubscriptionModalOpen);
  };

  const handleRefresh = async () => {
    fetchData();
  };

  const handleFilterChangeForConsultationType = (value) => {
    setConsultationTypeFilter(value);
    setIsFilterSelectedForConsultation(true);
    setCurrentPage(1);
  };

  // For navigating to that particular appointment
  const [highlightedRow, setHighlightedRow] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  function createLinkAndOpenAppointment(row, rowIndex) {
    const url = `/admin/appointment?mobile_number=${row.phone_number.slice(
      1
    )}&status=0&type=1}`;

    setHighlightedRow(rowIndex);
    setModalContent({ url });
  }
  const closeModal = () => {
    setModalContent(null);
  };

  return (
    <div className="px-4 mx-auto md:mx-0 md:w-full md:p-4">
      <div className="flex items-center justify-between my-6">
        <h1 className="items-center justify-center text-4xl font-bold text-center capitalize text-navy-700 dark:text-white">
          Growth
        </h1>
        {modalContent && (
          <IframeModal onClose={closeModal} url={modalContent.url} />
        )}
        <div className="flex flex-col items-center justify-between md:flex-row">
          <div className="flex items-center gap-3">
            <div className="hidden md:block">
              <button
                disabled={fetchingSubscriptionData}
                className="rounded-lg bg-blue-500 px-3 py-2.5 text-white hover:bg-blue-600 md:px-6"
                onClick={() => handleRefresh()}
              >
                Refresh
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-[1fr,2fr]">
        {/* Filter input */}
        <div className="flex items-center justify-between gap-6">
          <div className="relative mt-[3px] flex h-[61px] w-[355px] flex-grow items-center justify-center gap-2 rounded-full bg-white px-2 py-2 shadow-xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none md:w-[365px] md:flex-grow-0 md:gap-1 xl:w-[365px] xl:gap-2">
            <div className="flex h-full items-center rounded-full bg-lightPrimary text-navy-700 dark:bg-navy-900 dark:text-white xl:w-[225px]">
              <input
                type="text"
                placeholder=" User Phone Number"
                value={filterText}
                onChange={handleFilterChange}
                className="block h-full w-full rounded-full bg-lightPrimary px-4 text-sm font-medium text-navy-700 outline-none placeholder:!text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:!text-white sm:w-fit"
              />
            </div>
            <button
              className="px-4 py-2 mx-2 text-white bg-blue-500 rounded-full cursor-pointer hover:bg-blue-6000 hover:bg-blue-300"
              onClick={handleSearchButtonClick}
              disabled={loading} // Disable the button while loading
            >
              {loading ? (
                "Loading..."
              ) : (
                <div className="flex">
                  {/* <FiSearch  /> */}
                  Search
                </div>
              )}
            </button>
          </div>

          <div className="md:hidden">
            <button
              disabled={fetchingSubscriptionData}
              className="rounded-lg bg-blue-500 px-3 py-2.5 text-white hover:bg-blue-600 md:px-6"
              onClick={() => handleRefresh()}
            >
              Refresh
            </button>
          </div>
        </div>

        {/* Filters */}
        <div className="flex items-center justify-end my-4">
          <div className="flex items-center justify-start gap-4">
            {/* Date filter */}
            <div className="relative inline-flex items-center justify-center gap-4 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50">
              <input
                id="dob"
                name="dob"
                type="date"
                value={selectedFilterDate}
                onChange={(e) => handleDateChange(e.target.value)}
                max={new Date().toISOString().split("T")[0]}
                placeholder="Filter By Date"
                className={`w-full rounded-md px-5 py-3`}
              />

              {selectedFilterDate && (
                <div className="ml-5">
                  <button
                    onClick={() => {
                      setSelectedFilterDate("");
                      //
                    }}
                    className="absolute top-3 right-2"
                  >
                    <MdOutlineClose size={22} />
                  </button>
                </div>
              )}
            </div>

            {/* Filter by status */}
            <div className="flex">
              <div>
                <select
                  className={`focus:ring-primary rounded-md border border-gray-300 bg-white px-3 py-2 text-base ring-opacity-5 hover:bg-gray-50 focus:outline-none focus:ring-1
             ${selectedStausFilters !== "" ? "text-green-500" : "text-black"}
            `}
                  value={selectedStausFilters}
                  onChange={(e) => {
                    handleFilterChangeForStatus(e.target.value);
                  }}
                >
                  <option value="">Filter By Call Status</option>
                  <option value="all">All</option>
                  <option value="upcoming">Upcoming</option>
                  <option value="consultation_done">Completed</option>
                </select>
              </div>
              {selectedStausFilters !== "" && (
                <span
                  className="relative text-red-500 cursor-pointer left-1 top-3"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedStatusFilters("");
                  }}
                >
                  <MdCancel />
                </span>
              )}
            </div>

            {/* Filter By Sorting Method */}
            <div className="relative">
              <Listbox
                value={startSortingMethod}
                onChange={(value) => {
                  console.log(value);
                  setstartSortingMethod(value);
                  fetchData(value.value);
                }}
              >
                {({ open }) => (
                  <>
                    <div className="relative inline-block text-left">
                      <Listbox.Button
                        as="div"
                        className="inline-flex items-center justify-center w-full gap-4 px-4 py-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                      >
                        {startSortingMethod
                          ? `${startSortingMethod.name}`
                          : "Choose Start Date Sorting"}
                        {startSortingMethod ? (
                          <button
                            onClick={() => setstartSortingMethod("")}
                            className="flex items-center ml-2"
                          >
                            <MdOutlineClose size={22} />
                          </button>
                        ) : (
                          <HiOutlineSelector size="22" />
                        )}
                      </Listbox.Button>
                    </div>
                    <Listbox.Options
                      className="absolute mt-1 max-h-60 min-w-[180px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-opacity-5 focus:outline-none sm:text-sm"
                      style={{ display: open ? "block" : "none" }}
                    >
                      {startsortingOptions.map((option, index) => (
                        <Listbox.Option
                          key={index}
                          value={option}
                          className={({ active }) =>
                            `${
                              active
                                ? "bg-blue-500 text-white"
                                : "text-gray-900"
                            }
                        relative cursor-pointer select-none py-2 pl-1 pr-4`
                          }
                        >
                          {({ selected, active }) => (
                            <>
                              <div className="flex items-center gap-3">
                                <input
                                  type="checkbox"
                                  checked={selected ? true : false}
                                  className="cursor-pointer"
                                />
                                <span
                                  className={`${
                                    selected ? "font-semibold" : "font-normal"
                                  }`}
                                >
                                  {option.name}
                                </span>
                              </div>
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </>
                )}
              </Listbox>
            </div>

            <div className="flex items-center justify-start gap-4">
              {/* Filter By Sorting Method */}
              <div className="relative ml-5">
                <Listbox
                  value={endSortingMethod}
                  onChange={(value) => {
                    console.log(value);
                    setendSortingMethod(value);
                    fetchData(value.value);
                  }}
                >
                  {({ open }) => (
                    <>
                      <div className="relative inline-block text-left">
                        <Listbox.Button
                          as="div"
                          className="inline-flex items-center justify-center w-full gap-4 px-4 py-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                        >
                          {endSortingMethod
                            ? `${endSortingMethod.name}`
                            : "Choose End Date Sorting "}
                          {endSortingMethod ? (
                            <button
                              onClick={() => setendSortingMethod("")}
                              className="flex items-center ml-2"
                            >
                              <MdOutlineClose size={22} />
                            </button>
                          ) : (
                            <HiOutlineSelector size="22" />
                          )}
                        </Listbox.Button>
                      </div>
                      <Listbox.Options
                        className="absolute mt-1 max-h-60 min-w-[180px] overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-opacity-5 focus:outline-none sm:text-sm"
                        style={{ display: open ? "block" : "none" }}
                      >
                        {endsortingOptions.map((option, index) => (
                          <Listbox.Option
                            key={index}
                            value={option}
                            className={({ active }) =>
                              `${
                                active
                                  ? "bg-blue-500 text-white"
                                  : "text-gray-900"
                              }
                        relative cursor-pointer select-none py-2 pl-1 pr-4`
                            }
                          >
                            {({ selected, active }) => (
                              <>
                                <div className="flex items-center gap-3">
                                  <input
                                    type="checkbox"
                                    checked={selected ? true : false}
                                    className="cursor-pointer"
                                  />
                                  <span
                                    className={`${
                                      selected ? "font-semibold" : "font-normal"
                                    }`}
                                  >
                                    {option.name}
                                  </span>
                                </div>
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </>
                  )}
                </Listbox>
              </div>

              {/* Date Filter */}
              {/* <div className="relative inline-flex items-center justify-center gap-4 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50">
              <input
                id="dob"
                name="dob"
                type="date"
                max={new Date().toISOString().split("T")[0]}
                placeholder="Filter By Date"
                className={`w-full rounded-md px-5 py-3`}
              />

              <div className="ml-5">
                <button className="absolute top-3 right-2">
                  <MdOutlineClose size={22} />
                </button>
              </div>
            </div> */}
            </div>
          </div>
        </div>

        {/* Filters end */}
      </div>

      {isCreateSubscriptionModalOpen && (
        <CreateSubscriptionModal onClose={toggleModal} />
      )}

      <div className="mx-auto sm:hidden">
        {/* List items */}
        {data.length > 0 ? (
          data.map((row, rowIndex) => (
            <div key={rowIndex} className="p-4 mb-4 bg-white rounded-md shadow">
              <div className="flex justify-between mb-2">
                <div>
                  <p className="text-lg font-semibold">{row.id}</p>
                  <p>{row.phone_number}</p>
                </div>
                <div>
                  <p className="text-lg font-semibold">{row.plan_name}</p>
                </div>
              </div>
              <div className="flex justify-between">
                <div>
                  <p className="text-base font-semibold">Start Date</p>
                  <p>
                    {format(parseISO(row.s_start_time), "yyyy-MM-dd HH:mm:ss")}
                  </p>
                </div>
              </div>
              <div className="flex justify-between">
                <div>
                  <p className="text-base font-semibold">End Date</p>
                  <p>
                    {format(parseISO(row.s_end_time), "yyyy-MM-dd HH:mm:ss")}
                  </p>
                </div>
              </div>

              <div className="flex justify-between">
                <div>
                  <p className="text-base font-semibold">Max Calls</p>
                  <p>{row.max_calls}</p>
                </div>
                <div>
                  <p className="text-base font-semibold">Used Calls</p>
                  <p>{row.used_calls}</p>
                </div>
              </div>
              <div className="flex justify-end mt-2">
                <div>
                  <FaPencilAlt
                    className="mr-2 text-blue-500 cursor-pointer"
                    aria-label="Edit"
                  />
                </div>
              </div>
            </div>
          ))
        ) : fetchingSubscriptionData ? (
          <div className="flex h-[70vh] items-center justify-center">
            <SyncLoader color={"#525CEB"} size={10} />
          </div>
        ) : (
          <p>No data available</p>
        )}
      </div>
      {/* new way to show user data in modal */}
      {isPDataModalOpen && (
        <UserDataPopup
          selectedUser={selectedUser}
          noSearch={true}
          handlePDataModalClose={handlePDataModalClose}
        />
      )}

      {/* Desktop View Data */}
      {!fetchingSubscriptionData && data.length > 0 ? (
        <div className="min-h-[60vh] max-w-full overflow-y-auto">
          <table className="hidden bg-white shadow table-auto sm:block">
            <thead>
              <tr className="bg-blue-200">
                <th className="px-4 py-2 border">User</th>
                <th className="px-4 py-2 border">Plan Name</th>
                <th className="px-4 py-2 border">Start Date</th>
                <th className="px-4 py-2 border">End Date</th>

                <th className="px-4 py-2 border">Last Growth Call</th>
                <th className="px-4 py-2 border">Appointment Details</th>
                {/* <th className="px-4 py-2 border">Reason</th> */}
              </tr>
            </thead>
            <tbody>
              {data.length > 0 ? (
                data.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className={`${
                      rowIndex === highlightedRow
                        ? "bg-yellow-500"
                        : "hover:bg-blue-100"
                    }`}
                  >
                    <td className="px-4 py-2 border">
                      <button
                        className="text-blue-500 cursor-pointer hover:underline"
                        onClick={() => {
                          handlePDataModal(row);
                        }}
                      >
                        <p>{row.phone_number}</p> <HiExternalLink />
                        <p className="text-blue-500">{row?.name || ""}</p>
                      </button>
                    </td>
                    <td className="px-4 py-2 border">{row.plan_name}</td>
                    <td className="px-4 py-2 border">
                      {format(
                        parseISO(row.s_start_time),
                        "yyyy-MM-dd HH:mm:ss"
                      )}
                    </td>
                    <td className="px-4 py-2 border">
                      {format(parseISO(row.s_end_time), "yyyy-MM-dd HH:mm:ss")}
                    </td>

                    <td className="px-4 py-2 border">
                      {row.last_appointment_time === null
                        ? "No Call taken"
                        : format(
                            parseISO(row.last_appointment_time),
                            "yyyy-MM-dd HH:mm:ss"
                          )}
                      <br />
                      {row.last_appointment_time === null ? (
                        ""
                      ) : (
                        <p className="text-green-600 ">by {row.doctor_name}</p>
                      )}
                    </td>
                    <td className="px-4 py-2 border">
                      <button
                        className="text-blue-500 cursor-pointer hover:underline"
                        onClick={() => {
                          createLinkAndOpenAppointment(row, rowIndex);
                        }}
                      >
                        Open Appointnment <HiExternalLink />
                      </button>
                    </td>
                    {/* <td className="px-4 py-2 border">{row.comment}</td> */}
                  </tr>
                ))
              ) : (
                <tr>
                  <td className="px-4 py-2 border" colSpan="6">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      ) : fetchingSubscriptionData ? (
        <div className="flex h-[70vh] items-center justify-center">
          <SyncLoader color={"#525CEB"} size={10} />
        </div>
      ) : (
        <div className="flex h-full min-h-[60vh] w-full items-center justify-center">
          <p className="text-lg font-semibold text-center">
            No Subscription Data available
          </p>
        </div>
      )}
      <div className="flex flex-col justify-center mt-10 md:mt-20 md:flex-row">
        <div className="flex justify-center md:ml-auto">
          <button
            onClick={handlePrevPage}
            disabled={currentPage === 1}
            className="px-4 py-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600 md:px-8"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M9.707 4.293a1 1 0 010 1.414L6.414 9H16a1 1 0 110 2H6.414l3.293 3.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {totalPages > 1 &&
            [
              ...Array(Math.min(numberOfButtons, totalPages - currentPage + 1)),
            ].map((_, index) => {
              const pageNumber = currentPage + index;
              return (
                <button
                  key={index}
                  onClick={() => setCurrentPage(pageNumber)}
                  disabled={pageNumber > totalPages}
                  className={`ml-2 rounded-lg px-4 py-2 ${
                    currentPage === pageNumber
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-800 hover:bg-gray-300"
                  }`}
                >
                  {pageNumber}
                </button>
              );
            })}

          <button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
            className="px-4 py-2 mx-2 text-white bg-blue-500 rounded-lg hover:bg-blue-600 md:px-8"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-5 h-5 transform -rotate-180"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M9.707 4.293a1 1 0 010 1.414L6.414 9H16a1 1 0 110 2H6.414l3.293 3.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <div className="relative flex items-center justify-end mt-5 md:ml-auto md:mt-0 ">
          <input
            type="text"
            placeholder={`Go to page (1 - ${totalPages})`}
            value={filterPage}
            onChange={(e) => setFilterPage(e.target.value)}
            className="w-full py-2 pl-8 pr-2 text-sm font-medium text-gray-800 bg-white border border-gray-400 rounded-md outline-none"
          />
          <button
            className="px-4 py-3 mx-2 text-white bg-blue-500 rounded-lg cursor-pointer hover:bg-blue-300"
            onClick={handlePageSearch}
          >
            <FiSearch
              className="w-4 h-4 rounded-lg cursor-pointer"
              onClick={handlePageSearch}
            />
          </button>
        </div>
      </div>

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default Growth;

import React, { useRef, useEffect } from "react";

const IframeModal = ({ onClose, url }) => {
  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [url]);

  return (
    <div className="fixed inset-0 z-[100] flex items-center justify-center overflow-y-auto ">
      <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
      <div
        className="relative left-0 z-50 h-[90vh] w-[850px] rounded bg-white md:mx-auto md:ml-4 "
        ref={ref}
      >
        <iframe
          src={url}
          className="h-full w-[850px]"
          title="Chat IframeModal"
        />

        <div
          className="text-black-800 dark:text-black-100 absolute top-1 right-[-6px] m-3 cursor-pointer transition duration-150 ease-in-out"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            aria-label="Close"
            className="icon icon-tabler icon-tabler-x"
            width={20}
            height={20}
            viewBox="0 0 24 24"
            strokeWidth="2.5"
            stroke="currentColor"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <line x1={18} y1={6} x2={6} y2={18} />
            <line x1={6} y1={6} x2={18} y2={18} />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default IframeModal;

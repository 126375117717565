// useDynamicHeight.js
import { useEffect, useState, useRef } from "react";

const useDynamicHeight = (dependencies) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const checkHeight = () => {
      if (ref.current) {
        setIsOverflowing(ref.current.scrollHeight > window.innerHeight * 0.74);
      }
    };

    checkHeight();
    window.addEventListener("resize", checkHeight);

    return () => {
      window.removeEventListener("resize", checkHeight);
    };
  }, [...dependencies]);

  return { ref, isOverflowing };
};

export default useDynamicHeight;

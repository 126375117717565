import { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import axios from "axios";
import { getToken } from "utils/getToken";
import { toast } from "react-toastify";
import SyncLoader from "components/Loaders/SyncLoader";
import { IoMdClose } from "react-icons/io";

const headers = {
  "Content-Type": "application/json",
  Authorization: `Token ${getToken()}`,
};

const DeleteUserModal = ({ openDeleteUserModal, setOpenDeleteUserModal }) => {
  const [loading, setLoading] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");

  const handleDeleteUser = async (e) => {
    e.preventDefault();

    if (openDeleteUserModal.userMobile) {
      try {
        setLoading(true);

        const response = await axios.post(
          process.env.REACT_APP_API_URL + "users/delete-user-account/",
          {
            mobile: openDeleteUserModal.userMobile,
            source: "support_portal",
            reason: deleteReason,
          },
          {
            headers,
          }
        );

        if (response.status === 200) {
          toast.success("This user is now deleted!");

          //Close Modal
          setOpenDeleteUserModal({
            open: false,
            userMobile: null,
            patientName: null,
          });

          window.location.reload();
        }
      } catch (error) {
        console.error("DELETE_USER_MODAL", error);
        toast.error("Something went wrong while deleting patient!");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog
      open={openDeleteUserModal.open}
      onClose={() => {
        setOpenDeleteUserModal({
          open: false,
          userMobile: null,
          patientName: null,
        });
      }}
      className="relative z-50"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-[#000] opacity-20" aria-hidden="true" />

      {/* Full-screen scrollable container */}
      <div className="fixed inset-0 overflow-y-auto">
        {/* Container to center the panel */}
        <div className="flex min-h-screen items-center justify-center p-4">
          {/* The actual dialog panel  */}
          <Dialog.Panel className="mx-auto w-full max-w-md rounded md:min-w-[40%]">
            <div className="my-8 inline-block w-full bg-white p-6 shadow-xl">
              <div className="flex justify-end">
                <button
                  onClick={() => {
                    setOpenDeleteUserModal({
                      open: false,
                      userMobile: null,
                      patientName: null,
                    });
                  }}
                  className="flex gap-3 text-gray-600 hover:text-gray-800"
                >
                  <IoMdClose size={25} />
                </button>
              </div>
              <Dialog.Title
                as="h3"
                className="text-center text-lg font-medium leading-6 text-gray-900"
              >
                Delete Patient
              </Dialog.Title>
              <form onSubmit={handleDeleteUser} className="space-y-4">
                <div className="w-full text-sm">
                  <p className="text-start font-semibold">User Name</p>
                  <input
                    value={openDeleteUserModal.patientName}
                    readOnly
                    className={`mt-2 w-full rounded border p-3 text-sm`}
                  />
                </div>

                <div className="w-full text-sm">
                  <p className="text-start font-semibold">Delete Reason</p>
                  <input
                    value={deleteReason}
                    onChange={(e) => setDeleteReason(e.target.value)}
                    className={`mt-2 w-full rounded border p-3 text-sm`}
                  />
                </div>

                <div className="mt-4">
                  {loading ? (
                    <div>
                      <div className="rounded-md bg-red-600 px-4 py-2 text-white">
                        <SyncLoader size="5px" />
                      </div>
                    </div>
                  ) : (
                    <button className="w-full rounded-md bg-red-600 px-4 py-2 text-white">
                      Delete User
                    </button>
                  )}
                </div>
              </form>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

export default DeleteUserModal;

import React from "react";
import UserData from "./UserData";

const UserDataPopup = ({ selectedUser, noSearch, handlePDataModalClose }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center  overflow-y-auto">
      <div className="absolute inset-0  bg-gray-900 opacity-75"></div>
      <div className="md:max-w-4/5 relative z-10  mx-4  rounded   md:mx-auto md:w-4/5">
        <UserData mobileNum={selectedUser} noSearch={true} />
      </div>
      <div
        className="text-black-800 dark:text-black-100 absolute right-10 top-0 m-3 cursor-pointer rounded-full bg-white transition duration-150 ease-in-out"
        onClick={handlePDataModalClose}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          aria-label="Close"
          className="icon icon-tabler icon-tabler-x"
          width={30}
          height={30}
          viewBox="0 0 24 24"
          strokeWidth="2.5"
          stroke="currentColor"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" />
          <line x1={18} y1={6} x2={6} y2={18} />
          <line x1={6} y1={6} x2={18} y2={18} />
        </svg>
      </div>
    </div>
  );
};

export default UserDataPopup;

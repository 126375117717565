import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ClipLoader } from "react-spinners";
import { getToken } from "utils/getToken";

const PData = ({ mobile, isPDataModalOPen, onClose }) => {
  const [apiResponse, setApiResponse] = useState(null);
  const [isPDataLoading, setIsPDataLoading] = useState(false);

  const fetchWhatsAppData = () => {
    fetch("https://api6.gagahealth.com/whatsapp/get-wa-data/", {
      method: "POST",
      headers: {
        Authorization: `Token ${getToken()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mobile: `${mobile}`,
        doctor_mobile: "+919833018465",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setApiResponse(data);
        setIsPDataLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setIsPDataLoading(false);
        toast.error("Something went wrong!");
      });
  };

  useEffect(() => {
    if (isPDataModalOPen) {
      setIsPDataLoading(true);
      fetchWhatsAppData();
    }
  }, [isPDataModalOPen, mobile]);

  useEffect(() => {
    if (!isPDataModalOPen) {
      setApiResponse(null);
    }
  }, [isPDataModalOPen]);

  const formatBoldText = (text) => {
    return <strong>{text}</strong>;
  };

  const renderFields = () => {
    if (!apiResponse || !apiResponse.messages) return null;

    const message = apiResponse.messages;
    const lines = message.split("\n");

    return (
      <div>
        {lines.map((line, index) => (
          <div key={index}>
            {line.includes(":")
              ? line
                  .split(":")
                  .map((part, i) => (
                    <span key={i}>
                      {i % 2 === 0 ? formatBoldText(part) : `: ${part}`}
                    </span>
                  ))
              : formatBoldText(line)}
            <br />
          </div>
        ))}
      </div>
    );
  };
  const ref = useRef(null);
  // Close on clicking outside the modal
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isPDataModalOPen]);

  return (
    <div>
      {isPDataModalOPen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-y-auto">
          <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
          <div
            className="relative z-10 w-full mx-4 bg-white rounded md:mx-auto md:max-w-lg"
            ref={ref}
          >
            {!isPDataLoading ? (
              <div className="message-container h-[100vh] overflow-y-scroll p-4 pt-8 text-gray-700">
                {apiResponse && renderFields()}
              </div>
            ) : (
              <div className="flex h-[30vh] items-center justify-center">
                <ClipLoader color={"blue"} size={40} />
              </div>
            )}

            <div
              className="absolute top-0 right-0 m-3 transition duration-150 ease-in-out cursor-pointer text-black-800 dark:text-black-100"
              onClick={onClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-label="Close"
                className="icon icon-tabler icon-tabler-x"
                width={20}
                height={20}
                viewBox="0 0 24 24"
                strokeWidth="2.5"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <line x1={18} y1={6} x2={6} y2={18} />
                <line x1={6} y1={6} x2={18} y2={18} />
              </svg>
            </div>
          </div>
        </div>
      )}
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default PData;

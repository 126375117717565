export const getToken = () => {
  const token = localStorage.getItem("token");
  if (token === null) {
    return null;
  } else {
    const tokenExpiration = token.expiration;
    const tokenExpirationDate = new Date(tokenExpiration);
    if (tokenExpirationDate <= new Date()) {
      localStorage.removeItem("token");
      return null;
    } else {
      return token;
    }
  }
};

import { useRecoilState, useRecoilValue } from "recoil";
import {
  selectedUserState,
  showModalState,
  doctorsDataState,
  doctorIdState,
  appointmentDateState,
  appointmentTypeState,
  appointmentStatusState,
  remarkUpdateState,
  feedbackState,
  ratingState,
  currentPageState,
  filterChangeForUserState,
  filterSelectedForUserState,
  statusFilterState,
  filterSelectedForStatusState,
  mobileNumberState,
  isVideoConsultationAllowedState,
} from "store/appointment/atoms/appointmentAtomState";
import { toast } from "react-toastify";
import dayjs from "dayjs";

import { getToken } from "utils/getToken";
import { parseISO, format } from "date-fns";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import { useEffect, useState } from "react";

const appointmentStatusSelectOptions = [
  { name: "Created", value: "created" },
  { name: "Scheduled", value: "scheduled" },
  { name: "Pending Confirmation", value: "pending_confirmation" },
  { name: "On Going", value: "on_going" },
  { name: "Consultation Done", value: "consultation_done" },
  { name: "Cancelled", value: "cancelled" },
  { name: "Closed", value: "closed" },
];

const AppointmentModal = ({ handleCloseModal, fetchData }) => {
  const selectedUser = useRecoilValue(selectedUserState);
  const [showModal, setShowModal] = useRecoilState(showModalState);
  const doctors_Data = useRecoilValue(doctorsDataState);

  const [doctorsData, setDoctorsData] = useState(doctors_Data);
  
  const [doctorId, setDoctorId] = useRecoilState(doctorIdState);
  const [dateIsNull,setDateIsNull]=useState(selectedUser.scheduled_at===null);
  const [appointmentDate, setAppointmentDate] =
    useRecoilState(appointmentDateState);
  const [slotId, setSlotId] = useState("");
  const [slots, setSlots] = useState([]);
  const appointmentType = useRecoilValue(appointmentTypeState);
  const [appointmentStatus, setAppointmentStatus] = useRecoilState(
    appointmentStatusState
  );
  const mobileNumber = useRecoilValue(mobileNumberState);

  const [feedback, setFeedback] = useRecoilState(feedbackState);
  const [rating, setRating] = useRecoilState(ratingState);
  const [remarkUpdate, setRemarkUpdate] = useRecoilState(remarkUpdateState);
  const filterChangeForUser = useRecoilValue(filterChangeForUserState);
  const statusFilter = useRecoilValue(statusFilterState);
  const isFilterSelectedForUser = useRecoilValue(filterSelectedForUserState);
  const isFilterSelectedForStatus = useRecoilValue(
    filterSelectedForStatusState
  );

  const currentPage = useRecoilValue(currentPageState);
  const [isVideoConsultationAllowed, setIsVideoConsultationAllowed] =
    useRecoilState(isVideoConsultationAllowedState);

  const handleAppointmentStatusChange = (e) => {
    const { value } = e.target;
    setAppointmentStatus(value);
  };

  const handleSaveClick = async () => {
    console.log(doctorId);
    if (
      selectedUser.appointment_type === "growth" ||
      selectedUser.appointment_type.toLowerCase().includes("lactation")
    ) {
      console.log(typeof doctorId, doctorId, typeof slotId, slotId);
      if (doctorId === "0") {
        toast.error("Please select a doctor");
        console.log("called doctorId");
        return;
      }
      // if (slotId === "0" || slotId === "" || slotId === null) {
      //   console.log("called slotId");
      //   toast.error("Please select a slot");
      //   return;
      // }
    }

    const slot_id = parseInt(slotId);
    const updatedUser = {
      ...selectedUser,
      appointment_type: appointmentType,
      slot_id: slot_id,
      appointment_status: appointmentStatus,
    };

    console.log(updatedUser);

    if (
      selectedUser.appointment_type === "growth" ||
      selectedUser.appointment_type.toLowerCase().includes("lactation")
    ) {
      updatedUser.scheduled_at = appointmentDate;
    }

    if (updatedUser.appointment_status === "scheduled") {
      if (!selectedUser.patient_id || selectedUser.patient_id === "") {
        toast.error(
          "Please select a patient before scheduling the appointment"
        );
        setShowModal(false);
        return;
      }
    }

    try {
      const remarkUpdateData = remarkUpdate
        ? {
            email_id: localStorage.getItem("loginEmail"),
            time: format(new Date(), "dd-MM-yyyy HH:mm:ss"),
            note: remarkUpdate,
          }
        : {};

      if (doctorId === 0) {
        setDoctorId(null);
      }
      let updateData = {
        appointment_id: updatedUser.id,
        appointment_status: updatedUser.appointment_status,
        appointment_type: updatedUser.appointment_type,
        remark: remarkUpdateData,
        rating: rating ? parseInt(rating) : null,
        feedback: feedback ? feedback : null,
        doctor_id: doctorId === 0 ? null : parseInt(doctorId),
        video_consultation_allowed: isVideoConsultationAllowed,
      };

      if (
        selectedUser.appointment_type === "growth" ||
        selectedUser.appointment_type.toLowerCase().includes("lactation")
      ) {
        updateData.slot_id = updatedUser.slot_id;
      } else {
        updateData.scheduled_at = appointmentDate;
      }

      const jsonData = JSON.stringify(updateData);

      const config = {
        method: "post",
        url: process.env.REACT_APP_API_URL + "consultation/appointment/update/",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${getToken()}`,
        },
        data: jsonData,
      };

      const response = await axios(config);
      setRemarkUpdate("");
      setRating("");
      setFeedback("");
      setDoctorId(0);
      setShowModal(false);
      fetchData({
        mobileNumber,
        isFilterSelectedForUser,
        isFilterSelectedForStatus,
        filterChangeForUser,
        statusFilter,
        currentPage,
      });
    } catch (error) {
      console.error(error);
      toast.error("Failed to update appointment");
      toast.error("Contact Utkarsh");
    }
  };
  const FetchDoctorGrowthandLactation = async (category) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}doctors/doctors/all/?category=${category}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${getToken()}`,
          },
        }
      );
      console.log(data);
      setDoctorsData(data);
    } catch (error) {
      console.error("Error fetching doctors:", error);
      toast.error("Failed to fetch doctors");
    }
  };
  const fetchDoctorSlots = async (doctorId, slotType) => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}doctors/get-doctor-appointment-slot/`,
        { doctor_id: doctorId, source: "support portal", slot_type: slotType },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${getToken()}`,
          },
        }
      );

      const slotData = data.appointments.map((slot) => ({
        value: slot.id,
        label: `${slot.date} (${dayjs(slot.time_from, "HH:mm").format(
          "hh:mm A"
        )} - ${dayjs(slot.time_to, "HH:mm").format("hh:mm A")})`,
      }));
      setSlots(slotData);
    } catch (error) {
      console.error("Error fetching doctor slots:", error);
      toast.error("Failed to fetch doctor slots");
    }
  };

  useEffect(() => {
    if (doctorId) {
      fetchDoctorSlots(doctorId, selectedUser.appointment_type);
    }
    if (selectedUser.appointment_type === "growth") {
      FetchDoctorGrowthandLactation("growth");
    } else if (
      selectedUser.appointment_type.toLowerCase().includes("lactation")
    ) {
      FetchDoctorGrowthandLactation("lactation");
    }
  }, [doctorId]);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-hidden">
      <div className="absolute inset-0 bg-gray-900 opacity-75"></div>
      <div className="custom-scrollbar relative z-10 h-[95vh] w-[45%] overflow-y-auto  rounded bg-white md:mx-auto">
        <div className="text-sm">
          <div className="p-2 md:p-8">
            <h2 className="text-center text-2xl font-bold">Appointment Data</h2>
            <div className="mt-2 space-y-4 md:mt-4">
              <div className="relative space-y-4 rounded-md border border-[#bcb9b9] p-2">
                <p className="absolute -top-[11px] left-4 bg-white px-1 font-semibold">
                  User details
                </p>
                <div className="flex flex-col">
                  <p className="font-normal">
                    Phone Number:{" "}
                    <span className="font-medium text-gray-700">
                      {selectedUser.phone_number}
                    </span>
                  </p>
                </div>
                <div className="flex justify-between">
                  <p className="font-normal">
                    Appointment Created:{" "}
                    <span className="font-medium text-gray-700">
                      {format(
                        parseISO(selectedUser.created_at),
                        "dd-MM-yyyy HH:mm:ss"
                      )}
                    </span>
                  </p>
                  <p className="font-normal">
                    Appointment Updated: {"  "}
                    <span className="font-medium text-gray-700">
                      {format(
                        parseISO(selectedUser.updated_at),
                        "dd-MM-yyyy HH:mm:ss"
                      )}
                    </span>{" "}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-normal">
                    Appointment Type:{" "}
                    <span className="font-medium text-gray-700">
                      {appointmentType}
                    </span>
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-normal">
                    Subscription Name:{" "}
                    <span className="font-medium text-gray-700">
                      {selectedUser.subscription_name}
                    </span>
                  </p>
                </div>
              </div>

              <div className="relative flex justify-between rounded-md border border-[#bcb9b9] p-2 pt-4 gap-x-6">
                <p className="absolute -top-[11px] left-4 bg-white px-1 font-semibold">
                  Doctor Details
                </p>

              <div className="flex flex-col w-full">
                <p className="font-normal">
                  Doctor's Name:<b className="text-xl text-red-500">*</b>
                </p>
                <select
                  value={doctorId}
                  onChange={(e) => setDoctorId(e.target.value)}
                  className="rounded-md border border-gray-400 bg-white px-4 py-2"
                  placeholder="Select a doctor"
                >
                  <option value={0}>Select a doctor</option>
                  {doctorsData.map((doctor, index) => (
                    <option key={index} value={doctor.id}>
                      {doctor.name}
                    </option>
                  ))}
                </select>
              </div>

              {(selectedUser.appointment_type === "growth" ||
                selectedUser.appointment_type
                  .toLowerCase()
                  .includes("lactation")) && (
                <div className="flex flex-col w-full">
                  <p className="font-normal">
                    Slots:<b className="text-xl text-red-500">*</b>
                  </p>
                  <select
                    value={slotId}
                    onChange={(e) => setSlotId(e.target.value)}
                    className="rounded-md border border-gray-400 bg-white px-4 py-2"
                    placeholder="Select a slot"
                  >
                    <option value={0}>Select a slot</option>
                    {slots.length > 0 ? (
                      slots.map((slot, index) => (
                        <option key={index} value={slot.value}>
                          {slot.label}
                        </option>
                      ))
                    ) : (
                      <option value="" disabled>
                        No slots available
                      </option>
                    )}
                  </select>
                </div>
              )}
              </div>


              <div className="relative flex flex-col justify-between rounded-md border border-[#bcb9b9] p-2 pt-4">
                <p className="absolute -top-[11px] left-4 bg-white px-1 font-semibold">
                  Appointment details
                </p>
                <div className="flex justify-between">
                <div className={`flex flex-col w-[50%] ${dateIsNull && ((appointmentStatus!=="created" && appointmentStatus!=="cancelled") || doctorId!=0) && 'border-2 px-3 py-2 bg-red-50 border-red-500 rounded-md flex justify-center items-left'}`}>
                  {dateIsNull && ((appointmentStatus!=="created" && appointmentStatus!=="cancelled") || doctorId!=0) && <p className="text-red-500 pb-2 font-bold">Please Select an Appointment Date</p>}    
                  <p className={"flex gap-1 font-normal"}>
                    Appointment Date:
                    <b className="text-xl text-red-500">*</b>
                  </p>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      value={appointmentDate}
                      onChange={(date) => {
                        if(date){
                          setDateIsNull(false);
                        }else{
                          setDateIsNull(true);
                        }
                        setAppointmentDate(date);
                        console.log(date);
                      }}
                      ampmInClock={false}
                      ampm={false}
                      format="DD-MM-YYYY HH:mm:ss"
                      disabled={
                        selectedUser.appointment_type === "growth" ||
                        selectedUser.appointment_type
                          .toLowerCase()
                          .includes("lactation")
                      }
                    />
                  </LocalizationProvider>
                </div>
                <div className="flex flex-col w-[40%]">
                  <p className="flex gap-1 font-normal text-blue-500">
                    Edit Status: <b className="text-xl text-red-500">*</b>
                  </p>
                  <select
                    value={appointmentStatus}
                    onChange={handleAppointmentStatusChange}
                    className="rounded-md border border-gray-400 bg-white px-4 py-4"
                  >
                    {appointmentStatusSelectOptions.map((status, index) => (
                      <option key={index} value={status.value}>
                        {status.name}
                      </option>
                    ))}
                  </select>
                </div>
                </div>
                {selectedUser.preferred_time && <div className="mt-3 border-2 border-yellow-500 bg-yellow-50 rounded-md px-4 py-1">
                  <p className="font-bold text-yellow-500 flex justify-between items-center">
                    <p className="tracking-wide">Preferred Appointment Time :</p>
                    <p className="text-lg">{selectedUser.preferred_time ? selectedUser.preferred_time : "N/A"}</p>
                  </p>
                </div>}
              </div>

              <div className="relative space-y-4 rounded-md border border-[#bcb9b9] p-2">
                <p className="absolute -top-[11px] left-4 bg-white px-1 font-semibold">
                  Remark
                </p>
                <div className="flex flex-col">
                  <p className="font-normal">Remark History:</p>
                  <p
                    className={`flex flex-col px-4 py-2 ${
                      selectedUser.remark.update &&
                      selectedUser.remark.update.length > 0
                        ? "rounded-md border"
                        : ""
                    }`}
                  >
                    {selectedUser.remark.update &&
                      selectedUser.remark.update.length > 0 &&
                      selectedUser.remark.update.map((userRemark, index) => {
                        return (
                          <span
                            key={index}
                            className="font-medium text-gray-700"
                          >
                            {index + 1}. {userRemark.note}{" - by "}
                            <span className="text-blue-700">
                              {userRemark.email_id.split(".")[0].split("@")[0]}
                            </span>{" "}
                            on <span className="text-blue-700">
                            {userRemark.time.split(" ")[0]}
                            </span>
                          </span>
                        );
                      })}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="flex gap-1 font-normal">
                    Remark:
                    <b className="text-xl text-red-500">*</b>
                  </p>
                  <input
                    value={remarkUpdate}
                    onChange={(e) => {
                      if (e.target.value.trim() !== "")
                        setRemarkUpdate(e.target.value);
                    }}
                    type="text"
                    className="rounded-md border border-gray-400 px-4 py-2"
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <p className="flex gap-1 font-normal ">
                  Feedback: <b className="text-xl text-red-500">*</b>
                </p>
                <textarea
                  value={feedback}
                  onChange={(e) => setFeedback(e.target.value)}
                  className="resize-none rounded-md border border-gray-400 px-4 py-2"
                />
              </div>
              <div className="flex flex-row justify-between gap-4">
                <div className="flex w-full flex-col justify-around">
                  <p className="flex gap-1 font-normal ">
                    Video Consultation Allowed Via App:{" "}
                    <b className="text-xl text-red-500">*</b>
                  </p>
                  <select
                    value={isVideoConsultationAllowed}
                    onChange={(e) => {
                      setIsVideoConsultationAllowed(
                        e.target.value === "true" ? true : false
                      );
                    }}
                    className="rounded-md border border-gray-400 bg-white px-4 py-2"
                  >
                    <option value={true}>True</option>
                    <option value={false}>False</option>
                  </select>
                </div>

                <div className="flex flex-col">
                  <p className="flex gap-1 font-normal ">
                    Rating: <b className="text-xl text-red-500">*</b>
                  </p>
                  <select
                    value={rating}
                    onChange={(e) => {
                      setRating(e.target.value);
                    }}
                    className="rounded-md border border-gray-400 bg-white px-4 py-2"
                  >
                    <option value="null" disabled>
                      Select a Rating
                    </option>
                    <option value="1">Unsatisfied</option>
                    <option value="2">Satisfied</option>
                    <option value="3">Very Satisfied</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="sticky -bottom-1 flex justify-end space-x-4 bg-white py-2 px-2 md:px-8">
            <button
              className="rounded-md bg-red-500 px-4 py-2 text-white"
              onClick={handleCloseModal}
            >
              Cancel
            </button>
            <button
              className={`${dateIsNull && ((appointmentStatus!=="created" && appointmentStatus!=="cancelled") || doctorId!=0) ? 'bg-gray-500 text-gray-200' : 'bg-green-500 text-white'} rounded-md  px-4 py-2`}
              onClick={handleSaveClick}
              disabled={dateIsNull && ((appointmentStatus!=="created" && appointmentStatus!=="cancelled") || doctorId!=0)}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentModal;

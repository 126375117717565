import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useState } from "react";
import { MdClose } from "react-icons/md";
import { toast } from "react-toastify";
import "./datepickerstyles.css";
import { format } from "date-fns";

function CustomTimeDialog({ setOpen, snoozeId, chatId, createSnooze }) {
  const [endDate, setEndDate] = useState(new Date());

  return (
    <div className="fixed inset-0 z-[100] flex items-center justify-center overflow-hidden">
      <div className="absolute inset-0 bg-gray-800 opacity-75"></div>
      <div className="relative z-10 flex h-[400px] w-[400px] flex-col items-center overflow-y-auto rounded bg-white py-4 md:mx-auto">
        <MdClose
          className="absolute top-4 right-4 cursor-pointer"
          onClick={() => setOpen(false)}
        />
        <div className="mt-6 flex flex-col items-center justify-center gap-y-4 rounded-lg">
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            className="w-full rounded-md border border-blue-400 px-4 py-2"
            dateFormat="yyyy-MM-dd HH:mm:ss"
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            minDate={new Date()}
          />
        </div>
        <button
          className="mx-auto mt-4 block w-[50%] rounded-md bg-blue-500 px-4 py-2 uppercase text-white"
          onClick={() => {
            if (endDate.getTime() <= Date.now()) {
              toast.error("Select a date and time in the future.");
            } else {
              const formattedDateTime = format(endDate, "yyyy-MM-dd HH:mm:ss");
              createSnooze(snoozeId, formattedDateTime, chatId);
              setOpen(false);
            }
          }}
        >
          Set
        </button>
      </div>
    </div>
  );
}

export default CustomTimeDialog;

import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "@react-oauth/google";
import axios from "axios";
export default function SignIn() {
  const navigate = useNavigate();
  const errorInLogin = () => {
    console.log("Unable to login user");
  };
  const responseGoogle = (res) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}users/google-login-internal/`,
        {
          credential: res.credential,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {

        localStorage.setItem("token", response.data.key);
        localStorage.setItem("loginEmail", response.data.email);

        navigate("/admin/home", { replace: true });
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Sign In
        </h4>
        <GoogleLogin onSuccess={responseGoogle} onError={errorInLogin} />
      </div>
    </div>
  );
}

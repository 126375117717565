import { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { useRecoilState } from "recoil";
import {
  openAddUpdatePatientModalState,
  addUpdateAppointmentState,
} from "store/appointment/atoms/appointmentAtomState";
import axios from "axios";
import { getToken } from "utils/getToken";
import { toast } from "react-toastify";
import SyncLoader from "components/Loaders/SyncLoader";
import { IoMdClose } from "react-icons/io";

const headers = {
  "Content-Type": "application/json",
  Authorization: `Token ${getToken()}`,
};

const AddPatientModal = () => {
  const [openAddUpdatePatientModal, setOpenAddUpdatePatientModal] =
    useRecoilState(openAddUpdatePatientModalState);
  const [addUpdateAppointment, setAddUpdateAppointment] = useRecoilState(
    addUpdateAppointmentState
  );
  const [formValues, setFormValues] = useState({
    mobile: openAddUpdatePatientModal?.userMobile || "",
    name: openAddUpdatePatientModal?.patientDetails?.name || "",
    gender: openAddUpdatePatientModal?.patientDetails?.gender || "",
    full_term:
      openAddUpdatePatientModal?.patientDetails?.patient_details?.full_term,
    birth_weight:
      openAddUpdatePatientModal?.patientDetails?.patient_details
        ?.birth_weight || "",
    dob: openAddUpdatePatientModal?.patientDetails?.date_of_birth || "",
  });

  const [creatingPatient, setCreatingPatient] = useState(false);
  const [updatingPatient, setUpdatingPatient] = useState(false);

  console.log("Patient Add", formValues);

  const selectedAppointmentIndex = openAddUpdatePatientModal?.appointmentIndex;

  const handleAddPatient = async (e) => {
    e.preventDefault();

    if (formValues.mobile) {
      try {
        setCreatingPatient(true);

        const response = await axios.post(
          process.env.REACT_APP_API_URL + "patients/create-patient/",
          formValues,
          {
            headers,
          }
        );

        if (response.status === 200) {
          toast.success("Patient is created successfully!");
          setAddUpdateAppointment({
            appointmentIndex: selectedAppointmentIndex,
            needUpdate: true,
          });

          //Close Modal
          setOpenAddUpdatePatientModal({
            open: false,
            userMobile: "",
            type: "",
            patientDetails: null,
            appointmentIndex: null,
          });
        }
      } catch (error) {
        console.error("ADD_UPDATE_PATIENT_MODAL_APPOINTMENT", error);
        toast.error("Something went wrong while creating patient!");
      } finally {
        setCreatingPatient(false);
      }
    }
  };

  const handleUpdatePatient = async (e) => {
    e.preventDefault();
    console.log("Update Patient", formValues);

    if (
      openAddUpdatePatientModal?.patientDetails?.id &&
      openAddUpdatePatientModal?.userMobile
    ) {
      try {
        setUpdatingPatient(true);

        const data = {
          id: openAddUpdatePatientModal.patientDetails.id,
          ...formValues,
        };
        const response = await axios.post(
          process.env.REACT_APP_API_URL + "patients/update-patient_data/",
          data,
          {
            headers,
          }
        );

        if (response.status === 200) {
          toast.success("Patient details are updated successfully!");
          setAddUpdateAppointment({
            appointmentIndex: selectedAppointmentIndex,
            needUpdate: true,
          });
          //Close Modal
          setOpenAddUpdatePatientModal({
            open: false,
            userMobile: "",
            type: "",
            patientDetails: null,
            appointmentIndex: null,
          });
        }
      } catch (error) {
        console.error("ADD_UPDATE_PATIENT_MODAL_APPOINTMENT", error);
        toast.error("Something went wrong while updating patient!");
      } finally {
        setUpdatingPatient(false);
      }
    }
  };

  useEffect(() => {
    setFormValues({
      mobile: openAddUpdatePatientModal?.userMobile || "",
      name: openAddUpdatePatientModal?.patientDetails?.name || "",
      gender: openAddUpdatePatientModal?.patientDetails?.gender || "",
      full_term:
        openAddUpdatePatientModal?.patientDetails?.patient_details
          ?.full_term === undefined
          ? ""
          : openAddUpdatePatientModal?.patientDetails?.patient_details
              ?.full_term,
      birth_weight:
        openAddUpdatePatientModal?.patientDetails?.patient_details
          ?.birth_weight || "",
      dob: openAddUpdatePatientModal?.patientDetails?.date_of_birth || "",
    });
  }, [
    openAddUpdatePatientModal?.userMobile,
    openAddUpdatePatientModal?.patientDetails?.id,
  ]);

  return (
    <Dialog
      open={openAddUpdatePatientModal.open}
      onClose={() => {
        setOpenAddUpdatePatientModal({
          open: false,
          userMobile: "",
          type: "",
          patientDetails: null,
          appointmentIndex: null,
        });
      }}
      className="relative z-50"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-[#000] opacity-20" aria-hidden="true" />

      {/* Full-screen scrollable container */}
      <div className="fixed inset-0 overflow-y-auto">
        {/* Container to center the panel */}
        <div className="flex items-center justify-center min-h-screen p-4">
          {/* The actual dialog panel  */}
          <Dialog.Panel className="mx-auto w-full max-w-md rounded md:min-w-[40%]">
            <div className="inline-block w-full p-6 my-8 bg-white shadow-xl">
              <div className="flex justify-end">
                <button
                  onClick={() => {
                    setOpenAddUpdatePatientModal({
                      open: false,
                      userMobile: "",
                      type: "",
                      patientDetails: null,
                      appointmentIndex: null,
                    });
                  }}
                  className="flex gap-3 text-gray-600 hover:text-gray-800"
                >
                  <IoMdClose size={25} />
                </button>
              </div>
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-center text-gray-900"
              >
                {openAddUpdatePatientModal.type === "add"
                  ? "Add Patient"
                  : "Update Patient"}
              </Dialog.Title>
              <form
                onSubmit={
                  openAddUpdatePatientModal.type === "add"
                    ? handleAddPatient
                    : handleUpdatePatient
                }
                className="space-y-4"
              >
                <div className="w-full text-sm">
                  <p className="font-semibold text-start">Patient Name</p>
                  <input
                    value={formValues.name}
                    required
                    onChange={(e) => {
                      setFormValues((prev) => {
                        return {
                          ...prev,
                          name: e.target.value,
                        };
                      });
                    }}
                    className={`mt-2 w-full rounded border p-3 text-sm`}
                    placeholder="Enter Patient Name"
                  />
                </div>

                <div className="w-full text-sm">
                  <p className="font-semibold text-start">Gender:</p>
                  <select
                    //   value={babyGender || "null"}
                    //   onChange={(e) => {
                    //     setBabyGender(e.target.value);
                    //   }}
                    value={formValues.gender}
                    onChange={(e) => {
                      setFormValues((prev) => {
                        return {
                          ...prev,
                          gender: e.target.value,
                        };
                      });
                    }}
                    className={`w-full rounded-md bg-gray-100 p-3`}
                  >
                    <option value={""}>Select Gender</option>
                    <option value={"male"}>Male</option>
                    <option value={"female"}>Female</option>
                  </select>
                </div>

                <div className="w-full text-sm">
                  <p className="font-semibold text-start">Full Term:</p>
                  <select
                    //   value={fullTermBorn || "null"}
                    //   onChange={(e) => {
                    //     setFullTermBorn(e.target.value);
                    //   }}
                    value={formValues.full_term}
                    onChange={(e) => {
                      let fullTerm;

                      if (e.target.value) {
                        if (e.target.value === "true") {
                          fullTerm = true;
                        } else if (e.target.value === "false") {
                          fullTerm = false;
                        }
                      } else {
                        fullTerm = "";
                      }

                      setFormValues((prev) => {
                        return {
                          ...prev,
                          full_term: fullTerm,
                        };
                      });
                    }}
                    className={`w-full rounded-md bg-gray-100 p-3`}
                  >
                    <option value={""}>Select Term</option>
                    <option value={true}>Yes</option>
                    <option value={false}>No</option>
                  </select>
                </div>

                <div className="w-full text-sm">
                  <p className="font-semibold text-start">Birth Weight(Kg):</p>

                  <input
                    value={formValues.birth_weight}
                    placeholder="Enter Patient Weight"
                    onChange={(e) => {
                      setFormValues((prev) => {
                        return {
                          ...prev,
                          birth_weight: parseFloat(e.target.value),
                        };
                      });
                    }}
                    type="number"
                    min="0"
                    step="any"
                    className="bg-transparent mt-2 w-full rounded border p-3 text-sm outline-none [&::-webkit-inner-spin-button]:appearance-none"
                    onWheel={(event) => event.currentTarget.blur()}
                  />
                </div>

                <div className="w-full text-sm">
                  <p className="font-semibold text-start">Baby DOB:</p>

                  <input
                    id="dob"
                    name="dob"
                    type="date"
                    value={formValues.dob}
                    onChange={(e) => {
                      setFormValues((prev) => {
                        return {
                          ...prev,
                          dob: e.target.value,
                        };
                      });
                    }}
                    max={new Date().toISOString().split("T")[0]}
                    className={`w-full rounded-md border-gray-200 p-2`}
                  />
                </div>

                <div className="mt-4">
                  {creatingPatient || updatingPatient ? (
                    <div>
                      <div className="px-4 py-2 text-white bg-blue-500 rounded-md">
                        <SyncLoader size="5px" />
                      </div>
                    </div>
                  ) : openAddUpdatePatientModal.type === "add" ? (
                    <button className="w-full px-4 py-2 text-white bg-blue-500 rounded-md">
                      Add Patient
                    </button>
                  ) : (
                    <button className="w-full px-4 py-2 text-white bg-blue-500 rounded-md">
                      Update Patient
                    </button>
                  )}
                </div>
              </form>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  );
};

export default AddPatientModal;

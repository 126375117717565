export const formatTimestamp = (timestamp) => {
  const date = new Date(timestamp);

  // Define an array of month names in shorthand notation
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Extract date and time components
  const day = date.getDate();
  const month = monthNames[date.getMonth()]; // Get the month in shorthand notation
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  // Determine the day suffix (e.g., "6th", "21st", "25th")
  let daySuffix = "th";
  if (day === 1 || day === 21 || day === 31) {
    daySuffix = "st";
  } else if (day === 2 || day === 22) {
    daySuffix = "nd";
  } else if (day === 3 || day === 23) {
    daySuffix = "rd";
  }

  // Format the timestamp
  const formattedTimestamp = `${day} ${month} ${year}, ${hours}:${minutes}`;
  return formattedTimestamp;
};

import { atom } from "recoil";
export const openAddUpdatePatientModalOthersState = atom({
    key: "openAddUpdatePatientModalOthers",
    default: {
        open: false,
        userMobile: "",
        type: "",
        patientDetails: null,
        appointmentIndex: null,
    },
});
export const patientRefetchState = atom({
    key: 'patientRefetchState',
    default: false,
});
import { useMediaQuery } from "@mui/material";
import "assets/css/filter.css";
import axios from "axios";
import SnoozeDropdown from "components/dropdown/SnoozeDropdown";
import UserDataPopup from "components/UserData/UserDataPopup";
import { format, parseISO } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { FiSearch } from "react-icons/fi";
import { HiExternalLink } from "react-icons/hi";
import { MdCancel } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { formatTimeInMinutes } from "utils/formatTimeInMinutes";
import { getToken } from "utils/getToken";
import "../../assets/css/Blink.css";
import "../../assets/css/scrollBar.css";
import ChatCountLogic from "../../utils/ChatCountLogic";
import CustomTimeDialog from "./CustomTimeDialog";
import IframeModal from "./IframeModal";

const ChatStatus = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState("All Groups");
  const [filterPage, setFilterPage] = useState("");
  const [isGroupDropdownOpen, setIsGroupDropdownOpen] = useState(false);
  const [groupSearchQuery, setGroupSearchQuery] = useState("");
  const [visibleGroups, setVisibleGroups] = useState(30);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [groups, setGroups] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const [lastMessageTime, setLastMessageTime] = useState("gamma");
  const [statusFilter, setStatusFilter] = useState([
    "open",
    "active",
    "pending_doctor",
  ]);
  const [isChatStatusModalOpen, setIsChatStatusModalOpen] = useState(false);
  const [groupDataLoaded, setGroupDataLoaded] = useState(false);
  const [isChatStatusSaveButtonDisabled, setIsChatStatusSaveButtonDisabled] =
    useState(data.map(() => false));
  const [mobileNumber, setMobileNumber] = useState("");
  const [isFilterStatusMenuOpen, setIsFilterStatusMenuOpen] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [filterChanged, setFilterChanged] = useState(false);
  const [newStatus, setNewStatus] = useState("default");
  // const [chatCurrentStatus, setChatCurrentStatus] = useState("null");
  const [currentChatId, setCurrentChatId] = useState(null);
  const [chatIndex, setChatIndex] = useState(null);
  const [
    isChatStatusModalOpenSaveButttonDisable,
    setIsChatStatusModalOpenSaveButttonDisable,
  ] = useState(false);
  const [sortingMethod, setSortingMethod] = useState("asc");
  const location = useLocation();
  const currentPath = location.pathname;
  const [isSearching, setIsSearching] = useState(false);
  const groupDataFetcher = async () => {
    try {
      let url = process.env.REACT_APP_API_URL + "whatsapp/get-group-details/";

      const response = await axios.get(url, {
        headers: {
          Authorization: `Token ${getToken()}`,
          "Content-Type": "application/json",
        },
      });
      // console.log("response", response);

      if (
        response.data &&
        response.data.data &&
        response.data.data.length > 0
      ) {
        setGroupDataLoaded(true);
        const groupNames = response.data.data.map((group) => group.group_name);

        // console.log("groupData", groupData);
        setGroups(["All Groups", ...groupNames]);
        setGroupData([
          {
            group_name: "All Groups",
            group_id: null,
          },
          ...response.data.data,
        ]);

        // setSelectedGroup(groups[0]);
        // console.log(groupData);
        // setSelectedGroupId(groupData[0].group_id);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
      // setIsDisabled(false);
    }
  };

  console.log(data[0]);

  const retrieveConversation = async (retries = 0) => {
    try {
      setIsPageLoading(true);
      const apiUrl = process.env.REACT_APP_API_URL + "whatsapp/get-chats/";
      const formattedStatus = `'${statusFilter.join("','")}'`;

      const queryParams = {
        status: statusFilter.length > 0 ? formattedStatus : null,
      };
      // console.log(filterChanged);

      const timeInMinutes = parseInt(lastMessageTime, 10);
      if (retries >= 0 && !isNaN(timeInMinutes)) {
        queryParams.minutes = timeInMinutes;
      }
      if (selectedGroupId !== null) {
        queryParams.group_id = selectedGroupId;
      }
      if (sortingMethod !== null && sortingMethod !== "default") {
        queryParams.sort_by = sortingMethod;
      }
      if (mobileNumber.length > 7) {
        queryParams.mobile = mobileNumber;
      }
      if (filterChanged) {
        setCurrentPage(1);
      }
      if (currentPage) {
        queryParams.page = filterChanged ? 1 : currentPage;
      }
      const response = await axios.get(apiUrl, {
        headers: {
          Authorization: `Token ${getToken()}`,
          "Content-Type": "application/json",
        },
        params: queryParams,
      });
      if (filterChanged) {
        setFilterChanged(false);
      }
      if (response.status === 200) {
        // console.log(response);
        if (response.data.data.length === 0) {
          setIsSearching(false);
          toast.error("No data found for the selected filters.");
          setData([]);
          // setCurrentPage(1);
          // setSelectedGroupId(null);
          // setSelectedGroup("All Groups");
          // setLastMessageTime("20 mins");
          // setMobileNumber("");
          // setStatusFilter([]);
          // setIsPageLoading(true);
        } else {
          setData(response.data.data);
          setIsPageLoading(false);
          setIsSearching(false);
        }
      } else {
        toast.error("Failed to fetch data from the API.");
        setIsSearching(false);
      }
    } catch (error) {
      setIsSearching(false);
      console.error("Error fetching data:", error);
      toast.error("Error fetching data from the API.");
    }
  };

  // fetch initial data and group data
  useEffect(() => {
    groupDataFetcher();
  }, []);

  useEffect(() => {
    if (groupDataLoaded) {
      // console.log("groupDataLoaded");
      retrieveConversation();
    }
  }, [
    currentPage,
    selectedGroupId,
    statusFilter,
    lastMessageTime,
    groupDataLoaded,
    sortingMethod,
  ]);

  // For filters

  // For group filter
  const handleGroupSelection = (selectedGroupName) => {
    const matchingGroup = groupData.find(
      (group) =>
        group.group_name.toLowerCase() === selectedGroupName.toLowerCase()
    );
    // console.log(matchingGroup);
    if (matchingGroup) {
      setSelectedGroup(matchingGroup.group_name);
      setSelectedGroupId(matchingGroup.group_id);
      setIsGroupDropdownOpen(false);
      setFilterChanged(true);
      // console.log(selectedGroupId);
    } else {
      toast.error("Group not found with id:", selectedGroupName);
      console.log("Group not found with id:", selectedGroupName);
    }
  };
  const filteredGroups = groups.filter((group) =>
    group.toLowerCase().includes(groupSearchQuery.toLowerCase())
  );

  const handleGroupScroll = (e) => {
    const maxScroll = e.target.scrollHeight - e.target.clientHeight;
    setScrollPosition(e.target.scrollTop);

    if (scrollPosition >= maxScroll - 100) {
      setVisibleGroups((prevVisibleGroups) => prevVisibleGroups + 5);
    }
  };
  // Close groupdropdown on clicking outside
  const groupDropDownRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        groupDropDownRef.current &&
        !groupDropDownRef.current.contains(event.target)
      ) {
        setIsGroupDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  // end group filter

  // For  last message time filter
  const handleLastMessageTimeChange = (value) => {
    setLastMessageTime(value);
    setFilterChanged(true);
  };

  // For status filter
  const handleStatusFilterChange = (value) => {
    setStatusFilter((prevStatusFilter) => {
      if (value === "all") {
        if (
          prevStatusFilter.includes("open") &&
          prevStatusFilter.includes("active") &&
          prevStatusFilter.includes("closed") &&
          prevStatusFilter.includes("replied") &&
          prevStatusFilter.includes("blocked") &&
          prevStatusFilter.includes("pending_doctor")
        ) {
          // If any of them is selected, deselect all
          return prevStatusFilter.filter(
            (item) =>
              item !== "open" &&
              item !== "active" &&
              item !== "closed" &&
              item !== "replied" &&
              item !== "blocked" &&
              item !== "pending_doctor"
          );
        } else {
          // If none of them are selected, select all
          setFilterChanged(true);
          return [
            "open",
            "active",
            "closed",
            "replied",
            "blocked",
            "pending_doctor",
          ];
        }
      } else if (value === "live-chat") {
        // Handle "live-chat" option
        if (
          prevStatusFilter.includes("open") &&
          prevStatusFilter.includes("active") &&
          prevStatusFilter.includes("pending_doctor")
        ) {
          // If both "Open" and "Active" are selected, deselect them
          return prevStatusFilter.filter(
            (item) =>
              item !== "open" && item !== "active" && item !== "pending_doctor"
          );
        } else if (prevStatusFilter.includes("live-chat")) {
          // If "Live Chat" is already selected, deselect it
          return prevStatusFilter.filter((item) => item !== "live-chat");
        } else {
          setFilterChanged(true);
          return [...prevStatusFilter, "open", "active", "pending_doctor"];
        }
      } else {
        if (prevStatusFilter.includes(value)) {
          return prevStatusFilter.filter((item) => item !== value);
        } else {
          setFilterChanged(true);
          return [...prevStatusFilter, value];
        }
      }
    });
  };

  // Close statusfilter on clicking outside
  const filterStatusMenuRef = useRef(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        filterStatusMenuRef.current &&
        !filterStatusMenuRef.current.contains(event.target)
      ) {
        setIsFilterStatusMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // for mobile filter
  const handleSearchByMobileNumber = () => {
    setIsSearching(true);
    setFilterChanged(true);
    retrieveConversation();
  };
  const handleMobileNumberRemoval = () => {
    setMobileNumber("");
    // console.log("mobileNumber", mobileNumber);
    setFilterChanged(true);
    retrieveConversation();
  };

  // For ascending/descending sort
  const handleSortChange = (value) => {
    setSortingMethod(value);
    setFilterChanged(true);
  };

  const handlePageSearch = () => {
    const pageNumber = parseInt(filterPage);
    if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
      setMobileNumber("");
      setCurrentPage(pageNumber);
    }
  };

  const handleNextPage = () => {
    // if (currentPage < totalPages) {
    //   setCurrentPage((prevPage) => prevPage + 1);
    // }
    setCurrentPage((prevPage) => prevPage + 1);
    window.scrollTo(0, 0);
    setData([]);
  };

  const handlePrevPage = () => {
    // if (currentPage > 1) {
    //   setCurrentPage((prevPage) => prevPage - 1);
    // }
    setCurrentPage((prevPage) => prevPage - 1);
    setData([]);
    window.scrollTo(0, 0);
  };

  const isSmallScreen = useMediaQuery("(max-width: 768px)");
  const numberOfButtons = isSmallScreen ? 4 : 10;

  // For changing status
  const handleChatStatusChangeEdit = (chatId, currentStatus, rowIndex) => {
    setIsChatStatusModalOpen(true);
    setCurrentChatId(chatId);
    setChatIndex(rowIndex);
    setNewStatus(currentStatus);
  };

  const handleChatStatusChange = async (chatId, currentStatus, rowIndex) => {
    const url = process.env.REACT_APP_API_URL + "whatsapp/chat-update/";
    const payload = {
      chat_status: currentStatus,
      chat_id: chatId,
    };
    try {
      const updatedDisabledState = [...isChatStatusSaveButtonDisabled];
      updatedDisabledState[rowIndex] = true;
      setIsChatStatusSaveButtonDisabled(updatedDisabledState);
      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Token ${getToken()}`,
          "Content-Type": "application/json",
        },
      });
      // console.log(response);
      // console.log(response.data);
      if (response.status === 200) {
        const itemIndex = data.findIndex((item) => item.chat_id === chatId);
        console.log("itemIndex", itemIndex);

        if (itemIndex !== -1) {
          // Create a copy of the data array with the updated Chat_status
          const updatedData = [...data];
          updatedData[itemIndex].Chat_status = response.data.updated_status;
          updatedData[itemIndex].Chat_last_updated = response.data.updated_time;
          // Update the state with the new data
          setData(updatedData);
          updatedDisabledState[rowIndex] = false;
          setIsChatStatusSaveButtonDisabled(updatedDisabledState);
          setIsChatStatusModalOpen(false);
          toast.success("Chat status updated successfully");
        }
      }
    } catch (error) {
      console.log("Error updating data:", error);
      const updatedDisabledState = [...isChatStatusSaveButtonDisabled];

      updatedDisabledState[rowIndex] = false;
      setIsChatStatusSaveButtonDisabled(updatedDisabledState);
      toast.error("Error updating data:", error);
    }
  };

  const timeAgo = (dateTime) => {
    const messageDate = new Date(dateTime);
    const now = new Date();
    const differenceInMinutes = Math.round((now - messageDate) / 60000); // 60000 milliseconds in a minute

    if (differenceInMinutes < 60) {
      return `${differenceInMinutes} minutes`;
    } else {
      // Handle hours or days if needed
      const differenceInHours = Math.round(differenceInMinutes / 60);
      if (differenceInHours < 24) {
        return `${differenceInHours} hours`;
      } else {
        const differenceInDays = Math.round(differenceInHours / 24);
        return `${differenceInDays} days`;
      }
    }
  };

  // For navigating to that particular chat
  const [highlightedRow, setHighlightedRow] = useState(null);
  const [modalContent, setModalContent] = useState(null);
  function createLinkAndOpenChat(row, rowIndex) {
    if (!row.Chat_start_time) {
      return "";
    }

    const chatStartTime = new Date(row.Chat_start_time);
    const startTimeOffset = chatStartTime.getTimezoneOffset();

    const lastMessageTime = new Date(row.event_time);
    const lastMessageTimeOffset = lastMessageTime.getTimezoneOffset();
    const startTime = new Date(
      chatStartTime.getTime() - 10 * 60 * 1000 - startTimeOffset * 60 * 1000
    );
    const endTime = new Date(
      lastMessageTime.getTime() +
        10 * 60 * 1000 -
        lastMessageTimeOffset * 60 * 1000
    );
    const startTimeISO = startTime.toISOString().slice(0, 19);
    const endTimeISO = endTime.toISOString().slice(0, 19);
    // console.log(startTimeISO, endTimeISO);

    const url = `/admin/whatsapp-chat?group_id=${
      row.Whatsappgroup_id
    }&start_time=${startTimeISO}&end_time=${endTimeISO}&mobile_number=${row.Chat_started_by.slice(
      1
    )}`;

    setHighlightedRow(rowIndex);
    setModalContent({ url });
  }
  const closeModal = () => {
    setModalContent(null);
  };

  // For PData Modal

  const [selectedUser, setSelectedUser] = useState(null);
  const [isPDataModalOpen, setIsPDataModalOpen] = useState(false);
  const handlePDataModal = (row) => {
    setSelectedUser(row.Chat_started_by);
    // console.log(row.Chat_started_by);
    setIsPDataModalOpen(true);
  };

  const handlePDataModalClose = () => {
    setIsPDataModalOpen(false);
    setSelectedUser(null);
  };
  const refreshData = () => {
    retrieveConversation();
  };

  // For chat count
  const [blockedChatsCount, setBlockedChatsCount] = useState(0);
  const [pendingDoctorChatsCount, setPendingDoctorChatsCount] = useState(0);
  const [liveChatsCount, setLiveChatsCount] = useState(0);
  const currentTime = new Date();
  const [serverSyncTime, setServerSyncTime] = useState(currentTime);
  const [isServerSyncTimeExceed, setIsServerSyncTimeExceed] = useState(false);
  const [lastBlockAlertTime, setLastBlockAlertTime] = useState(currentTime);
  const [isLastBlockAlertTimeExceed, setIsLastBlockAlertTimeExceed] =
    useState(false);

  const timeDifference = serverSyncTime
    ? currentTime - new Date(serverSyncTime)
    : null;

  const isTimeDifferenceExceeded =
    timeDifference && timeDifference > 15 * 60 * 1000;

  const [showCustomDialog, setShowCustomDialog] = useState(false);
  const [snoozeId, setSnoozeId] = useState();
  const [chatId, setChatId] = useState();

  // creating snooze function
  function handleOpenModal(snooze_id, chat_id) {
    setShowCustomDialog(true);
    setSnoozeId(snooze_id);
    setChatId(chat_id);
  }
  async function createCustomSnooze(snooze_id, value, chat_id) {
    const token = getToken();
    const url =
      snooze_id && snooze_id !== ""
        ? `${process.env.REACT_APP_API_URL}tasks/alert/edit-snooze/`
        : `${process.env.REACT_APP_API_URL}tasks/alert/create-snooze/`;
    const data = {
      alert_source_table: "chat",
      alert_source_id: chat_id,
      snooze_duration: "custom",
      snooze_end_time: value,
      snooze_id,
    };

    try {
      if (value !== "") {
        const response = await axios.post(url, data, {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200 || response.status === 201) {
          toast.success(response.data.message);
          refreshData();
        }
      }
    } catch (error) {
      toast.error("Error occured while creating snooze.");
    }
  }
  async function createSnooze(chatId, snoozeId, value) {
    const token = getToken();
    const url = `${process.env.REACT_APP_API_URL}tasks/alert/create-snooze/`;
    const data = {
      snooze_duration: value,
      alert_source_table: "chat",
      alert_source_id: chatId,
    };
    if (value === "custom") {
      handleOpenModal(snoozeId, chatId);
    }

    try {
      if (value !== "" && value !== "custom") {
        const response = await axios.post(url, data, {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200 || response.status === 201) {
          toast.success(response.data.message);
          refreshData();
        }
      }
    } catch (error) {
      toast.error("Error occured while creating snooze.");
    }
  }

  async function editSnooze(snoozeId, value, is_snoozed, chatId) {
    const token = getToken();
    const url = `${process.env.REACT_APP_API_URL}tasks/alert/edit-snooze/`;
    const data = {
      snooze_duration: is_snoozed && value === "0_min" ? "0_min" : value,
      snooze_id: snoozeId,
    };

    if (value === "custom") {
      handleOpenModal(snoozeId, chatId);
    }
    
    try {
      if (value !== "" && value !== "custom") {
        const response = await axios.post(url, data, {
          headers: {
            Authorization: `Token ${token}`,
            "Content-Type": "application/json",
          },
        });
        if (response.status === 200 || response.status === 201) {
          toast.success(response.data.message);
          refreshData();
        }
      }
    } catch (error) {
      toast.error("Error occured while editing snooze.");
    }
  }

  const snoozeOptions = [
    { value: "30_min", label: "30 minutes" },
    { value: "60_min", label: "60 minutes" },
    { value: "8am_next_day", label: "Next working hour (8AM next day)" },
    { value: "custom", label: "Custom" },
  ];

  return (
    <div className="mx-auto sm:w-full">
      {showCustomDialog ? (
        <CustomTimeDialog
          snoozeId={snoozeId}
          chatId={chatId}
          setOpen={setShowCustomDialog}
          createSnooze={createCustomSnooze}
        />
      ) : null}
      {currentPath === "/admin/chat-status" && (
        <ChatCountLogic
          setGroupBlockedChatsCount={setBlockedChatsCount}
          setGroupPendingDoctorChatsCount={setPendingDoctorChatsCount}
          setGroupLiveChatsCount={setLiveChatsCount}
          setServerSyncTime={setServerSyncTime}
          setIsServerSyncTimeExceed={setIsServerSyncTimeExceed}
          setLastBlockAlertTime={setLastBlockAlertTime}
          setIsLastBlockAlertTimeExceed={setIsLastBlockAlertTimeExceed}
        />
      )}
      {modalContent && (
        <IframeModal onClose={closeModal} url={modalContent.url} />
      )}
      {currentPath === "/admin/chat-status" && (
        <>
          <div className="flex items-center justify-center pt-2">
            <h1
              className={`text-lg font-semibold ${
                isServerSyncTimeExceed
                  ? "animate-blink text-red-500"
                  : "text-green-500"
              }`}
            >
              {isServerSyncTimeExceed && (
                <>
                  <span className={` text-red-500`}>⚠️</span>
                  Last message synced on server:{" "}
                  {formatTimeInMinutes(serverSyncTime)}
                  <span className="text-red-500 ">⚠️</span>
                  Contact Tech Team.
                </>
              )}
              {!isServerSyncTimeExceed && (
                <>
                  Last message synced on server:{" "}
                  {formatTimeInMinutes(serverSyncTime)}
                </>
              )}
            </h1>
          </div>

          {/* Last Block Alert */}
          <div className="flex items-center justify-center text-center">
            <h1
              className={`text-lg font-semibold ${
                isLastBlockAlertTimeExceed
                  ? "animate-blink text-red-500"
                  : "text-green-500"
              }`}
            >
              {isLastBlockAlertTimeExceed && (
                <>
                  <span className={` text-red-500`}>⚠️</span>
                  Last Block Alert Sent:{" "}
                  {formatTimeInMinutes(lastBlockAlertTime)}
                  <span className="text-red-500 ">⚠️</span>
                  Contact Tech Team.
                </>
              )}
              {!isLastBlockAlertTimeExceed && (
                <>
                  Last Block Alert Sent:{" "}
                  {formatTimeInMinutes(lastBlockAlertTime)}
                </>
              )}
            </h1>
          </div>
        </>
      )}
      {/* Live Chat section */}
      <div className="mb-5 py-6">
        {/* Header */}
        <div className="mr-1 flex justify-between">
          <h1 className="items-center justify-center text-center text-2xl font-bold capitalize text-blue-500 dark:text-white">
            Whatsapp Chat Status
          </h1>
          {currentPath === "/admin/chat-status" && (
            <>
              <button
                className={`rounded-lg px-8 py-2 text-white ${
                  liveChatsCount > 0
                    ? "bg-red-500 hover:bg-red-600"
                    : "bg-green-500 hover:bg-green-600"
                }`}
                onClick={() => navigate("/admin/chat-status")}
              >
                Total Live Chats: {liveChatsCount}
              </button>
              <button
                className={`rounded-lg px-8 py-2 text-white ${
                  pendingDoctorChatsCount > 0
                    ? "bg-red-500 hover:bg-red-600"
                    : "bg-green-500 hover:bg-green-600"
                }`}
                onClick={() => navigate("/admin/pending-doctor-chat")}
              >
                Total Pending Doctor Chats: {pendingDoctorChatsCount}
              </button>
            </>
          )}
          <button
            className="rounded-lg bg-blue-500 px-8 py-2 text-white hover:bg-blue-600"
            onClick={() => refreshData()}
          >
            Refresh
          </button>
        </div>

        {/* Filters */}
        <div className="flex flex-col py-4 md:flex-row md:justify-between ">
          {/* Filter using group name */}
          <div className="flex flex-col">
            <div
              className="flex w-[80vw] flex-col rounded-xl md:w-auto"
              ref={groupDropDownRef}
            >
              <button
                type="button"
                className={`focus:ring-primary relative inline-flex items-center rounded-md border ${
                  selectedGroup ? "text-green-500" : "text-black"
                } border-gray-300 bg-white px-4 py-2 text-base ring-opacity-5 hover:bg-gray-50 focus:outline-none focus:ring-1 `}
                id="options-menu"
                aria-haspopup="listbox"
                aria-expanded="true"
                onClick={() => setIsGroupDropdownOpen(!isGroupDropdownOpen)}
              >
                {selectedGroup ? (
                  <>
                    {selectedGroup}
                    <svg
                      className="mt-2 mr-1 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 5.293a1 1 0 011.414 0L10 6.586l1.293-1.293a1 1 0 111.414 1.414l-2 2a1 1 0 01-1.414 0l-2-2a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                    {selectedGroup !== "All Groups" && (
                      <span
                        className="ml-2 cursor-pointer text-red-500"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the button's click event from firing
                          handleGroupSelection("All Groups"); // Clear the group filter
                        }}
                      >
                        <MdCancel />
                      </span>
                    )}
                  </>
                ) : (
                  <>
                    Filter using group name
                    <svg
                      className="mt-2 mr-1 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 5.293a1 1 0 011.414 0L10 6.586l1.293-1.293a1 1 0 111.414 1.414l-2 2a1 1 0 01-1.414 0l-2-2a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </>
                )}
              </button>
              {/* Groupdropdown */}
              {isGroupDropdownOpen && (
                <div className="focus:ring-primary absolute z-10 mt-11 w-auto origin-top-left divide-y divide-gray-200 rounded-md border border-gray-300 bg-white shadow-lg ring-1 ring-opacity-5 focus:outline-none">
                  <input
                    type="text"
                    placeholder="Search groups..."
                    value={groupSearchQuery}
                    onChange={(e) => setGroupSearchQuery(e.target.value)}
                    className="border-b border-gray-300 px-3 py-2 focus:outline-none"
                  />
                  <div
                    className="max-h-40 overflow-y-auto"
                    onScroll={handleGroupScroll}
                  >
                    {filteredGroups
                      .slice(0, visibleGroups)
                      .map((groupName, index) => (
                        <div
                          key={groupName + index}
                          className={`text-black cursor-pointer select-none px-4 py-2 text-sm `}
                          onClick={() => {
                            handleGroupSelection(groupName);
                          }}
                        >
                          {groupName}
                        </div>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Filter by status */}
          <div className="flex flex-col">
            <div
              className="flex w-[80vw] flex-col rounded-xl md:w-auto"
              ref={filterStatusMenuRef}
            >
              <button
                id="dropdownCheckboxButton"
                className={`focus:ring-primary ${
                  statusFilter.length > 0 ? "text-green-500" : "text-black"
                } relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base ring-opacity-5 transition-transform hover:bg-gray-50 focus:outline-none focus:ring-1`}
                type="button"
                onClick={() =>
                  setIsFilterStatusMenuOpen(!isFilterStatusMenuOpen)
                }
              >
                {statusFilter.length > 0 ? (
                  <>
                    {statusFilter.length} Status Selected
                    <svg
                      className={`ml-2.5 h-2.5 w-2.5 transform ${
                        isFilterStatusMenuOpen ? "rotate-180" : ""
                      }`}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="m1 1 4 4 4-4"
                      />
                    </svg>
                    <span
                      className="ml-2 cursor-pointer text-red-500"
                      onClick={(e) => {
                        e.stopPropagation();
                        setStatusFilter([]);
                      }}
                    >
                      <MdCancel />
                    </span>
                  </>
                ) : (
                  <>
                    Filter By Status
                    <svg
                      className={`ml-2.5 h-2.5 w-2.5 transform ${
                        isFilterStatusMenuOpen ? "rotate-180" : ""
                      }`}
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="m1 1 4 4 4-4"
                      />
                    </svg>
                  </>
                )}
              </button>

              {isFilterStatusMenuOpen && (
                <div
                  id="dropdownDefaultCheckbox"
                  className="absolute z-10 mt-11 w-[163px] rounded-lg bg-white shadow ring-1 ring-opacity-5 transition-transform focus:outline-none dark:divide-gray-600 dark:bg-gray-700"
                >
                  <ul
                    className="text-black space-y-3 p-3 text-sm dark:text-gray-200"
                    aria-labelledby="dropdownCheckboxButton"
                  >
                    <li>
                      <div className="flex items-center">
                        <input
                          id="checkbox-item-all"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:ring-offset-gray-700 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-700"
                          value="all"
                          onChange={() => handleStatusFilterChange("all")}
                          checked={
                            statusFilter.includes("open") &&
                            statusFilter.includes("active") &&
                            statusFilter.includes("closed") &&
                            statusFilter.includes("replied") &&
                            statusFilter.includes("pending_doctor")
                          }
                        />
                        <label
                          htmlFor="checkbox-item-live-chat"
                          className="ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          All
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="flex items-center">
                        <input
                          id="checkbox-item-live-chat"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:ring-offset-gray-700 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-700"
                          value="live-chat"
                          onChange={() => handleStatusFilterChange("live-chat")}
                          checked={
                            statusFilter.includes("open") ||
                            statusFilter.includes("active") ||
                            statusFilter.includes("pending_doctor")
                          }
                        />
                        <label
                          htmlFor="checkbox-item-live-chat"
                          className="ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          Live Chat (Open, Active, Pending Doctor)
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="flex items-center">
                        <input
                          id="checkbox-item-2"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:ring-offset-gray-700 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-700"
                          value="closed"
                          onChange={() => handleStatusFilterChange("closed")}
                          checked={statusFilter.includes("closed")}
                        />
                        <label
                          htmlFor="checkbox-item-2"
                          className="ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          Closed
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="flex items-center">
                        <input
                          id="checkbox-item-3"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:ring-offset-gray-700 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-700"
                          value="replied"
                          onChange={() => handleStatusFilterChange("replied")}
                          checked={statusFilter.includes("replied")}
                        />
                        <label
                          htmlFor="checkbox-item-3"
                          className="ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          Replied
                        </label>
                      </div>
                    </li>
                    <li>
                      <div className="flex items-center">
                        <input
                          id="checkbox-item-blocked"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500 dark:border-gray-500 dark:bg-gray-600 dark:ring-offset-gray-700 dark:focus:ring-blue-600 dark:focus:ring-offset-gray-700"
                          value="blocked"
                          onChange={() => handleStatusFilterChange("blocked")}
                          checked={statusFilter.includes("blocked")}
                        />
                        <label
                          htmlFor="checkbox-item-blocked"
                          className="ml-2 w-full text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                          Blocked
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>

          {/* Filter by Last Message Time */}
          <div className="relative inline-flex items-center">
            <select
              id="lastMessageTime"
              className={`focus:ring-primary rounded-md border border-gray-300 bg-white px-4 py-2 text-base ring-opacity-5 hover:bg-gray-50 focus:outline-none focus:ring-1 ${
                lastMessageTime !== "gamma" && lastMessageTime !== ""
                  ? "text-green-500"
                  : "text-black"
              }`}
              value={lastMessageTime}
              onChange={(e) => {
                handleLastMessageTimeChange(e.target.value);
              }}
            >
              <option value="gamma">Filter By Last Message Time</option>
              <option value="">All Time</option>
              <option
                value="5 mins"
                className={
                  lastMessageTime === "5 mins" ? "text-green-500" : "text-black"
                }
              >
                5 mins ago
              </option>
              <option
                value="10 mins"
                className={
                  lastMessageTime === "10 mins"
                    ? "text-green-500"
                    : "text-black"
                }
              >
                10 mins ago
              </option>
              <option
                value="15 mins"
                className={
                  lastMessageTime === "15 mins"
                    ? "text-green-500"
                    : "text-black"
                }
              >
                15 mins ago
              </option>
              <option
                value="30 mins"
                className={
                  lastMessageTime === "30 mins"
                    ? "text-green-500"
                    : "text-black"
                }
              >
                30 mins ago
              </option>
            </select>
            {lastMessageTime && lastMessageTime !== "gamma" && (
              <button
                className="z-50 ml-2 cursor-pointer text-red-500"
                onClick={() => setLastMessageTime("gamma")}
              >
                <MdCancel />
              </button>
            )}
          </div>

          {/* Ascending/ descending filter */}
          <div className="relative inline-flex items-center">
            <select
              id="ascendingDescending"
              className={`focus:ring-primary rounded-md border border-gray-300 bg-white px-4 py-2 text-base ring-opacity-5 hover:bg-gray-50 focus:outline-none focus:ring-1 ${
                sortingMethod !== "default" && sortingMethod !== null
                  ? "text-green-500"
                  : "text-black"
              }`}
              value={sortingMethod}
              onChange={(e) => {
                handleSortChange(e.target.value);
              }}
            >
              <option value="default">Choose sorting format</option>
              <option
                value="asc"
                className={
                  sortingMethod === "asc" ? "text-green-500" : "text-black"
                }
              >
                Ascending
              </option>
              <option
                value="desc"
                className={
                  sortingMethod === "desc" ? "text-green-500" : "text-black"
                }
              >
                Descending
              </option>
            </select>
            {sortingMethod && sortingMethod !== "default" && (
              <button
                className="z-50 ml-2 cursor-pointer text-red-500"
                onClick={() => setSortingMethod("default")}
              >
                <MdCancel />
              </button>
            )}
          </div>

          {/* Filters via mobile number */}
          <div
            id="dropdown"
            className="flex w-72 items-center justify-center gap-2 rounded-md border border-gray-300 bg-white px-2 py-1 shadow-md dark:border-navy-700 dark:bg-navy-800"
          >
            <input
              type="tel"
              className="bg-50 h-10 flex-grow rounded-md px-3 text-sm text-navy-700 outline-none placeholder:text-gray-400 dark:bg-navy-900 dark:text-white dark:placeholder:text-white"
              id="mobile"
              placeholder="Search By Mobile Number"
              aria-label="Search"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
            />
            <div className="flex">
              <button
                className="rounded-md bg-blue-500 px-4 py-2 text-white shadow hover:bg-blue-600 disabled:opacity-50"
                type="button"
                id="button-addon1"
                onClick={() => handleSearchByMobileNumber()}
              >
                {isSearching ? "Loading..." : "Search"}
              </button>
            </div>
          </div>
        </div>
        {/* Filters end */}

        {isChatStatusModalOpen && (
          <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="modal-overlay absolute h-full w-full bg-gray-900 opacity-50"></div>
            <div className="modal-container z-50 mx-auto w-96 rounded bg-white shadow-lg">
              <div className="modal-content p-6">
                <button
                  className="relative left-[330px] text-gray-600 hover:text-gray-800"
                  onClick={() => setIsChatStatusModalOpen(false)}
                >
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </button>
                <h2 className="mb-4 text-lg font-semibold">Change Status</h2>
                <select
                  value={newStatus}
                  onChange={(e) => setNewStatus(e.target.value)}
                  className="w-full rounded-md border p-2"
                >
                  <option value="default">Choose the status</option>
                  <option value="closed">Closed</option>
                  <option value="active">Active</option>
                  <option value="replied">Replied</option>
                  <option value="open">Open</option>
                </select>
                <div className="mt-4 flex justify-end">
                  <button
                    className="rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
                    onClick={() => {
                      handleChatStatusChange(
                        currentChatId,
                        newStatus,
                        chatIndex
                      );
                    }}
                    disabled={isChatStatusModalOpenSaveButttonDisable}
                  >
                    {isChatStatusModalOpenSaveButttonDisable
                      ? "Please Wait..."
                      : "Save"}
                  </button>

                  <button
                    className="ml-2 rounded-md bg-gray-400 px-4 py-2 text-white"
                    onClick={() => setIsChatStatusModalOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {isPDataModalOpen && (
          <UserDataPopup
            selectedUser={selectedUser}
            noSearch={true}
            handlePDataModalClose={handlePDataModalClose}
          />
        )}
        {/* For mobile view */}
        {/* <div className="mx-auto sm:hidden">
        {!isPageLoading ? (
          data.map((row, rowIndex) => (
            <div
              key={rowIndex}
              className="p-4 mb-4 bg-white rounded-lg shadow-lg"
            >
              <div className="mb-2">
                <p className="text-base font-semibold">{row.Group_name}</p>
              </div>
              <div className="text-black mt-2 h-auto w-full rounded-lg border-2 border-solid border-[#BFDBFE] bg-white py-2 px-4 focus:outline-none focus:ring-blue-500 dark:focus:ring-blue-600">
                <div className="flex justify-between pb-2">
                  <p className="text-xs text-blue-800">
                    User_id ~ {row.User_id}
                  </p>
                  <div className="flex flex-col">
                    <p className="text-sm text-blue-500">
                      ~ {row.Chat_started_by}
                    </p>
                    {row.Chat_start_time ? (
                      <p>
                        {format(
                          parseISO(row.Chat_start_time),
                          "dd-MM-yyyy HH:mm:ss"
                        )}
                      </p>
                    ) : (
                      <p className="text-gray-500">Not available</p>
                    )}
                  </div>
                </div>
                <p className="">{row.message}</p>
                <div className="flex flex-col justify-between pb-2">
                  <p
                    className={`mt-2  text-sm capitalize ${
                      row.Chat_status === "active"
                        ? "text-blue-500 "
                        : row.Chat_status === "replied"
                        ? "text-green-500 "
                        : row.Chat_status === "closed"
                        ? "text-gray-500 "
                        : "text-red-500 "
                    }`}
                  >
                    {row.Chat_status}
                  </p>
                  <button
                    className="px-4 py-2 text-white bg-blue-500 rounded-md hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
                    onClick={() =>
                      handleChatStatusChange(row.id, row.Chat_status, rowIndex)
                    }
                    disabled={isChatStatusSaveButtonDisabled}
                  >
                    {isChatStatusSaveButtonDisabled[rowIndex]
                      ? "Please Wait..."
                      : "Close Chat"}
                  </button>

                  <div className="flex flex-col mt-4">
                    {row.Chat_last_updated ? (
                      <p className="text-xs">
                        {format(
                          parseISO(row.Chat_last_updated),
                          "dd-MM-yyyy HH:mm:ss"
                        )}
                      </p>
                    ) : (
                      <p className="text-sm text-red-500">Not replied</p>
                    )}
                  </div>
                  <button
                    className="text-blue-500 hover:cursor-pointer"
                    onClick={() => {
                      createLinkAndOpenChat(row, rowIndex);
                    }}
                  >
                    Open chat <HiExternalLink />
                  </button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-lg font-semibold text-center">No data available</p>
        )}
      </div> */}

        {/* For desktop view */}
        {!isPageLoading ? (
          <div
            className="custom-scrollbar max-h-[80vh]
        max-w-full table-auto overflow-scroll rounded-lg border-2 border-gray-600 bg-white text-sm shadow-md sm:block"
          >
            <table className="w-full">
              <thead>
                <tr className="bg-blue-200">
                  <th className="border px-2 py-2 ">Group Name</th>
                  <th className="border px-4 py-2">User</th>
                  <th className="border px-4 py-2">Last Message</th>
                  <th className="border px-4 py-2">Chat Start Time</th>
                  <th className="border px-4 py-2">Last Message Time</th>
                  <th className="border px-4 py-2">Link</th>
                  <th className="border px-4 py-2">Status</th>
                  <th className="border px-4 py-2">Snooze</th>
                </tr>
              </thead>
              <tbody>
                {data.map((row, rowIndex) => (
                  <tr
                    key={rowIndex}
                    className={`${
                      rowIndex === highlightedRow
                        ? "bg-yellow-500"
                        : "hover:bg-yellow-100"
                    } ${
                      row.is_snoozed ? "bg-gray-500 hover:bg-gray-100" : ""
                    } ${row?.is_actionable && "bg-orange-200"}`}
                  >
                    <td className="w-[15%] border px-2 py-2">
                      {row.Group_name}
                    </td>
                    <td className="border px-4 py-2">
                      <div className="flex flex-col justify-center gap-3">
                        <button
                          className="flex cursor-pointer items-center gap-2 text-blue-500 hover:underline"
                          onClick={() => {
                            handlePDataModal(row);
                          }}
                        >
                          {row.Chat_started_by} <HiExternalLink />
                        </button>

                        <p>{row?.user_name || ""}</p>
                      </div>
                    </td>

                    <td className="w-[10%] border px-4 py-2">
                      {row.message.length > 200 ? (
                        <p>{row.message.slice(0, 200)}...</p>
                      ) : (
                        <p>{row.message}</p>
                      )}
                    </td>
                    <td className="border px-4 py-2">
                      {row.Chat_start_time ? (
                        format(parseISO(row.Chat_start_time), "HH:mm")
                      ) : (
                        <p className="text-gray-500">Not available</p>
                      )}
                    </td>
                    <td className="border px-4 py-2">
                      {row.event_time ? (
                        format(parseISO(row.event_time), "HH:mm")
                      ) : (
                        <p className="text-red-500">Not replied</p>
                      )}{" "}
                      ({" "}
                      {row.event_time
                        ? `${timeAgo(row.event_time)} ago`
                        : "Not available"}{" "}
                      )
                    </td>

                    <td className="border px-4 py-2">
                      <button
                        className="cursor-pointer text-blue-500 hover:underline"
                        onClick={() => {
                          createLinkAndOpenChat(row, rowIndex);
                        }}
                      >
                        Open chat <HiExternalLink />
                      </button>
                    </td>
                    <td className="border px-4 py-2">
                      <p
                        className={`text-sm font-semibold capitalize ${
                          row.Chat_status === "active"
                            ? "text-[#8A2BE2]"
                            : row.Chat_status === "replied"
                            ? "text-green-400"
                            : row.Chat_status === "closed"
                            ? "text-black"
                            : row.Chat_status === "open"
                            ? "text-red-500"
                            : "text-green-700"
                        }`}
                      >
                        {row.Chat_status === "active"
                          ? "Live (Active)"
                          : row.Chat_status === "open"
                          ? "Live (Open)"
                          : row.Chat_status}
                      </p>
                      <button
                        className="rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
                        onClick={() =>
                          handleChatStatusChange(
                            row.chat_id,
                            "closed",
                            rowIndex
                          )
                        }
                        disabled={isChatStatusSaveButtonDisabled[rowIndex]}
                      >
                        {isChatStatusSaveButtonDisabled[rowIndex]
                          ? "Please Wait..."
                          : "Close"}
                      </button>
                      <button
                        className="mt-2 rounded-md bg-blue-500 px-3 py-2 text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
                        onClick={() =>
                          handleChatStatusChangeEdit(
                            row.chat_id,
                            row.Chat_status,
                            rowIndex
                          )
                        }
                      >
                        Edit
                      </button>
                    </td>
                    <td className="flex flex-col items-center gap-y-2 border px-4 py-2">
                      <SnoozeDropdown
                        selectedValue={
                          row.is_snoozed ? row.Snoozed_duration : ""
                        }
                        onChange={(value) => {
                          row.Snooze_id
                            ? editSnooze(
                                row.Snooze_id,
                                value,
                                row.is_snoozed,
                                row.chat_id
                              )
                            : createSnooze(row.chat_id, row.Snooze_id, value);
                        }}
                        options={
                          row.is_snoozed
                            ? [
                                ...snoozeOptions,
                                { value: "0_min", label: "Remove snooze" },
                              ]
                            : snoozeOptions
                        }
                      />
                      <div>
                        {row.Snoozed_until ? (
                          <p className="font-semibold">
                            till -{" "}
                            {format(
                              new Date(row.Snoozed_until),
                              "HH:mm (dd-MM-yy)"
                            )}
                          </p>
                        ) : null}
                      </div>
                      {row.Snoozed_by ? (
                        <p>{row.Snoozed_by.replace("@gagahealth.com", "")}</p>
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : data.length > 0 ? (
          <div className="flex h-[70vh] items-center justify-center">
            <ClipLoader color={"blue"} size={60} />
          </div>
        ) : (
          <p className="text-center text-lg font-semibold">No data available</p>
        )}

        <div className="mt-10 flex flex-col justify-center md:mt-20 md:flex-row">
          <div className="flex justify-center md:ml-auto">
            <button
              onClick={handlePrevPage}
              disabled={currentPage === 1}
              className="rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:opacity-50 disabled:hover:bg-blue-500"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="inline-flex h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M9.707 4.293a1 1 0 010 1.414L6.414 9H16a1 1 0 110 2H6.414l3.293 3.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              Previous Page
            </button>
            {/* {totalPages > 1 &&
            [
              ...Array(Math.min(numberOfButtons, totalPages - currentPage + 1)),
            ].map((_, index) => {
              const pageNumber = currentPage + index;
              return (
                <button
                  key={index}
                  onClick={() => setCurrentPage(pageNumber)}
                  disabled={pageNumber > totalPages}
                  className={`ml-2 rounded-lg px-4 py-2 ${
                    currentPage === pageNumber
                      ? "bg-blue-500 text-white"
                      : "bg-gray-200 text-gray-800 hover:bg-gray-300"
                  }`}
                >
                  {pageNumber}
                </button>
              );
            })} */}

            <h3 className="flex items-center px-2 text-lg text-gray-800 ">
              Page {currentPage}
            </h3>

            <button
              onClick={handleNextPage}
              // disabled={currentPage === totalPages}
              className="mx-2 rounded-lg bg-blue-500 px-4 py-4 text-white hover:bg-blue-600 md:px-8"
            >
              Next Page
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="inline-flex h-5 w-5 -rotate-180 transform"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M9.707 4.293a1 1 0 010 1.414L6.414 9H16a1 1 0 110 2H6.414l3.293 3.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div className="mt-5 flex items-center justify-end md:ml-auto md:mt-0">
            <input
              type="text"
              placeholder={`Go to page (1 - ${totalPages})`}
              value={filterPage}
              onChange={(e) => setFilterPage(e.target.value)}
              className="w-full rounded-md border border-gray-400 bg-white py-2 pl-8 pr-2 text-sm font-medium text-gray-800 outline-none"
            />
            <button
              className="mx-2 cursor-pointer rounded-lg bg-blue-500 px-4 py-3 text-white hover:bg-blue-300"
              onClick={handlePageSearch}
            >
              <FiSearch
                className="h-4 w-4 cursor-pointer rounded-lg"
                onClick={handlePageSearch}
              />
            </button>
          </div>
        </div>
      </div>
      <hr />

      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
};

export default ChatStatus;
